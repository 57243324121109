import { Outlet, Link } from 'react-router-dom'
import DashboardNavbar from '../DashboardNavbar'
import { HiOutlineMenuAlt3,HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdOutlineDashboard, MdOutlineMapsHomeWork, MdOutlineSupportAgent, MdLogout } from "react-icons/md";
import { LiaHotelSolid, LiaHomeSolid } from "react-icons/lia";
import { FiUser } from "react-icons/fi";
import { FaUsers, FaHeart, FaAirbnb,FaWarehouse } from "react-icons/fa";
import { AiOutlineTransaction } from "react-icons/ai";
import { SlEnvolopeLetter } from "react-icons/sl";
import React, { useState } from 'react';
import { useUser } from '../../Context/UserProvider';
import { HiHome } from "react-icons/hi2";
import { TiHomeOutline } from "react-icons/ti";

const DashboardLayout = () => {
  const [open, setOpen] = useState(true)
  const { user, logout } = useUser();
  const role = user?.role

  const menus = [
    { name: 'dashboard', link: `/dashboard/${role}`, icon: MdOutlineDashboard },
    // { name: 'create user', link: `create-user`, icon: FiUser },
    { name: 'my profile', link: `my-profile`, icon: FiUser, margin: true },
    { name: 'my wishlist', link: `wishlist`, icon: FaHeart },
    { name: 'view properties', link: `view-properties`, icon: MdOutlineMapsHomeWork },
    { name: 'add property', link: `add-property`, icon: MdOutlineMapsHomeWork },
    { name: 'hotels', link: 'all-hotels', icon: LiaHotelSolid, margin: true },
    { name: 'houses', link: 'all-properties', icon: LiaHomeSolid },
    { name: 'shortlets', link: 'all-shortlets', icon: TiHomeOutline },
    { name: 'serviced accommodation', link: 'all-servicedAccomodation', icon: FaAirbnb, margin: true },
    { name: 'studio apartments', link: 'all-studioApartments', icon: HiHome },
    { name: 'warehouse', link: 'all-warehouse', icon: FaWarehouse , margin:true},
    { name: 'office complex', link: 'all-office_complex', icon: HiOutlineOfficeBuilding ,},
    { name: 'view agents', link: `view-owners`, icon: MdOutlineSupportAgent },
    { name: 'view users', link: `view-users`, icon: FaUsers },
    { name: 'transactions', link: 'view-transactions', icon: AiOutlineTransaction, margin: true },
    { name: 'subscriber', link: 'view-subscribers', icon: SlEnvolopeLetter },
  ]
  return (
    <div className='max-w-[1728px] border-2 border-gray-50 mx-auto'>
      <DashboardNavbar onClick={() => setOpen(!open)} />
      <main className="px-[10px] flex justify-between gap-[15px] relative">
        {/* sidebar */}
        <section className={`bg-mosque-700 min-h-screen relative hidden lg:block ${open ? 'w-[20%]' : 'w-16 mr-8'} duration-700 text-gray-200 px-4`}>
          <div className='fixed min-h-max'>
            <div className="flex justify-end py-3 cursor-pointer ml-auto">
              <HiOutlineMenuAlt3 size={26} onClick={() => setOpen(!open)} />
            </div>
            <div className={`flex mt-2 flex-col justify-evenly gap-2 fixed pb-8 h-max ${open && 'myoverflow block'} overflow-hidden`}>
              {
                menus?.filter(
                  (menu) => {
                    if (role === 'admin') {
                      return menu.name !== 'my wishlist' &&
                        menu.name !== 'view properties'// Display all menu items for admin
                    } else if (role === 'homeseeker') {
                      return (
                        menu.name !== 'create user' &&
                        menu.name !== 'view users' &&
                        menu.name !== 'view agents' &&
                        menu.name !== 'subscriber' &&
                        menu.name !== 'add property' &&
                        menu.name !== 'hotels' &&
                        menu.name !== 'apartments' &&
                        menu.name !== 'airbnb' &&
                        menu.name !== 'studio apartments' &&
                        menu.name !== 'shortlets' && 
                        menu.name !== 'warehouse' &&
                        menu.name !== 'office complex'
                      );
                    } else if (role === 'homeowner') {
                      return (
                        menu.name !== 'create user' &&
                        menu.name !== 'view users' &&
                        menu.name !== 'view agents' &&
                        menu.name !== 'subscriber' &&
                        menu.name !== 'view properties' &&
                        menu.name !== 'my wishlist'
                      );
                    } else if (role === 'rep') {
                      return (
                        menu.name !== 'create user' &&
                        menu.name !== 'view users' &&
                        menu.name !== 'view agents' &&
                        menu.name !== 'add property' &&
                        menu.name !== 'subscriber' &&
                        menu.name !== 'view properties' &&
                        menu.name !== 'my wishlist'
                      );
                    }
                    return false;
                  }
                ).map((menu, i) => (
                  <>
                    {open ?
                      <Link to={menu.link} key={i} className={`${menu?.margin && 'mt-3'} flex items-center text-xl gap-3 p-2 font-medium ${open && 'hover:bg-mosque-300'}  rounded-md`}>
                        <div>
                          {React.createElement(menu?.icon, { size: '26' })}
                        </div>
                        <h2 className={`whitespace-pre duration-700 capitalize ${!open && 'opacity-0 -translate-x-96 overflow-hidden'}`} style={{ transitionDelay: `${i + 3}00ms` }}>{menu?.name}</h2>
                      </Link>
                      :
                      <div key={i} className={`${menu?.margin && 'mt-3'} flex items-center text-xl gap-3 p-2 font-medium ${open && 'hover:bg-mosque-300'}  rounded-md`}>
                        <Link to={menu.link}>
                          {React.createElement(menu?.icon, { size: '26' })}
                        </Link>
                        <h2 className={`whitespace-pre duration-700 capitalize ${!open && 'opacity-0 -translate-x-96 overflow-hidden'}`} style={{ transitionDelay: `${i + 3}00ms` }}>{menu?.name}</h2>
                      </div>}
                  </>
                ))
              }
              <button type="button" onClick={() => logout()} className={`flex items-center text-xl gap-3 p-2 font-medium ${open && 'hover:bg-mosque-300'}  rounded-md`}> <MdLogout /> <p className={`whitespace-pre duration-700 capitalize ${!open && 'opacity-0 -translate-x-28 overflow-hidden'}`} style={{ transitionDelay: `300ms` }}>Logout</p></button>
            </div>
          </div>
        </section>
        {/* mobile menu */}
        <section className={`bg-mosque-700 z-[1200] pb-5 lg:hidden absolute top-0 left-0 ${open ? 'w-[220px]' : 'hidden'}  min-h-screen duration-700 text-gray-200 px-4 `}>
          <div className=''>
            <div className="flex mt-4 flex-col justify-evenly gap-4 min-h-max">
              {
                menus?.filter(
                  (menu) => {
                    if (role === 'admin') {
                      return menu.name !== 'my wishlist' &&
                        menu.name !== 'view properties'; // Display all menu items for admin
                    } else if (role === 'homeseeker') {
                      return (
                        menu.name !== 'create user' &&
                        menu.name !== 'view users' &&
                        menu.name !== 'view agents' &&
                        menu.name !== 'subscriber' &&
                        menu.name !== 'add property' &&
                        menu.name !== 'hotels' &&
                        menu.name !== 'apartments' &&
                        menu.name !== 'airbnb' &&
                        menu.name !== 'studio apartments' &&
                        menu.name !== 'shortlets' &&
                        menu.name !== 'warehouse' &&
                        menu.name !== 'office complex'
                      );
                    } else if (role === 'homeowner') {
                      return (
                        menu.name !== 'create user' &&
                        menu.name !== 'view users' &&
                        menu.name !== 'view agents' &&
                        menu.name !== 'subscriber' &&
                        menu.name !== 'view properties' &&
                        menu.name !== 'my wishlist'
                      );
                    } else if (role === 'rep') {
                      return (
                        menu.name !== 'create user' &&
                        menu.name !== 'view users' &&
                        menu.name !== 'view agents' &&
                        menu.name !== 'add property' &&
                        menu.name !== 'subscriber' &&
                        menu.name !== 'view properties' &&
                        menu.name !== 'my wishlist'
                      );
                    }
                    return false;
                  }
                ).map((menu, i) => (
                  <Link to={menu.link} key={i} className={`${menu?.margin && 'mt-5'} flex items-center text-xl gap-3 p-2 font-medium ${open && 'hover:bg-mosque-300'}  rounded-md`}>
                    <div>
                      {React.createElement(menu?.icon, { size: '26' })}
                    </div>
                    <h2 className={`whitespace-pre duration-700 capitalize ${!open && 'opacity-0 -translate-x-60 overflow-hidden'}`} style={{ transitionDelay: `${i + 3}00ms` }}>{menu?.name}</h2>
                  </Link>
                ))
              }
              <button type="button" onClick={() => logout()} className={`flex items-center text-xl gap-3 p-2 font-medium ${open && 'hover:bg-mosque-300'}  rounded-md`}> <MdLogout /> <p className={`whitespace-pre duration-700 capitalize ${!open && 'opacity-0 -translate-x-28 overflow-hidden'}`} style={{ transitionDelay: `300ms` }}>Logout</p></button>
            </div>
          </div>
        </section>
        <section className={`p-3 z-[1000] ${open ? 'w-full lg:w-[80%]' : 'w-[95%]'} mt-[5px]`}>
          <Outlet />
        </section>
      </main>
    </div>
  )
}

export default DashboardLayout