import React from 'react'
import { Outlet } from 'react-router-dom'
import Home from "../../assets/home.jpg"


const Auth = () => {
 return (
  <section className='flex flex-row items-center gap-6'>
   <div className='hidden lg:block lg:w-[50%] h-full'>
    <img src={Home} alt="home" className='w-auto h-full object-contain' loading="lazy" width={'600'} height={'600'} />
   </div>
   <div className='w-full lg:w-[50%]'>
    <Outlet />
   </div>
  </section>
 )
}

export default Auth