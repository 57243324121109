import React, { useEffect, useRef } from 'react'
import Error from '../assets/404.svg'
import { useNavigate } from 'react-router-dom'
import service from '../services'


const Error404 = () => {
 const containerRef = useRef(undefined);
 useEffect(() => service.goToTop(containerRef), [])
 const navigate = useNavigate()
 return (
  <div className="flex flex-col gap-3 items-center justify-center p-3" ref={containerRef}>
   <div className="text-center">
    <img src={Error} alt="Error" className='w-full h-full max-h-[400px] max-w-[500px]' />
    <h1 className="text-4xl font-bold text-gray-800 my-4">Not Found</h1>
    <p className="text-gray-600 text-xl uppercase font-semibold">The page you are looking for might not exist.</p>
   </div>
   <div>
    <p className='text-center my-4 text-lg'>Except you would like to take a job in FrontEnd Development, which would give you the knowledge on how to build the page you are looking for 🤣🤣</p>
    <button type="button" className="submitBtn" onClick={() => navigate('/')}>Go back Home</button>
   </div>
  </div>
 )
}

export default Error404