import React from 'react'
import CustomInput from '../../Components/CustomInput'
import { useState } from 'react'
import { auth } from '../../services/api/auth'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect } from 'react'


const ForgotPassword = () => {
 const [email, setEmail] = useState('')
 useEffect(() => {
  document.title = 'Forgot Password';
 }, []);
 const navigate = useNavigate()

 const handleChange = (e) => {
  const { value } = e.target
  setEmail(value)
 }

 const handleSubmit = (e) => {
  e.preventDefault();
  const formData = new FormData()

  formData.append('email', email)

  auth.resetPassword(formData).then((res) => {
   console.log(res);

   const token = res.data?.token
   toast.success('Token generated');

   setTimeout(() => {
    navigate(`/auth/reset-password/?email=${email}&token=${token}`)
   }, 200)

  }).catch((res) => console.log(res))
 }
 return (
  <div>
   <div className='flex flex-col gap-4 justify-center text-center'>
    <h2 className='text-2xl lg:text-4xl text-asparagus-600 font-semibold'>Forgot Password?</h2>
    <p className='text-lg lg:text-xl font-medium'>Enter your email address below and we will send you instructions to reset your password.</p>
   </div>
   <form>
    <CustomInput label={'Email'} type={'email'} name={'email'} value={email} handleChange={handleChange} />
    <button type="button" className='submitBtn' onClick={handleSubmit}>Send</button>
   </form>
  </div>
 )
}

export default ForgotPassword