import React from 'react'
import { MdAccountBalance } from "react-icons/md";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { IoIosHeart } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../Context/UserProvider';
import service from '../services';


const Dropdown = () => {
 const { user, logout } = useUser()
 const role = user?.role

 const navigate = useNavigate()

 const handleLogout = () => {
  logout();
  navigate('/')
  service.reloadPage();
 }
 return (
  <article className='w-[250px] h-auto p-3 bg-white shadow-inner absolute right-0 top-[50px] z-50 flex flex-col gap-4'>
   <Link to={`dashboard/${role}`} className='flex gap-4 items-center text-xl p-3'>
    <MdAccountBalance />
    <p>Dashboard</p>
   </Link>
   {role === 'homeseeker' && <Link to={`dashboard/${role}/wishlist`} className='flex gap-4 items-center text-xl p-3'>
    <IoIosHeart />
    <p>Wishlist</p>
   </Link>}
   <div className='flex gap-4 items-center text-xl p-3 cursor-pointer' onClick={handleLogout}>
    <LiaSignOutAltSolid />
    <p>Sign out</p>
   </div>
  </article>
 )
}

export default Dropdown