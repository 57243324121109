import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const GoogleMapComponent = ({ onPinDrop }) => {
 const [center, setCenter] = useState({ lat: 0, lng: 0 });
 const [markerPosition, setMarkerPosition] = useState(null);

 const handleMapClick = (event) => {
  const { latLng } = event;
  const latitude = latLng.lat();
  const longitude = latLng.lng();
  setCenter({ lat: latitude, lng: longitude });
  setMarkerPosition({ lat: latitude, lng: longitude });

  // Pass the coordinates to the parent component or update state
  onPinDrop({ latitude, longitude });
 };

 return (
  <LoadScript googleMapsApiKey={'AIzaSyDGddrEOO2c32aQzYw1TTrOzGvpXgSbzFk'}>
   <GoogleMap
    mapContainerStyle={{ height: '400px', width: '100%' }}
    center={center}
    zoom={2}
    onClick={handleMapClick}
   >
    {markerPosition && <Marker position={markerPosition} />}
   </GoogleMap>
  </LoadScript>
 );
};

export default GoogleMapComponent;
