import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import TestimonialCard from './Cards/TestimonialCard';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';
import BgClient from '../assets/bgclients.jpg'

const Testimonials = () => {
 return (
  <section className='testimonialContainer' style={{ backgroundImage: `url(${BgClient})` }}>
   <div className="overlay"></div>
   <div className='testimonials'>
    <div className='mb-5'>
     <p className='text-[18px]'>CLIENTS REVIEWS</p>
     <h1 className='text-[30px] sm:text-[40px] font-bold'>What our clients are saying</h1>
    </div>
    <div className='w-full'>
     <Splide>
      {
       [
        {
          name: "Emily Johnson",
          location: "California, USA",
          review: "Working with HomeLinker was an absolute pleasure! From the moment we contacted them, they were attentive and responsive to all our needs. Our agent, Sarah, went above and beyond to help us find the perfect home. She was knowledgeable about the local market, provided valuable insights, and made the entire process seamless. We couldn't be happier with our new home and highly recommend HomeLinker to anyone looking for a stress-free home-buying experience!",
          image: "https://imgs.search.brave.com/_uRyIXyDuXhBgqyOn35m5UP_aPx1BFmXbR4Ww8HrVMI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by95/b3VuZy13b21hbi1w/b3J0cmFpdC1zdHJl/ZXRfMTMwMy0xMzY3/Mi5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
        },
        {
          name: "David Williams",
          location: "London, UK",
          review: "I cannot say enough good things about HomeLinker. The professionalism and dedication displayed by the entire team were exceptional. Our agent, John, was incredibly patient and took the time to understand exactly what we were looking for. He showed us numerous properties and provided honest feedback, which helped us make an informed decision. The entire transaction was smooth, and we felt supported every step of the way. Thank you, HomeLinker, for helping us find our dream home!",
          image: "https://imgs.search.brave.com/-rrCGAk09A_ULWZjHDIAbEZ1JvVd4OOYRWzQI7f8zSA/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by95/b3VuZy1tYW4tc21p/bGluZy1zdGFuZGlu/Zy1jbGlmZi1uZWFy/LWJlYXV0aWZ1bC1z/ZWFfMTgxNjI0LTIz/NzE2LmpwZz9zaXpl/PTYyNiZleHQ9anBn"
        },
        {
          name: "Aisha Bello",
          location: "Lagos, Nigeria",
          review: "HomeLinker exceeded our expectations in every way. From the initial consultation to the final closing, their service was top-notch. Our agent, Emily, was always available to answer our questions and provided expert guidance throughout the process. She negotiated a fantastic deal for us, and we felt confident knowing we were in capable hands. We are thrilled with our new home and grateful for the exceptional service provided by HomeLinker. We highly recommend them to anyone in the market for a new home.",
          image: "https://imgs.search.brave.com/5KV5eifI1qG4Gt-R7qUN4FNOfo6C1AFktn9y9nkextE/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9jZW50cmFsLWFm/cmljYW4tcGx1cy1z/aXplLW1pZGRsZS1h/Z2UtYWR1bHQtZmVt/YWxlLWNlOTBmZi1h/ODhjLWZjLTliOC1l/OGZlMGNhOF82NjIy/MTQtMjA3NzEwLmpw/Zz9zaXplPTYyNiZl/eHQ9anBn"
        }
      ]
      .map((x, y) => {
        return (
         <SplideSlide key={y}>
          <TestimonialCard {...x}/>
         </SplideSlide>
        )
       })
      }
     </Splide>
    </div>
   </div>
  </section>
 )
}

export default Testimonials