import React, { useEffect, useRef } from 'react'
import Home from '../assets/home2.jpg'
import TypeCard from '../Components/Cards/TypeCard'
import { seek } from '../data'
import service from '../services'
import api from '../services/api'
import { useWishlist } from '../Context/WishListProvider'
import { useUser } from '../Context/UserProvider'


const WhatYouSeek = () => {
  const{setWishlist} = useWishlist()
  const {user} = useUser()
 const containerRef = useRef(undefined);
 
 useEffect(() => {
  service.goToTop(containerRef);
  document.title = 'What do you seek?'
  if(user?.role === 'homeseeker'){
    api.homeSeeker.wish.getWishlist().then((res)=>{setWishlist(res?.data)}).catch((err)=>console.log(err))
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [setWishlist])


 return (
  <section ref={containerRef}>
   <article className='whatyouseek' style={{ backgroundImage: `url(${Home})` }}>
    <div className="overlay"></div>
    <div className="relative flex flex-col gap-5 z-50 text-white text-center">
     <h1 className='text-4xl md:text-[80px]'>What do You Seek??</h1>
     <p className='text-lg md:text-2xl'>Our offerings are tailored to meet your needs and suite your lifestyle.</p>
    </div>
   </article>
   <div className='my-8'>
    <h1 className='find'>Find Your New Home</h1>
    <section className='displayData'>
     {
      seek.map((x, y) => (
       <TypeCard key={y} name={x.name} to={x.name === 'Hotel' ? '/find-hotel' : `/find-apartment/${x.name}`} image={x.image} />
      ))
     }
    </section>
   </div>
  </section>
 )
}

export default WhatYouSeek