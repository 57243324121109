import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { propertiesData } from '../data'
import PropertyCard from "../Components/Cards/PropertyCard";
import { IoMdArrowRoundBack } from "react-icons/io";
import service from "../services";
import api from "../services/api";
import Loading from "../Components/Loading";
import ReactPaginate from "react-paginate";

const FindApartmentPage = () => {
  const { apartmentType } = useParams();
  const containerRef = useRef(undefined);

  useEffect(() => service.goToTop(containerRef), []);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  document.title = apartmentType;

  useEffect(() => {
    setLoading(true);
    if (apartmentType === "Houses") {
      api.homeSeeker.apartments
        .getAllApartments()
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setFilteredData(res.data.Apartments);
          }
        })
        .catch((err) => console.error(err));
    } else if (apartmentType === "Shortlet") {
      api.homeSeeker.shortlets
        .getAllShortlets()
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setFilteredData(res.data.shortlets);
          }
        })
        .catch((err) => console.error(err));
    } else if (apartmentType === "Serviced Accommodation") {
      api.homeSeeker.airbnb
        .getAllAirbnb()
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setFilteredData(res.data.Airbnb);
          }
        })
        .catch((err) => console.error(err));
    } else if (apartmentType === "Flat") {
      api.homeSeeker.studioApartment
        .getAllStudioApartment()
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setFilteredData(res.data.StudioApartment);
          }
        })
        .catch((err) => console.error(err));
    } else if (apartmentType === "Office Complex") {
      api.homeSeeker.officeComplex.getAllOfficeComplex()
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setFilteredData(res.data.officeComplex);
          }
        })
        .catch((err) => console.error(err));
    } else {
      api.homeSeeker.warehouse.getAllWarehouse()
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setFilteredData(res.data.warehouse);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [apartmentType]);

  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    type: apartmentType || "",
    location: "",
    price: "",
    bedrooms: "",
    bathrooms: "",
    tag: "",
  });

  const applyFilters = () => {
    // Filter the data based on selected filters
    let updatedData = [...filteredData];

    if (filters.property_type) {
      updatedData = updatedData.filter(
        (item) =>
          item?.property_type.toLowerCase() ===
          filters.property_type.toLowerCase()
      );
    }

    if (filters.tag) {
      updatedData = updatedData.filter(
        (item) => item?.tag.toLowerCase() === filters.tag.toLowerCase()
      );
    }

    if (filters.location) {
      updatedData = updatedData.filter(
        (item) =>
          item?.location.toLowerCase() === filters.location.toLowerCase()
        // item.location.country.toLowerCase() === filters.location.country.toLowerCase() &&
        // item.location.state.toLowerCase() === filters.location.state.toLowerCase()
      );
    }

    if (filters.price) {
      updatedData = updatedData.filter(
        (item) => item.price <= parseInt(filters.price)
      );
    }

    if (filters.bedrooms) {
      updatedData = updatedData.filter(
        (item) => item.bedrooms === parseInt(filters.bedrooms)
      );
    }

    if (filters.bathrooms) {
      updatedData = updatedData.filter(
        (item) => item.bathrooms === parseFloat(filters.bathrooms)
      );
    }

    setFilteredData(updatedData);
  };

  const handleChangeFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div ref={containerRef}>
      <section className="my-4">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="flex gap-2 items-center mb-2"
        >
          {" "}
          <IoMdArrowRoundBack /> <p>View other apartment type</p>
        </button>
        <h1 className="text-center text-xl lg:text-[35px] text-minsk-500 font-semibold">
          You are viewing listed {apartmentType}
        </h1>
      </section>
      <section className="flex justify-evenly gap-4 items-center">
        {/* Apartment Tag */}
        {apartmentType === "Apartment" && (
          <article className="form_input_container">
            <label htmlFor="tag">Tag</label>
            <select
              name="tag"
              id="tag"
              onChange={(e) => handleChangeFilter("tag", e.target.value)}
              value={filters.tag}
              className="formInput"
            >
              <option value="">All</option>
              {["rent", "sale", "lease"].map((x, y) => (
                <option key={y + 1} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </article>
        )}
        {/* Number of bedrooms */}
        <article className="form_input_container">
          <label htmlFor="type">Bedrooms</label>
          <select
            name="type"
            id="type"
            onChange={(e) => handleChangeFilter("bedrooms", e.target.value)}
            value={filters.bedrooms}
            className="formInput"
          >
            <option value="">All</option>
            {[1, 2, 3, 4, 5].map((x, y) => (
              <option key={y + 1} value={x}>
                {x}
              </option>
            ))}
          </select>
        </article>
        {/* Number of bathrooms */}
        <article className="form_input_container">
          <label htmlFor="type">Bathrooms</label>
          <select
            name="type"
            id="type"
            onChange={(e) => handleChangeFilter("bathrooms", e.target.value)}
            value={filters.bathrooms}
            className="formInput"
          >
            <option value="">All</option>
            {[1, 2, 3, 4, 5].map((x, y) => (
              <option key={y + 1} value={x}>
                {x}
              </option>
            ))}
          </select>
        </article>
        <button
          onClick={applyFilters}
          className="bg-asparagus-500 py-2 text-white px-4 rounded-md"
        >
          Filter
        </button>
      </section>
      {loading ? (
        <Loading className={"w-[50px] my-[50px]"} />
      ) : (
        <div>
          <section className="displayData">
            {currentItems.length > 0 ? (
              currentItems.map((x) => {
                return (
                  <PropertyCard
                    id={x.id}
                    tag={x.tag}
                    type={x.property_type}
                    sqrFt={x.size || x.complex_size || x.warehouse_size}
                    state={x.state}
                    key={x.id}
                    location={x.location}
                    price={x.price}
                    bed={x.no_of_bedroom}
                    seeker={true}
                    owner={false}
                    currency={x.currency}
                    bath={x.no_of_bathroom}
                    garage={x.garage}
                    agentName={x.created_by}
                    date={x.created_at}
                    propertyName={x.property_name}
                    address={x.street_name}
                    image={
                      apartmentType === "Houses"
                        ? `https://homelinker.itl.ng/public/upload/apartments/images/${x.image_1}`
                        : apartmentType === "Shortlet"
                        ? `https://homelinker.itl.ng/public/upload/shortlets/images/${x.image_1}`
                        : apartmentType === "Serviced Accommodation"
                        ? `https://homelinker.itl.ng/public/upload/airbnbs/images/${x.image_1}`
                        : apartmentType === 'Warehouse' ? `https://homelinker.itl.ng/public/upload/Warehouses/images/${x.image_1}` : apartmentType === 'Office Complex' ? `https://homelinker.itl.ng/public/upload/Complexs/images/${x.image_1}` :`https://homelinker.itl.ng/public/upload/studioapartments/images/${x.image_1}`
                    }
                  />
                );
              })
            ) : (
              <div>
                <p className="text-center text-red-500">
                  No matching properties found
                </p>
              </div>
            )}
          </section>
          {filteredData.length > itemsPerPage && <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"flex justify-center gap-2 my-12"}
            previousLinkClassName={
              "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
            }
            nextLinkClassName={
              "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-white"
            }
            breakClassName={"text-blue-500 cursor-pointer"}
            activeClassName={
              "bg-blue-500 text-white border rounded-full p-2"
            }
          />}
        </div>
      )}
    </div>
  );
};

export default FindApartmentPage;
