import { toast } from 'react-toastify'
import Wish from '../../../assets/wishlist.svg'
import WishlistItem from '../../../Components/Cards/WishlistItem'
import { useWishlist } from '../../../Context/WishListProvider'
import api from '../../../services/api'
import { useEffect } from 'react'


const Wishlist = () => {
 const {wishlist, setWishlist} = useWishlist()

useEffect(()=>{
  api.homeSeeker.wish.getWishlist().then((res)=>{setWishlist(res?.data)}).catch((err)=>console.log(err))
},[setWishlist])

 const handleRemoveWish = (id) =>{
  api.homeSeeker.wish.deleteWish(id).then((res)=>{
    if(res.status === 200){
      const wishlistFromLocalStorage = JSON.parse(sessionStorage.getItem('wishes')) || [];
      const updatedWishlist = wishlistFromLocalStorage.filter(item => item.id !== id);
      setWishlist(updatedWishlist)
      sessionStorage.setItem('wishes', JSON.stringify(updatedWishlist));
      toast.success('Wish removed')
    }
  }).catch((err) =>console.log(err))
 }
 
 return (
  <section className='mt-8'>
   {wishlist.length < 1 && (
    <div className='emptywishlist'>
     <figure>
      <img src={Wish} alt="empty wish" className='w-[80%] lg:w-[60%] mx-auto'/>
     </figure>
     <div className='flex flex-col gap-5'>
      <article className='flex flex-col gap-2'>
       <h1 className='text-2xl text-red-600 font-semibold'>Your Wishlist is empty!</h1>
       <p className='text-minsk-500 text-lg'>seems like you don't have wishes here.</p>
       <p className='text-minsk-500 text-lg'f>Make a wish!</p>
      </article>
      <button type="button" className='bg-minsk-500 text-white py-2 hover:scale-75 hover:duration-300 hover:ease-linear'>Start your home hunting</button>
     </div>
    </div>
   )
   }
   {
    wishlist.length > 0 && (
    <div className='flex flex-col gap-4'>
     <p>{wishlist.length} wishes </p>

     <section className='displayData mt-5'>
      {
        wishlist.map((x,y)=>{
          return(
            <WishlistItem key={x.id} type={x.property_type} removeWish={()=>handleRemoveWish(x.id)} id={x.id} price={x.property_price} property_name={x.property_name} location={x.location}/>
          )
        })
      }
     </section>
    </div>)
   }
  </section>
 )
}

export default Wishlist