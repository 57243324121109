import React, { useState } from 'react'

const Modal = ({ isOpen, onClose, children }) => {
 const [isModalOpen, setIsModalOpen] = useState(isOpen);

 // Function to close the modal
 const closeModal = () => {
  setIsModalOpen(false);
  onClose && onClose();
 };

 // Function to handle clicks outside the modal (optional)
 const handleOutsideClick = (e) => {
  if (e.target === e.currentTarget) {
   closeModal();
  }
 };

 // Update the modal state when isOpen prop changes
 React.useEffect(() => {
  setIsModalOpen(isOpen);
 }, [isOpen]);

 return (
  <>
   {isModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-50">
     <div
      className="fixed inset-0 bg-black opacity-50"
      onClick={handleOutsideClick}
     ></div>
     <div className="relative bg-white p-6 rounded-lg z-10">
      {/* Modal content */}
      <div className='my-5 py-5'>{children}</div>
      {/* Close button */}
      <button
       onClick={closeModal}
       className="absolute top-0 right-3 mt-4 mb-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
       X
      </button>
     </div>
    </div>
   )}
  </>
 );
};

export default Modal;