import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AnyModal from "../Components/AnyModal";
import ViewPicVid from "../Components/ViewPicVid";
import { BsHouseLockFill } from "react-icons/bs";
import { IoBed } from "react-icons/io5";
import { BiSolidCarGarage } from "react-icons/bi";
import { FaBath, FaHeart } from "react-icons/fa6";
import { MdPointOfSale } from "react-icons/md";
import { FaRegSquare } from "react-icons/fa";
import { formatPrice, formatPropertyName } from "../Components/Reuseables";
import PropertyCard from "../Components/Cards/PropertyCard";
import { CiLocationOn, CiHeart, CiShare2 } from "react-icons/ci";
import { toast } from "react-toastify";
import { useUser } from "../Context/UserProvider";
import Modal from "../Components/Cards/Modal";
import api from "../services/api";
import service from "../services";
import { useWishlist } from "../Context/WishListProvider";
import NoImage from '../assets/noImage.jpg'


const SingleProperty = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { wishlist } = useWishlist();
  const [liked, setLiked] = useState(false);
  const isUserLoggedIn = () => user && Object.keys(user).length > 0;
  const [property, setProperty] = useState("");
  const [phone, setPhone] = useState('+2349130003337');
  const [similarProperty, setSimilarProperty] = useState([]);

  useEffect(() => {
    document.title = `${property?.property_name}`;
  }, [property?.property_name]);
  const containerRef = useRef(undefined);

  const phoneNumber = phone;

  const message = encodeURIComponent(
    `Hi, my name is ${
      user.firstname + " " + user.lastname
    }. My phone number ${phoneNumber}, and my email ${
      user.email
    }. I am interested in booking an inspection for the property "${
      property?.property_name
    }" (ID: ${property?.id}), which is a(n) ${property?.property_type}, located at ${property?.street_name},${
      property?.location
    }.`
  );

  // Construct the WhatsApp URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

  const { id, type } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedFeatures, setCheckedFeatures] = useState([]);
  const [checkedRules, setCheckedRules] = useState([]);
  const overview = [
    {
      icon: <BsHouseLockFill />,
      name:
        property?.property_type === "office_complex"
          ? "Office Complex"
          : property?.property_type  + ": " + property?.house_type,
      value: "",
    },
    {
      icon: <IoBed />,
      name: "Bed",
      value: property?.no_of_bedroom,
    },
    {
      icon: <BiSolidCarGarage />,
      name: "Garage",
      value: property?.garage,
    },
    {
      icon: <FaBath />,
      name: "Bathroom",
      value: property?.no_of_bathroom,
    },
    {
      icon: <MdPointOfSale />,
      name: "For Sale",
      value: "",
    },
    {
      icon: <FaRegSquare />,
      name: "Sqft",
      value: property?.size,
    },
  ];

  useEffect(() => {
    const fetchPropertyById = () => {
      switch (type) {
        case "apartment":
          api.homeSeeker.apartments
            .getSingleApartment(id)
            .then((res) => {
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
              setProperty(res.data.data);
            })
            .catch((err) => console.log(err));
          api.homeSeeker.apartments
            .getAllApartments()
            .then((res) => {
              setSimilarProperty(res.data.Apartments);
            })
            .catch((err) => console.log(err));
          break;
        case "shortlet":
          api.homeSeeker.shortlets
            .getSingleShortlet(id)
            .then((res) => {
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
              setProperty(res.data.data);
            })
            .catch((err) => console.log(err));
          api.homeSeeker.shortlets
            .getAllShortlets()
            .then((res) => {
              setSimilarProperty(res.data.shortlets);
            })
            .catch((err) => console.error(err));
          break;
        case "studio_apartment":
          api.homeSeeker.studioApartment
            .getSingleStudioApartment(id)
            .then((res) => {
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
              setProperty(res.data.data);
            })
            .catch((err) => console.log(err));
          api.homeSeeker.studioApartment
            .getAllStudioApartment()
            .then((res) => {
              setSimilarProperty(res.data.StudioApartment);
            })
            .catch((err) => console.error(err));
          break;
        case "airbnb":
          api.homeSeeker.airbnb
            .getSingleAirbnb(id)
            .then((res) => {
              setProperty(res.data.data);
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
            })
            .catch((err) => console.log(err));
          api.homeSeeker.airbnb
            .getAllAirbnb()
            .then((res) => {
              setSimilarProperty(res.data.Airbnb);
            })
            .catch((err) => console.error(err));
          break;
        case "hotel":
          api.homeSeeker.hotels
            .getSingleHotel(id)
            .then((res) => {
              setProperty(res.data.data);
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
            })
            .catch((err) => console.log(err));
          api.homeSeeker.hotels
            .getAllHotels()
            .then((res) => {
              setSimilarProperty(res.data.hotels);
            })
            .catch((err) => console.error(err));
          break;
        case "office_complex":
          api.homeSeeker.officeComplex
            .getSingleOfficeComplex(id)
            .then((res) => {
              setProperty(res.data.data);
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
            })
            .catch((err) => console.log(err));
          api.homeSeeker.officeComplex
            .getAllOfficeComplex()
            .then((res) => {
              setSimilarProperty(res.data.officeComplex);
            })
            .catch((err) => console.error(err));
          break;
        case "warehouse":
          api.homeSeeker.warehouse
            .getSingleWarehouse(id)
            .then((res) => {
              setProperty(res.data.data);
              setPhone(res.data?.phone === null ? phone : res.data?.phone)
            })
            .catch((err) => console.log(err));
          api.homeSeeker.warehouse
            .getAllWarehouse()
            .then((res) => {
              setSimilarProperty(res.data.warehouse);
            })
            .catch((err) => console.error(err));
          break;
        default:
          // Handle invalid property types or defaults
          break;
      }
    };
    const isLiked = wishlist.some(
      (item) => item.property_id.toString() === id.toString()
    );
    setLiked(isLiked);
    service.goToTop(containerRef);
    fetchPropertyById();
  }, [id, type, wishlist]);

  useEffect(() => {
    const features = [
      "air_conditioner",
      "heating",
      "free_wifi",
      "electric_vehicle_charging_station",
      "kitchen",
      "kitchenette",
      "washing_machine",
      "oven",
      "fridge",
      "flat_screen_tv",
      "swimming_pool",
      "hot_tub",
      "mini_bar",
      "balcony",
      "garden_view",
      "terrace",
      "ocean_view",
    ];
    const rules = ["smoking", "pets", "parties"];
    const newCheckedRules = rules.map((rule) => {
      return {
        name: rule,
        checked: property[rule] === 1,
      };
    });
    const newCheckedFeatures = features.map((feature) => {
      return {
        name: feature,
        checked: property[feature] === 1,
      };
    });

    setCheckedRules(newCheckedRules);
    setCheckedFeatures(newCheckedFeatures);
  }, [property]);

  const path =
    type === "apartment"
      ? "https://homelinker.itl.ng/upload/apartments"
      : type === "shortlet"
      ? "https://homelinker.itl.ng/public/upload/shortlets"
      : type === "studio_apartment"
      ? "https://homelinker.itl.ng/public/upload/studioApartments"
      : type === "airbnb"
      ? "https://homelinker.itl.ng/public/upload/airbnbs"
      : type === "warehouse"
      ? "https://homelinker.itl.ng/public/upload/Warehouses"
      : type === "office_complex"
      ? "https://homelinker.itl.ng/public/upload/Complexs"
      : "https://homelinker.itl.ng/public/upload/hotels";

  const images = [
    path + "/images/" + property?.image_1,
    path + "/images/" + property?.image_2,
    path + "/images/" + property?.image_3,
    path + "/images/" + property?.image_4,
  ];
  const videos = [
    path + "/videos/" + property.video_1,
    path + "/videos/" + property?.video_2,
  ];

  const [showPopup, setShowPopup] = useState(false);

  const handleViewMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleShare = () => {
    const currentUrl = window.location.href;

    const textarea = document.createElement("textarea");
    textarea.value = currentUrl;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

    toast.success("URL copied to clipboard!");
  };

  const handleAddWish = (e) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("property_id", id);
    formData.append("property_type", type);
    if (isUserLoggedIn()) {
      api.homeSeeker.wish
        .addToWishlist(formData)
        .then((res) => {
          console.log(res);
          toast.success("Wish added");
        })
        .catch((err) => console.log(err));
    } else {
      navigate("/auth/login");
    }
  };

  const handleBookInspection = () => {
    if (isUserLoggedIn() && user.role === "homeseeker") {
      setIsModalOpen(true);
    } else {
      navigate("/auth/login");
    }
  };

  const handlePayment = () => {
    if (isUserLoggedIn()) {
      console.log("i dey here");
    } else {
      navigate("/auth/login");
    }
  };

  return (
    <section className="md:px-[3%] xl:px[8%] py-[3%]" ref={containerRef}>
      <div>
        <button type="button" onClick={() => navigate(-1)}>
          Back to search result
        </button>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 justify-between my-3">
        <article>
          <h1 className="text-xl lg:text-2xl xl:text-4xl font-bold">
            {property.property_name ||
              property.complex_name ||
              property.warehouse_name}
          </h1>
          <div className="flex gap-2 items-center text-xl">
            <CiLocationOn className="text-red-600" />
            <address>
              {property.state},{property.location}
            </address>
          </div>
        </article>
        <article className="flex flex-col gap-2 font-bold">
          <h1 className="text-2xl">
            {property.currency || `₦`}{formatPrice(property.price)}
            <sub>(yearly)</sub>
          </h1>
          <div className="flex gap-3 justify-start sm:justify-end">
            {liked ? (
              <FaHeart className="text-[40px] border-2 border-gray-200 p-1 cursor-pointer fill-red-600" />
            ) : (
              <CiHeart
                className="text-[40px] border-2 border-gray-200 p-1 cursor-pointer fill-red-600"
                onClick={handleAddWish}
              />
            )}
            <CiShare2
              className="text-[40px] border-2 border-gray-200 p-1 cursor-pointer"
              onClick={handleShare}
            />
          </div>
        </article>
      </div>
      <div className="grid grid-cols-2 md:grid-rows-2 md:grid-cols-3 gap-4 h-[400px] mb-[25px]">
        {images.map((image, index) => (
          <div
            key={index}
            className={`relative ${
              index < 2 ? "row-span-2 col-span-1" : "row-span-1 col-span-1"
            }`}
          >
            <img
              src={image}
              alt={`product ${index + 1}`}
              className={`w-full rounded-md ${index < 2 ? "h-full" : "h-full"}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoImage;
              }}
            />
            {index === images.length - 1 && (
              <div
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 cursor-pointer p-[5px]"
                onClick={handleViewMore}
              >
                <span className="text-white text-lg text-center">
                  View More photos and Videos
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
      <section className="my-5 flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-[70%] xl:w-[80%] shadow-lg p-[15px]">
          {/* overview */}
          <article className="py-2 my-6">
            <h2 className="featureText">Overview</h2>
            <div className="flex flex-wrap gap-3 my-2">
              {overview
                .map((item, index) => (
                  <div
                    key={index}
                    className="flex gap-3 items-center h-[30px] shadow-md p-5"
                  >
                    <span className="bg-asparagus-600 text-white text-xl lg:text-2xl p-1 rounded-lg">
                      {item.icon}
                    </span>
                    <div className="flex gap-1 items-center">
                      <p>{item.value}</p>
                      <p className="capitalize">{item.name}</p>
                    </div>
                  </div>
                ))}
            </div>
          </article>
          <article className="py-2 my-6">
            <h2 className="featureText">Brief Description</h2>
            <div className="flex flex-wrap gap-3 my-2">
            <p className="my-2">
              {property.overview}
            </p>
            </div>
          </article>
          {/* details */}
          <article className="py-2 my-6">
            <h2 className="featureText">Details</h2>
            <div className="featureWrap">
              <ul>
                <li>
                  Property ID: <p>{property?.id}</p>
                </li>
                {property?.no_of_bedroom &&<li>
                  Bedroms: <p>{property?.no_of_bedroom}</p>
                </li>}
                {property?.no_of_bathroom &&<li>
                  Bathrooms: <p>{property?.no_of_bathroom}</p>
                </li>}
                {property?.garage &&<li>
                  Garages: <p>{property?.garage}</p>
                </li>}
              </ul>
              <ul>
                <li>
                  Property Type:{" "}
                  <p>
                    {property?.property_type === "office_complex"
                      ? "Office Complex"
                      : property?.property_type}
                  </p>
                </li>
                <li>
                  Property Size:{" "}
                  <p>
                    {property?.size ||
                      property?.complex_size ||
                      property?.warehouse_size}{" "}
                    Sqft
                  </p>
                </li>
                <li>
                  Property Status:{" "}
                  <p
                    className={
                      property?.status === "inactive"
                        ? "text-red-500"
                        : "text-green-500"
                    }
                  >
                    {property?.status}
                  </p>
                </li>
                <li>
                  Property Price: <p>₦{formatPrice(property?.price)}</p>
                </li>
              </ul>
              {/* <ul>
                <li>
                  Bathrooms: <p>2</p>
                </li>
                <li>
                  Garages: <p>2</p>
                </li>
                <li>
                  Property Price: <p>$230,000</p>
                </li>
              </ul> */}
            </div>
          </article>
          {/* features */}
          <article className="py-2 my-6">
            <h2 className="featureText">Features</h2>
            <div className="flex flex-wrap gap-4 justify-between items-start">
              {checkedFeatures
                .filter((option) => option.checked) // Filter out only the features that are checked
                .map((option, index) => (
                  <div
                    key={index}
                    className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  >
                    <label className="flex items-center gap-1 text-xl">
                      <input
                        type="checkbox"
                        checked={option.checked}
                        disabled
                        className="text-asparagus-600"
                      />
                      <p>{formatPropertyName(option.name)}</p>
                    </label>
                  </div>
                ))}
            </div>
          </article>
          {/* Rules */}
          <article className="py-2 my-6">
            <h2 className="featureText">Rules</h2>
            <div className="flex flex-wrap gap-4 justify-between items-start">
              {checkedRules
                .filter((option) => option.checked) // Filter out only the features that are checked
                .map((option, index) => (
                  <div
                    key={index}
                    className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
                  >
                    <label className="flex items-center gap-1 text-xl">
                      <input
                        type="checkbox"
                        checked={option.checked}
                        disabled
                        className="text-asparagus-600"
                      />
                      <p>{formatPropertyName(option.name)}</p>
                    </label>
                  </div>
                ))}
            </div>
          </article>
        </div>
        <div className="w-full  md:w-[30%] xl:w-[20%] shadow-lg rounded-md overflow-hidden">
          <div className="bg-mosque-400 text-white p-3 ">
            <h2>Home Owner Corner</h2>
          </div>
          <article className="my-5 mx-1 flex gap-3 p-3">
            <img
              src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt="person"
              className="rounded-full w-[100px] h-[100px]"
            />
            <div>
              <h2 className="font-semibold text-xl">Person Persons</h2>
              <p className="text-md">Agency Name</p>
            </div>
          </article>
          <article className="p-3 flex flex-col justify-between gap-8">
            <div>
              <h2 className="font-semibold text-lg">
                Book an inspection for this property
              </h2>
              <button
                type="button"
                className="submitBtn !bg-mosque-400"
                onClick={handleBookInspection}
              >
                Book Inspection
              </button>
            </div>
            <div>
              <h2 className="font-semibold text-lg">
                Proceed to pay for this property
              </h2>
              <button
                type="button"
                className="submitBtn"
                onClick={handlePayment}
              >
                Pay for property
              </button>
            </div>
          </article>
        </div>
      </section>
      {/* similar property */}
      <section className="shadow-lg flex flex-col gap-8 p-8">
        <div>
          <h1 className="text-xl lg:text-3xl font-bold text-asparagus-700">
            Similar Property
          </h1>
          <p className="text-lg">
            Discover similar properties and contact multiple agents
          </p>
        </div>
        <div className="displayData">
          {similarProperty
            ?.slice(0,4).filter((property) => {
              return property.id.toString() !== id.toString();
            })
            .map((x) => {
              return (
                <PropertyCard
                  id={x.id}
                  tag={x.tag}
                  type={x.property_type}
                  sqrFt={x.size}
                  state={x.state}
                  key={x.id}
                  location={x.location}
                  price={x.price}
                  bed={x.no_of_bedroom}
                  seeker={true}
                  owner={false}
                  bath={x.no_of_bathroom}
                  garage={x.garage}
                  agentName={x.created_by}
                  date={x.created_at}
                  propertyName={x.property_name}
                  address={x.street_name}
                  image={
                    type === "apartment"
                      ? `https://homelinker.itl.ng/public/upload/apartments/images/${x.image_1}`
                      : type === "shortlet"
                      ? `https://homelinker.itl.ng/public/upload/shortlets/images/${x.image_1}`
                      : type === "airbnb"
                      ? `https://homelinker.itl.ng/public/upload/airbnbs/images/${x.image_1}`
                      : type === "warehouse"
                      ? `https://homelinker.itl.ng/public/upload/Warehouses/images/${x.image_1}`
                      : type === "Office Complex"
                      ? `https://homelinker.itl.ng/public/upload/Complexs/images/${x.image_1}`
                      : `https://homelinker.itl.ng/public/upload/studioapartments/images/${x.image_1}`
                  }
                />
              );
            })}
        </div>
      </section>
      {/* popup section */}
      <section>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <div className="flex flex-col items-center gap-4">
              <h2 className="text-2xl">Make contact with our Representative</h2>
              <p>
                Any payment made outside HomeLinkers is not our responsibility
              </p>
              <div className="flex  gap-4 items-center">
                <a
                  href={whatsappUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-asparagus-600 px-4 rounded-md py-1 text-white font-medium"
                >
                  Contact Rep
                </a>
                <button
                  type="button"
                  className="bg-red-600 px-4 rounded-md py-1 text-white font-medium"
                  onClick={() => setIsModalOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        )}
        {showPopup && (
          <AnyModal
            handleClosePopup={handleClosePopup}
            content={<ViewPicVid images={images} videos={videos} />}
          />
        )}
      </section>
    </section>
  );
};

export default SingleProperty;
