import React from 'react'

const BreadCrumb = ({ items, currentStep, onStepClick }) => {
 return (
  <div className="flex items-center space-x-2 text-gray-500 py-4">
   {items.map((crumb, index) => (
    <span key={index}>
     {index > 0 && (
      <span className="mx-1">&#62;</span>
     )}
     {currentStep > index + 1 ? (
      <span
       className="cursor-pointer hover:text-gray-700"
       onClick={() => onStepClick(index + 1)}
      >
       {crumb}
      </span>
     ) : (
      <span className="font-bold">{crumb}</span>
     )}
    </span>
   ))}
  </div>
 );
}

export default BreadCrumb