import React, { useState, useEffect } from 'react'
import CustomInput from '../../../Components/CustomInput'
import { type } from '../../../data'
import { auth } from '../../../services/api/auth'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { calculatePasswordStrength } from '../../../Components/Reuseables'
import Loading from '../../../Components/Loading'




const Owner = () => {
 const navigate = useNavigate()
 useEffect(() => {
  document.title = 'Register as a Home Owner';
 }, []);
 const [loading, setLoading] = useState(false);
 const [ownerDetails, setOwnerDetails] = useState({
  agencyType: '',
  agencyName: '',
  location: '',
  email: '',
  fullname: '',
  password: '',
  confirm_password: '',
  commission: '',
  phone: ''
 })
 const [passwordStrength, setPasswordStrength] = useState('');
 const [agreeToTerms, setAgreeToTerms] = useState(false);

 const handleValidation = (password) => {
  const strength = calculatePasswordStrength(password);
  setPasswordStrength(strength);
 };


 const validateForm = () => {
  const {
   agencyType,
   agencyName,
   location,
   email,
   fullname,
   password,
   confirm_password,
   commission,
   phone
  } = ownerDetails;

  const isPasswordMatch = password === confirm_password;
  const isRequiredFieldsFilled =
   agencyType !== '' &&
   agencyName !== '' &&
   location !== '' &&
   email !== '' &&
   fullname !== '' &&
   password !== '' &&
   confirm_password !== '' &&
   commission !== '' &&
   phone !== ''

  const isCheckboxChecked = agreeToTerms;


  return isRequiredFieldsFilled && isPasswordMatch && isCheckboxChecked;
 };

 const handleSubmit = () => {
  setLoading(true);
  const formData = new FormData()

  formData.append('firstname', ownerDetails.fullname)
  formData.append('agency_name', ownerDetails.agencyName)
  formData.append('location', ownerDetails.location)
  formData.append('email', ownerDetails.email)
  formData.append('phone_number', ownerDetails.phone)
  formData.append('commission', ownerDetails.commission)
  formData.append('account_type', ownerDetails.agencyType)
  formData.append('password', ownerDetails.password)
  formData.append('password_confirmation', ownerDetails.confirm_password)

  if (validateForm()) {
   auth.registerOwner(formData).then((res) => {
    if (res.status === 200) {
     toast.success('Account Created Successfully');
     navigate('/auth/rep/login')
    }
   }).catch((res) => { toast.error(res.response.data.message); console.log(res) }).finally(() => setLoading(false))
  } else {
   // Handle validation error
   toast.error('Form validation failed.');
  }
 };

 const handleChange = (e) => {
  const { name, value } = e.target
  setOwnerDetails((prev) => ({
   ...prev,
   [name]: value

  }))
  if (name === 'password') {
   handleValidation(value);
  }
 }

 return (
  <form>
   <article className='form_input_container'>
    <label htmlFor={"agencyType"}>Agency Type <span className='important'>*</span></label>
    <select name={"agencyType"} value={ownerDetails.agencyType} className='formInput' onChange={handleChange}>
     <option value="">select a type</option>
     {
      type.map((x, y) => {
       return (
        <option key={y} value={x.name}>{x.name}</option>
       )
      })
     }
    </select>
   </article>
   <CustomInput value={ownerDetails.agencyName} placeholder={"Agency Name"} name={"agencyName"} type={"text"} label={"Agency Name"} handleChange={handleChange} important />

   <article className='form_input_container'>
    <label htmlFor="location">Location <span className='important'>*</span></label>
    <select name="location" id="location" value={ownerDetails.location} onChange={handleChange} className='formInput'>
     <option value="">Select your location</option>
     {
      ['Nigeria', 'United Kingdom', 'United States'].map((x, y) => {
       return (
        <option key={y + 1} value={x}>{x}</option>
       )
      })
     }
    </select>
   </article>

   <CustomInput value={ownerDetails.fullname} placeholder={"Your Fullname"} name={"fullname"} type={"text"} label={"Full Name"} handleChange={handleChange} important />
   
   <CustomInput value={ownerDetails.email} placeholder={"Your email"} name={"email"} type={"email"} label={"Email"} handleChange={handleChange} important />
   <CustomInput value={ownerDetails.phone} placeholder={"International format, eg +234, +44"} name={"phone"} type={"tel"} label={"Whatsapp number"} handleChange={handleChange} important />


   <CustomInput value={ownerDetails.password} placeholder={"Your password"} name={"password"} type={"password"} label={"Password"} handleChange={handleChange} important info={<div className='flex flex-col'><small>Password should contain Capital letter(A), Small letter(a), Number(9), Special characters(@#$%) and {'>'} 8 characters</small>{passwordStrength && <p>Password Strength: <span className={passwordStrength === 'Weak' ? 'text-red-500' : passwordStrength === 'Moderate' ? 'text-green-300' : passwordStrength === 'Strong' ? 'text-green-800' : 'text-gray-300'}>{passwordStrength}</span></p>}</div>} />

   <CustomInput value={ownerDetails.confirm_password} placeholder={""} name={"confirm_password"} type={"password"} label={"Confirm password"} handleChange={handleChange} important info={
    ownerDetails.password === ownerDetails.confirm_password && <p className='text-green-800'>Passwords match</p>
   } />

   <CustomInput value={ownerDetails.commission} placeholder={"Agreed Commission, eg: 10"} name={"commission"} type={"text"} label={"Agreed commision"} handleChange={handleChange} />
   <article className='flex gap-1 mb-[10px] items-center'>
    <input type="checkbox" name="policy" id="policy" checked={agreeToTerms}
     onChange={() => setAgreeToTerms(!agreeToTerms)} />
    <p>I agree to HomeLinkers policy and terms of use</p>
   </article>
   <button className={(validateForm() || loading === true) ? 'submitBtn' : 'disabledBtn'} type='button' disabled={(!validateForm() || loading === true)} onClick={handleSubmit}>{loading ? <Loading className={'w-[40px]'} /> : 'Sign up'}</button>
  </form>
 )
}

export default Owner