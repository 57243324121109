import React, { useEffect } from 'react'
import Transaction from '../../../Components/Transaction'


const Transactions = () => {
  useEffect(() => {
    document.title = `Your recent transactions`
  }, [])
  return (
    <section>
      <div></div>
      <div className="px-3 overflow-x-scroll my-4 myoverflow">
        <table className="min-w-full bg-white border  divide-y divide-gray-200 w-full table-auto">
          <thead>
            <tr className=''>
              <th className="tableHead">Property</th>
              <th className="tableHead">Details</th>
              <th className="tableHead">Payment Status</th>
              <th className="tableHead">Price</th>
              <th className="tableHead">Tag</th>
              <th className="tableHead">Buyer</th>
            </tr>
          </thead>
          <tbody>
            {
              [1,2,3,4].map((x,y)=>{
                return(
                  <Transaction key={y}/>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default Transactions