import React from 'react'


const TestimonialCard = ({name, location, review, image}) => {
  return (
    <article className='testimonialCard'>
      <div>
        <p className='md:text-[25px]'>{review}</p>
      </div>
      <div className='flex gap-4 mt-auto'>
        <img src={image} alt="testimonial_person" width={40} height={40} className='rounded-full w-[40px] h-[40px] lg:w-[70px] lg:h-[70px] object-cover' />
        <div>
          <h3>{name}</h3>
          <p>{location}</p>
        </div>
      </div>
    </article>
  )
}

export default TestimonialCard