import React, { useState } from 'react'
import CustomInput from '../../Components/CustomInput'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../services/api/auth'
import { useUser } from '../../Context/UserProvider'
import { toast } from 'react-toastify'
import service from "../../services"
import Loading from '../../Components/Loading'
import { useEffect } from 'react'


const RepOwnerLogin = () => {

  useEffect(() => {
    document.title = `Login as a Home Owner Rep`;
  }, []);

  const [loading, setLoading] = useState(false);
  const [logindetails, setDetails] = useState({
    email: '',
    password: ''
  })
  const { setUser } = useUser()
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setDetails((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!logindetails.email || !logindetails.password) {
      toast.error('Please enter both email and password');
      return;
    }
    setLoading(true);
    const formData = new FormData()
    formData.append('email', logindetails.email)
    formData.append('password', logindetails.password)
      auth.loginOwner(formData).then((res) => {
        if (res.status === 200) {
          toast.success('Logged in Successfully')
          setUser(res.data?.user)
          service.setTokensLocalStorage(res.data?.authorization?.token)
          navigate(`/dashboard/${res.data?.user?.role}`)
        }
      }).catch((res) => toast.error(res.response.data?.message)).finally(() => setLoading(false))
  
  }

  return (
    <div className='shadow-xl  p-4'>
      <div className='text-center'>
        <h2 className='text-asparagus-700 text-[35px] font-bold'>Login</h2>
        <div>
           <p className='text-xl'>You are about to login as a Home Owner Rep</p>
        </div>
      </div>
      <form>
        <CustomInput value={logindetails.email} placeholder={"Your email"} name={"email"} type={"email"} label={"Email"} handleChange={handleChange} />
        <CustomInput value={logindetails.password} placeholder={"Your password"} name={"password"} type={'password'} label={"Password"} handleChange={handleChange} />

        <Link to='/auth/forgot-password' className='text-mosque-600 text-sm ml-auto'>Forgot Password ?</Link>

        <div className='mt-5'>
          {/* <button type="button" className={'submitBtn'} onClick={handleSubmit} >{loading ? <Loading className={'w-[40px]'} /> : 'Login'}</button> */}
          <button type="button" className={(loading === true) ? 'disabledBtn' : 'submitBtn'} onClick={handleSubmit} disabled={loading === true}>{loading ? <Loading className={'w-[40px]'} /> : 'Login'}</button>
          <p className='mt-4 text-center text-lg font-semibold'>Don't have an account ? <span className='text-mosque-900 font-semibold'><Link to='/owner-or-seeker'>Sign Up</Link></span></p>
        </div>
      </form>
    </div>
  )
}

export default RepOwnerLogin