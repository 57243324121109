import axios from "axios";
import routes from "../routes";

export const auth = {
 registerOwner: async (postBody) => {
  return await axios.post(routes.register("homeowner"), postBody);
 },
 registerSeeker: async (postBody) => {
  return await axios.post(routes.register("homeseeker"), postBody);
 },
 registerRep: async (postBody) => {
  return await axios.post(routes.register("rep"), postBody);
 },
 loginAdmin: async (postBody) => {
  return await axios.post(routes.login("admin"), postBody);
 },
 loginSeeker: async (postBody) => {
  return await axios.post(routes.login("homeseeker"), postBody);
 },
 loginOwner: async (postBody) => {
  return await axios.post(routes.login("homeowner"), postBody);
 },
 loginRep: async (postBody) => {
  return await axios.post(routes.login("rep"), postBody);
 },
 refreshToken: async (token) => {
  return await axios.post(routes["refresh-token"](), token);
 },
 resetPassword: async (postBody) => {
  return await axios.post(routes["reset-password"]('homeseeker'), postBody)
 },
 activatePassword: async (postBody) => {
  return await axios.post(routes["activate-password"]('homeseeker'), postBody)
 }
};
