import React, { useState } from 'react'
import CustomInput from '../../Components/CustomInput'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../services/api/auth'
import { useUser } from '../../Context/UserProvider'
import { toast } from 'react-toastify'
import service from "../../services"
import Loading from '../../Components/Loading'
import { useEffect } from 'react'


const AdminLogin = () => {

 useEffect(() => {
  document.title = `Admin Login`;
 }, []);

 const [loading, setLoading] = useState(false);
 const [logindetails, setDetails] = useState({
  email: '',
  password: ''
 })
 const { setUser } = useUser()
 const navigate = useNavigate()

 const handleChange = (e) => {
  const { name, value } = e.target
  setDetails((prev) => ({
   ...prev,
   [name]: value
  }))
 }

 const handleSubmit = (e) => {
  e.preventDefault()
  setLoading(true);
  const formData = new FormData()
  formData.append('email', logindetails.email)
  formData.append('password', logindetails.password)
  auth.loginAdmin(formData).then((res) => {
    if (res.status === 200) {
     toast.success('Logged in Successfully')
     setUser(res.data?.user)
     service.setTokensLocalStorage(res.data?.authorization?.token)
     navigate('/dashboard/admin')
    }
   }).catch((res) => toast.error(res.response.data?.message)).finally(() => setLoading(false))
 }

 return (
  <div className='shadow-xl  p-4'>
   <div className='text-center'>
    <h2 className='text-asparagus-700 text-[35px] font-bold'>Login</h2>
    <div>
     <p className='text-xl'>Admin Login</p>
    </div>
   </div>
   <form>
    <CustomInput value={logindetails.email} placeholder={"Your Email"} name={"email"} type={"email"} label={"Email"} handleChange={handleChange} />
    <CustomInput value={logindetails.password} placeholder={"Your Password"} name={"password"} type={'password'} label={"Password"} handleChange={handleChange} />

    <Link to='/auth/forgot-password' className='text-mosque-600 text-sm ml-auto'>Forgot Password ?</Link>

    <div className='mt-5'>
     <button type="button" className={(loading === true) ? 'disabledBtn' : 'submitBtn'} onClick={handleSubmit} disabled={loading === true}>{loading ? <Loading className={'w-[40px]'} /> : 'Login'}</button>
    </div>
   </form>
  </div>
 )
}

export default AdminLogin