import React from 'react'
import CustomInput from '../../Components/CustomInput'
import { useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { auth } from '../../services/api/auth';

const ResetPassword = () => {
 const navigate = useNavigate()
 const [passwordStrength, setPasswordStrength] = useState('');
 // set page title
 useEffect(() => {
  document.title = 'Reset Password';
 }, []);

 const [searchParams] = useSearchParams()
 const email = searchParams.get('email')
 const token = searchParams.get('token')

 const [newP, setNewP] = useState({
  password: '',
  confirm_password: '',
  token: token
 })

 console.log(email)
 console.log('token', token)


 const calculatePasswordStrength = (password) => {
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (hasLowercase && hasUppercase && hasNumber && hasSpecialChar && password.length >= 8) {
   return 'Strong';
  } else if ((hasLowercase || hasUppercase || hasNumber || hasSpecialChar) && password.length >= 6) {
   return 'Moderate';
  } else {
   return 'Weak';
  }
 };

 const handleValidation = (password) => {
  const strength = calculatePasswordStrength(password);
  setPasswordStrength(strength);
 };

 const handleChange = (e) => {
  const { name, value } = e.target;
  setNewP((prev) => ({
   ...prev,
   [name]: value
  }))
  if (name === 'password') {
   handleValidation(value);
  }
 }

 const validateForm = () => {
  const {
   password,
   confirm_password,
  } = newP;

  const isPasswordMatch = password === confirm_password;
  const isRequiredFieldsFilled =
   password !== '' &&
   confirm_password !== ''

  return isRequiredFieldsFilled && isPasswordMatch
 };

 const handleSubmit = (e) => {
  e.preventDefault();
  const formData = new FormData()
  formData.append('token', newP.token)
  formData.append('email', email)
  formData.append('new_password', newP.password)
  formData.append('confirm_password', newP.confirm_password)

  if (validateForm()) {
   auth.activatePassword(formData).then((res) => { console.log(res); toast.success(res.data?.message); navigate('/auth/login') }).catch((res) => console.log(res))
  } else {
   toast.error('Passwords dont match ')
  }
 }


 return (
  <div>
   <div>
    <h2 className='text-2xl lg:text-4xl text-asparagus-600 font-semibold text-center'>Password Reset</h2>
   </div>
   <form>
    <CustomInput label={'Token'} value={newP.token} handleChange={handleChange} name={'token'} type={'text'} />
    <CustomInput label={'New Password'} value={newP.password} handleChange={handleChange} name={'password'} type={'password'} info={<div className='flex flex-col'><small>Password should contain Capital letter(A), Small letter(a), Number(9), Special characters(@#$%) and {'>'} 8 characters</small>{passwordStrength && <p>Password Strength: <span className={passwordStrength === 'Weak' ? 'text-red-500' : passwordStrength === 'Moderate' ? 'text-green-300' : passwordStrength === 'Strong' ? 'text-green-800' : 'text-gray-300'}>{passwordStrength}</span></p>}</div>} />
    <CustomInput label={'Confirm Password'} value={newP.confirm_password} handleChange={handleChange} name={'confirm_password'} type={'password'} />

    <button type='button' onClick={handleSubmit} className='submitBtn'>Reset</button>
   </form>
  </div>
 )
}

export default ResetPassword