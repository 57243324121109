import React, { useEffect, useState } from 'react'
import { CiHeart } from "react-icons/ci";
import { formatPrice } from '../Reuseables';
import { toast } from 'react-toastify';
import { MdOutlineDelete } from "react-icons/md";
import { FaPen, FaHeart } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserProvider';
import { useWishlist } from '../../Context/WishListProvider';
import api from '../../services/api';
import NoImage from '../../assets/noImage.jpg'


const Hotel = ({ price, location, name, id, chargeRate, seeker, owner, handleDeleteByOwner, state, handleEditByOwner, image }) => {
  const navigate = useNavigate()
  const { user } = useUser();
  const {wishlist} = useWishlist()
  const isUserLoggedIn = () => user && Object.keys(user).length > 0

  const[liked, setLiked] = useState(false)

  const handleAddWish = (e) => {
    e.stopPropagation();
    const formData = new FormData()
    formData.append('property_id', id)
    formData.append('property_type', 'hotel')
    if(isUserLoggedIn()){
      api.homeSeeker.wish.addToWishlist(formData).then((res)=>{
        if(res.status === 200){
          toast.success('Wish added')
          setLiked(true)
        }
      }).catch((err) => console.log(err))
    }else{
      navigate('/auth/login')
    }
  }

  useEffect(()=>{
    const isLiked = wishlist.some(item => item.property_id.toString() === id.toString());
    setLiked(isLiked);
  },[id, wishlist])

   const handleDelete =(e, id) =>{
    e.stopPropagation();
    handleDeleteByOwner(id)
   }
   const handleEdit =(e, id) =>{
    e.stopPropagation();
    handleEditByOwner(id)
   }
 return (
  <article className='hotelCardProperty' id={id} onClick={() => navigate(`/property/${id}/${'hotel'}`)}>
   {/* figure */}
   <figure className='propCard_figure'>
    <img src={image} alt="house"  className='w-full h-full  object-cover' onError={(e) => {
        e.target.onerror = null;
        e.target.src = NoImage;
      }}/>
    <div className='propCard_absolute left-3'>
     <p className="propCard_tag">{'Hotel'}</p>
    </div>
      <article className='propCard_absolute propCard_right '>
        <div className=' cursor-pointer'>
        {seeker && liked ? <FaHeart className="cursor-pointer fill-red-600" />:<CiHeart onClick={handleAddWish} />}
        </div>
        <div className='cursor-pointer'>
         {owner && <MdOutlineDelete  onClick={(e)=>handleDelete(e,id)}/>}
        </div>
        <div className='cursor-pointer'>
        {owner && <FaPen onClick={(e)=>handleEdit(e,id)}/>}
        </div>
      </article>
    <p className="propCard_price">₦{formatPrice(price)}/{chargeRate}</p>
   </figure>
   {/* other info */}
   <div className='propCard_info'>
    <h2 className='text-lg'>{state}, {location}</h2>
    <h2 className='text-xl font-bold'>
     {name}
    </h2>
   </div>
  </article>
 )
}

export default Hotel