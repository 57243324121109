import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


const ViewAgent = () => {
  const { ownerId } = useParams()
  console.log(ownerId)
  const navigate = useNavigate()
  const owner = {
    id: 1,
    fullName: 'John Doe',
    contact: {
      phoneNumber: '123-456-7890',
      email: 'john.doe@example.com',
      address: '123 Main Street, Cityville',
    },
    profilePicture: 'https://placekitten.com/200/200', // Replace with a real image URL
    ownedProperties: [
      {
        id: 101,
        address: '456 Oak Avenue, Townsville',
        type: 'House',
        description: 'Beautiful house with a garden',
        features: ['3 bedrooms', '2 bathrooms', 'Spacious living room'],
        status: 'Active',
      },
      // Add more properties if needed
    ],
    account: {
      username: 'johndoe123',
      creationDate: '2023-01-01',
      lastLoginDate: '2023-02-15',
    },
    transactions: [
      {
        id: 1001,
        propertyId: 101,
        date: '2023-03-01',
        amount: 1500,
        type: 'Rent',
      },
      // Add more transactions if needed
    ],
    verificationStatus: 'Verified',
    approvalStatus: 'Approved',
  };
  useEffect(() => {
    document.title = `Viewing ${owner.fullName} details`
  }, [owner.fullName])
  return (
    <div className="w-full">
      <div className="flex flex-col items-start justify-between mb-4">
        <p onClick={()=>navigate(-1)} className="text-blue-500 hover:underline">Back to Owners List</p>
        <h1 className="text-3xl font-bold">{owner.fullName} Details</h1>
      </div>

      <div className="flex items-center mb-8">
        <img src={owner.profilePicture} alt="Profile" className="w-16 h-16 rounded-full mr-4" />
        <div>
          <h2 className="text-xl font-bold">{owner.fullName}</h2>
          <p className="text-gray-600">{owner.contact.phoneNumber} | {owner.contact.email}</p>
          <p className="text-gray-600">{owner.contact.address}</p>
        </div>
      </div>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Owned Properties</h2>
        {owner.ownedProperties.map(property => (
          <div key={property.id} className="mb-4 border p-4 rounded">
            <h3 className="text-lg font-bold">{property.address}</h3>
            <p>Type: {property.type}</p>
            <p>Description: {property.description}</p>
            <p>Status: {property.status}</p>
          </div>
        ))}
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Account Information</h2>
        <p>Username: {owner.account.username}</p>
        <p>Account Created: {owner.account.creationDate}</p>
        <p>Last Login: {owner.account.lastLoginDate}</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Transactions</h2>
        {owner.transactions.map(transaction => (
          <div key={transaction.id} className="mb-4 border p-4 rounded">
            <p>Date: {transaction.date}</p>
            <p>Amount: ${transaction.amount}</p>
            <p>Type: {transaction.type}</p>
          </div>
        ))}
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Verification and Approval</h2>
        <p>Verification Status: {owner.verificationStatus}</p>
        <p>Approval Status: {owner.approvalStatus}</p>
      </section>
    </div>
  );
}

export default ViewAgent