import React, { useState, useRef, } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb';
import CustomInput from '../../../../Components/CustomInput';
import GoogleMapComponent from '../../../../Components/GoogleMap';
import { RxSwitch } from "react-icons/rx";
import Upload from '../../../../assets/upload.svg'
import { formatPropertyName } from '../../../../Components/Reuseables';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { useUser } from '../../../../Context/UserProvider';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../Components/Loading';
import { currencies } from '../../../../data';


const OfficeComplex = () => {
  const imageInputRefs = {
    image_1: useRef(null),
    image_2: useRef(null),
    image_3: useRef(null),
    image_4: useRef(null),
  };
  const videoInputRef = {
    video_1: useRef(null),
    video_2: useRef(null),
  };
  const [step, setStep] = useState(1);
  const [creating, setCreating] = useState(false)

  const { user } = useUser();

  const navigate = useNavigate()

  const [apartment, setApartment] = useState({
    propertyName: '',
    country: '',
    address: '',
    state: '',
    parking: 0,
    houseRules: {
      smoking: 0,
      pets: 0,
      parties: 0,
    },
    image_1: '',
    image_2: '',
    image_3: '',
    image_4: '',
    video_1: '',
    video_2: '',
    air_conditioner: 0,
    free_wifi: 0,
    generator: 0,
    office_room:0,
    service_charge_price:'',
    charge_rate:'',
    office_space_number:'',
    office_space_size:'',
    size:'',
    price: '',
    price_charge_rate:'',
    tag: 'Warehouse',
    overview:'',
    currency:''
  })
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApartment((prevApartment) => ({
      ...prevApartment,
      [name]: value,
    }));
  };
  // map click
  const handlePinDrop = (coordinates) => {
    setApartment((prev) => ({
      ...prev,
      map: coordinates
    }))
  }
  // houserules
  const handleHouseRuleChange = (rule) => {
    setApartment((prevApartment) => ({
      ...prevApartment,
      houseRules: {
        ...prevApartment.houseRules,
        [rule]: prevApartment.houseRules[rule] === 1 ? 0 : 1,
      },
    }));
  };


  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    setStep(step - 1);
  };
  const handleCheckboxChange = (option) => {
    setApartment((prevApartment) => ({
      ...prevApartment,
      [option]: prevApartment[option] === 1 ? 0 : 1, // Toggle the checkbox value
    }));
  };

  // handle upload 
  const handleFileChange = (event, fileType, fileNumber) => {
    const selectedFile = event.target.files[0];
    // Check file size based on fileType
    const maxSize = fileType === 'photo' ? 12 * 1024 * 1024 : 40 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      alert(`File size exceeds the allowed limit (${maxSize / (1024 * 1024)} MB). Please choose a smaller file.`);
      return;
    }
    setApartment((prevDetails) => ({
      ...prevDetails,
      [`${fileType}_${fileNumber}`]: selectedFile,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    setCreating(true)


    formData.append('complex_name', apartment.propertyName)
    formData.append('street_name', apartment.address)
    formData.append('location', apartment.country)
    formData.append('state', apartment.state)
    formData.append('complex_size', apartment.size)
    formData.append('service_charge_rate', apartment.charge_rate)
    formData.append('service_charge_price', apartment.service_charge_price)
    formData.append('free_wifi', apartment.free_wifi)
    formData.append('office_room', apartment.office_room)
    formData.append('office_room_size', apartment.office_space_size)
    formData.append('generator', apartment.generator)
    formData.append('air_conditioner', apartment.air_conditioner)
    formData.append('parking', apartment.parking)
    formData.append('smoking', apartment.houseRules.smoking)
    formData.append('parties', apartment.houseRules.parties)
    formData.append('pets', apartment.houseRules.pets)
    formData.append('tag', apartment.tag)
    formData.append('price', apartment.price)
     // Check if the files are already URLs or need to be appended as files
  if (apartment.image_1 instanceof File) {
    formData.append('image_1', apartment.image_1);
  }
  if (apartment.image_2 instanceof File) {
    formData.append('image_2', apartment.image_2);
  }
  if (apartment.image_3 instanceof File) {
    formData.append('image_3', apartment.image_3);
  }
  if (apartment.image_4 instanceof File) {
    formData.append('image_4', apartment.image_4);
  }
  if (apartment.video_1 instanceof File) {
    formData.append('video_1', apartment.video_1);
  }
  if (apartment.video_2 instanceof File) {
    formData.append('video_2', apartment.video_2);
  }
    formData.append('overview', apartment.overview)
    formData.append('currency', apartment.currency)

    api.homeOwner.officeComplex.createOfficeComplex(formData).then((res) => {
      if(res.status === 200){
        toast.success(res.data.message)
        navigate(`/dashboard/${user.role}/all-hotels`)
        setApartment(apartment)
      }
    })
    .catch((res) => {
      setCreating(false)
      console.log(res)
    }).finally(()=>setCreating(false))
  };



  // Handle stages rendering
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <section>
            <h2>Step 2: Basic Details</h2>
            <div>
              <form>
                <CustomInput value={apartment.propertyName} placeholder={"Complex name"} name={"propertyName"} type={'text'} label={"Complex Name"} handleChange={handleChange} />
                <article className='form_input_container'>
                  <label htmlFor="location">Location <span className='important'>*</span></label>
                  <select name="country" id="country" value={apartment.country} onChange={handleChange} className='formInput'>
                    <option value="">Select your location</option>
                    {
                      ['Nigeria', 'United Kingdom', 'United States'].map((country) => {
                        return (
                          <option key={country}>{country}</option>
                        )
                      })
                    }
                  </select>
                </article>
                <CustomInput value={apartment.address} placeholder={""} name={"address"} type={'text'} label={"Street name and Complex number"} handleChange={handleChange} />
                <CustomInput value={apartment.state} placeholder={""} name={"state"} type={'text'} label={"State"} handleChange={handleChange} />
                <div className='form_input_container'>
                  <label htmlFor="overview">Overview</label>
                  <textarea name="overview" className='formInput' id="overview" cols="30" rows="10" value={apartment.overview} onChange={handleChange}/>
                </div>
                <GoogleMapComponent onPinDrop={handlePinDrop} />
              </form>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </section>
        );
      case 2:
        return (
          <section>
            <h2>Step 2: Main Details</h2>
            <div>
            <CustomInput value={apartment.size} placeholder={"Complex size (per square meter)"} name={"size"} type={'text'} label={"Complex Size"} handleChange={handleChange} />
            <article className='form_input_container'>
                  <label htmlFor="country">Tag <span className='important'>*</span></label>
                  <select name="tag" id="" value={apartment.tag} onChange={handleChange} className='formInput'>
                    <option value="">Select your tag</option>
                    {
                      ['rent', 'sales', 'lease'].map((tag) => {
                        return (
                          <option className='capitalize' key={tag}>{tag}</option>
                        )
                      })
                    }
                  </select>
                </article>
            <div>
            
            <div className='flex flex-col md:flex-row gap-3'>
              <CustomInput value={apartment.service_charge_price} placeholder={"Complex service charge price"} name={"service_charge_price"} type={'text'} label={"Complex service charge Price"} handleChange={handleChange} />
               <article className='form_input_container'>
                    <label htmlFor="charge_rate">Service Charge Rate <span className='important'>*</span></label>
                    <select name="charge_rate" id="" value={apartment.charge_rate} onChange={handleChange} className='formInput capitalize'>
                      <option value="">Select your rate</option>
                      {
                        ['monthly', 'quaterly', 'yearly'].map((tag) => {
                          return (
                            <option className='capitalize' key={tag}>{tag}</option>
                          )
                        })
                      }
                    </select>
                  </article>
            </div>
            <div className='flex flex-col md:flex-row gap-3'>
              {/* <CustomInput value={apartment.office_space_number} placeholder={"How many office space does the complex have?"} name={"office_space_number"} type={'number'} label={"Number of Office rooms"} handleChange={handleChange} /> */}
              <CustomInput value={apartment.office_space_size} placeholder={"Minimum size of the Office room (per square meter)"} name={"office_space_size"} type={'number'} label={"Office room size"} handleChange={handleChange} />
            </div>
            </div>        
              <form>
                <div className='flex flex-col gap-2 my-6'>
                  <h1 className='headerTitle'>Amenities</h1>
                  <div className='flex flex-wrap gap-4 justify-between items-center'>
                      <article key={''} className='flex flex-col gap-3 w-auto'>
                        {['free_wifi','generator','air_conditioner','parking','office_room'].map((option) => (
                          <label key={option} className='flex items-center gap-1 text-xl'>
                            <input
                              type="checkbox"
                              checked={apartment[option]}
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <p>{formatPropertyName(option)}</p>
                          </label>
                        ))}
                      </article>
                  </div>
                </div>
                <div className='flex flex-col gap-2 my-6'>
                  <h1 className='headerTitle'>Office Complex Rules</h1>
                  <div>
                    {
                      ['smoking', 'pets', 'parties'].map((x, y) => {
                        return (
                          <article key={y} className='flex justify-between w-[300px] mb-4 text-xl'>
                            <p>{x}</p>
                            <RxSwitch values={apartment.houseRules[x]} onClick={() => handleHouseRuleChange(x)} className={`rotate-180 text-3xl text-gray-400 ${apartment.houseRules[x] === 1 && 'rotate-0 text-green-700'}`} />
                          </article>
                        )
                      })
                    }
                  </div>
                </div>
              </form>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </section>
        );
      case 3:
        return (
          <div>
            <h2>Step 3: Picture & Video</h2>
            <div className='flex flex-col gap-4'>
              {/* images */}
              <div className='flex gap-3 flex-wrap items-center justify-center'>
                {
                  [1, 2, 3, 4].map((fileNumber) => {
                    return (
                      <div key={fileNumber} onClick={() => imageInputRefs[`image_${fileNumber}`].current.click()} className='upload'>
                        <img src={apartment[`image_${fileNumber}`] ? URL.createObjectURL(apartment[`image_${fileNumber}`]) : Upload} alt='upload' className='w-[150px] h-[80%] object-cover' />
                        <input type="file" accept=".jpg, .jpeg, .png, .gif" onChange={(e) => handleFileChange(e, 'image', fileNumber)}
                          ref={imageInputRefs[`image_${fileNumber}`]} style={{ display: 'none' }} />
                        <p className='mt-auto'>Upload your images here, max file is (12mb)</p>
                      </div>
                    )
                  })
                }
              </div>
              {/* videos */}
              <div className='flex gap-4 justify-center'>
                {
                  [1, 2].map((fileNumber) => {
                    return (
                      <div key={fileNumber} onClick={() => videoInputRef[`video_${fileNumber}`].current.click()} className='upload'>
                        {
                          apartment[`video_${fileNumber}`] ?
                            (
                              <video controls width="auto" height="auto">
                                <source src={URL.createObjectURL(apartment[`video_${fileNumber}`])} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )
                            :
                            <img src={Upload} alt='upload' className='w-[150px]' />
                        }
                        <input type="file" accept="video/*" onChange={(e) => handleFileChange(e, 'video', fileNumber)} ref={videoInputRef[`video_${fileNumber}`]} style={{ display: 'none' }} />
                        <p>Upload your Videos here, max file is (40mb)</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Step 3: Final Details</h2>
            <div className='flex flex-col gap-4'>
              <article className='form_input_container'>
                    <label htmlFor="price_charge_rate">Charge Rate <span className='important'>*</span></label>
                    <select name="price_charge_rate" id="" value={apartment.price_charge_rate} onChange={handleChange} className='formInput capitalize'>
                      <option value="">Select your rate</option>
                      {
                        ['monthly', 'quaterly', 'yearly'].map((tag) => {
                          return (
                            <option className='capitalize' key={tag}>{tag}</option>
                          )
                        })
                      }
                    </select>
                  </article>
                  <div>
                <select name="currency" id="currency" className='formInput w-full md:w-[30%]' onChange={handleChange} value={apartment.currency}>
                  <option value="">Select currency</option>
                  {
                    currencies.map((currency)=>{
                      return(
                        <option key={currency.code} value={currency.symbol}>{currency.name}</option>
                      )
                    })
                  }
                </select>
                </div>
              <CustomInput value={apartment.price} label={`How much do you charge ${apartment.price_charge_rate}`} placeholder={'eg 2000000, or 20000.55 or 1500.00'} type={'text'} name="price" handleChange={handleChange} />
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleSubmit} className={!creating ? ` submitBtn` : 'disabledBtn'} disabled={creating === true}>{creating ? <Loading className={'w-[35px]'}/> : 'Submit'}</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <BreadCrumb items={['Basic Details', 'Complex Details', 'Other Details', 'Last Details']} currentStep={step} onStepClick={setStep} />
      {renderStepContent()}
    </div>
  )
}

export default OfficeComplex