import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate } from 'react-router-dom'
// pages
import { Home } from './Pages/Home'
import Auth from './Pages/Auth/Auth'
import Login from './Pages/Auth/Login'
import RepOwnerLogin from './Pages/Auth/RepOwnerLogin'
import Signup from './Pages/Auth/Signup'
import OwnerOrSeeker from './Pages/Auth/OwnerOrSeeker'
import WhatYouSeek from './Pages/WhatYouSeek'
import AddProperty from './Pages/Dashboard/Owner/AddProperty'
import Dashboard from './Pages/Dashboard/Owner/Dashboard'
import ViewUsers from './Pages/Dashboard/Admin/ViewUsers'
import ViewUser from './Pages/Dashboard/Admin/ViewUser'
import ViewAgents from './Pages/Dashboard/Admin/ViewAgents'
import ViewAgent from './Pages/Dashboard/Admin/ViewAgent'
import ViewSubscribers from './Pages/Dashboard/Admin/ViewSubscribers'
import AllProperties from './Pages/Dashboard/Admin/AllProperties'
import Transactions from './Pages/Dashboard/Admin/Transactions'
import FindApartmentPage from './Pages/FindApartmentPage'
import FindHotelPage from './Pages/FindHotelPage'
import ContactUs from './Pages/ContactUs'
import AboutUs from './Pages/AboutUs'
import ForgotPassword from './Pages/Auth/ForgotPassword'
import ResetPassword from './Pages/Auth/ResetPassword'
import ViewProperties from './Pages/Dashboard/Seeker/ViewProperties'
import MyProfile from './Pages/Dashboard/Seeker/MyProfile'
import Error404 from './Pages/Error404'
import Wishlist from './Pages/Dashboard/Seeker/Wishlist'
import SingleProperty from './Pages/SingleProperty'
import AllHotels from './Pages/Dashboard/Admin/AllHotels'
import AdminLogin from './Pages/Auth/AdminLogin'
import CtyRepRegistration from './Pages/Auth/CtyRepRegistration'
import AllShortlets from './Pages/Dashboard/Admin/AllShortlets'
import AllAirbnb from './Pages/Dashboard/Admin/AllAirbnb'
import AllStudioApartment from './Pages/Dashboard/Admin/AllStudioApartment'
// loading data
// import { homeDataLoader } from './Components/loadingData'

// layouts
import RootLayout from './Components/Layouts/RootLayout'
import DashboardLayout from './Components/Layouts/DashboardLayout'
// others
import { useUser } from './Context/UserProvider'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Location from './Pages/Location'
import AllWarehouse from './Pages/Dashboard/Admin/AllWarehouse'
import AllOfficeComplex from './Pages/Dashboard/Admin/AllOfficeComplex'




function App() {
  const { user } = useUser();

  const userRole = user.role;

  const isAdmin = userRole === 'admin';
  const isHomeowner = userRole === 'homeowner';
  const isHomeseeker = userRole === 'homeseeker';
  const isRep = userRole === 'rep';
  const isUserLoggedIn = () => user && Object.keys(user).length > 0


  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path='*' element={<Navigate to='/404' replace />} />
          <Route path='/404' element={<Error404 />} />
          {/* <Route index element={<Home />} loader={homeDataLoader} /> */}
          <Route path='/owner-or-seeker' element={<OwnerOrSeeker />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/location' element={<Location />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/what-you-seek' element={<WhatYouSeek />} />
          <Route path='/find-hotel' element={<FindHotelPage />} />
          <Route path='/property/:id/:type' element={<SingleProperty />} />
          <Route path='/find-apartment/:apartmentType' element={<FindApartmentPage />} />
          <Route path='/auth' element={isUserLoggedIn() ? <Navigate to='/' replace /> : <Auth />}>
            <Route path='admin/login' index element={<AdminLogin />} />
            <Route path='rep/login' index element={<RepOwnerLogin />} />
            <Route path='login' index element={<Login />} />
            <Route path='register/:type' element={<Signup />} />
            <Route path='register/ctyRep' element={<CtyRepRegistration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='reset-password/' element={<ResetPassword />} />
          </Route>
        </Route>
        <Route path={`/dashboard/admin`} element={isUserLoggedIn && isAdmin ? <DashboardLayout /> : (isUserLoggedIn ? <Navigate to='/404' replace /> : <Navigate to='/auth/login' replace />)}>
          <Route index element={<Dashboard />} />
          <Route path='my-profile' element={<MyProfile />} />
          <Route path='add-property' element={<AddProperty />} />
          <Route path='all-properties' element={<AllProperties />} />
          <Route path='all-hotels' element={<AllHotels />} />
          <Route path='view-subscribers' element={<ViewSubscribers />} />
          <Route path='view-users' element={<ViewUsers />} />
          <Route path='view-user/:buyerId' element={<ViewUser />} />
          <Route path='view-owners' element={<ViewAgents />} />
          <Route path='view-owner/:ownerId' element={<ViewAgent />} />
          <Route path='view-transactions' element={<Transactions />} />
        </Route>
        <Route path={`/dashboard/rep`} element={isUserLoggedIn && isRep ? <DashboardLayout /> : (isUserLoggedIn ? <Navigate to='/404' replace /> : <Navigate to='/auth/login' replace />)}>
          <Route index element={<Dashboard />} />
          <Route path='my-profile' element={<MyProfile />} />
          <Route path='all-properties' element={<AllProperties />} />
          <Route path='all-hotels' element={<AllHotels />} />
          <Route path='all-shortlets' element={<AllShortlets />} />
          <Route path='all-servicedAccomodation' element={<AllAirbnb />} />
          <Route path='all-studioApartments' element={<AllStudioApartment />} />
          <Route path='view-transactions' element={<Transactions />} />
        </Route>
        <Route path={`/dashboard/homeowner`} element={isUserLoggedIn && isHomeowner ? <DashboardLayout /> : (isUserLoggedIn ? <Navigate to='/404' replace /> : <Navigate to='/auth/login' replace />)}>
          <Route index element={<Dashboard />} />
          <Route path='add-property' element={<AddProperty />} />
          <Route path='my-profile' element={<MyProfile />} />
          <Route path='all-properties' element={<AllProperties />} />
          <Route path='all-shortlets' element={<AllShortlets />} />
          <Route path='all-hotels' element={<AllHotels />} />
          <Route path='all-servicedAccomodation' element={<AllAirbnb />} />
          <Route path='all-studioApartments' element={<AllStudioApartment />} />
          <Route path ='all-warehouse' element={<AllWarehouse />} />
          <Route path ='all-office_complex' element={<AllOfficeComplex />} />
          <Route path='view-transactions' element={<Transactions />} />
        </Route>
        <Route path={`/dashboard/homeseeker`} element={isUserLoggedIn && isHomeseeker ? <DashboardLayout /> : (isUserLoggedIn ? <Navigate to='/404' replace /> : <Navigate to='/auth/login' replace />)}>
          <Route index element={<Dashboard />} />
          <Route path='view-properties' element={<ViewProperties />} />
          <Route path='my-profile' element={<MyProfile />} />
          <Route path='view-transactions' element={<Transactions />} />
          <Route path='wishlist' element={<Wishlist />} />
        </Route>
      </>
    )
  )

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer autoClose={2000} />
    </>
  )
}

export default App
