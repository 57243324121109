import Spinner from "../assets/VAyR.gif"

const Loading = ({className}) => {
  return (
   <div className="h-full flex items-center justify-center w-full loadingDiv">
   <img src={Spinner} alt="spinner" className={className} />
  </div>
  )
}

export default Loading