import React from 'react'

const Subscriber = ({ email }) => {
 return (
  <article className='subscriber'>
   <p className='subscriber_text'>{email}</p>
  </article>
 )
}

export default Subscriber