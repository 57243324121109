import { Link, useParams } from 'react-router-dom'
import Owner from './RegistrationForm/Owner'
import Seeker from './RegistrationForm/Seeker'



const Signup = () => {
 const { type } = useParams()

 return (
  <div className='shadow-xl  p-4'>
   <div className='text-center'>
    <h2 className='text-asparagus-800 text-[25px] font-bold'>Create An Account</h2>
    <p className='underline decoration-double'>
     {type === 'home-owner' ? 'Home Owner' : 'Home Seeker'}
    </p>
   </div>
   <div>
    {type === 'home-owner' && <Owner />}
    {type === 'home-seeker' && <Seeker />}
   </div>
   <article className='my-[10px] '>
    <p className='mt-4 text-center text-lg font-semibold'>Already have an account ? <span className='text-mosque-900 font-semibold'><Link to='/auth/login'>Login</Link></span></p>
   </article>
  </div>
 )
}

export default Signup