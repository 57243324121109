import React, { useState, useEffect } from 'react';

function Location() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [country, setCountry] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            fetchCountry(position.coords.latitude, position.coords.longitude);
          },
          error => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };

    getLocation();
  }, []); // Empty dependency array ensures that this effect runs only once

  const fetchCountry = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
      );
      const data = await response.json();
      console.log(data)
      if (data.address && data.address.country) {
        setCountry(data.address.country);
      } else {
        setError('Country information not found.');
      }
    } catch (error) {
      setError('Failed to fetch country information.');
    }
  };

  return (
    <div>
      <h1>User Location</h1>
      {latitude && longitude ? (
        <div>
          <p>
            Latitude: {latitude}, Longitude: {longitude}
          </p>
          {country && <p>Country: {country}</p>}
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Location;
