import React, { useEffect, useRef, useState } from 'react'
import CustomInput from '../../../Components/CustomInput'
import { useUser } from '../../../Context/UserProvider'
import api from '../../../services/api'
import { CiEdit } from "react-icons/ci";
import { calculatePasswordStrength } from '../../../Components/Reuseables';
import { toast } from 'react-toastify';


const MyProfile = () => {
 useEffect(() => {
  document.title = 'My Profile'
 }, [])
 const profilePic = useRef(null)
 const { user, setUser } = useUser()
 const [formChanged, setFormChanged] = useState(false)


 const [me, setMe] = useState({
  firstname: user?.firstname || '',
  lastname: user?.lastname || '',
  email: user?.email || '',
  phone: user?.phone_number || '',
  address: user?.address || '',
  state: user?.state || '',
  country: user?.location || '',
  profile_pic: user?.photo || null,
  agency_name: user?.agency_name || ''
 });
 const [passwords, setPasswords] = useState({
  former: '',
  password: '',
  confirm_password: ''
 })
 const [loadingImage, setLoadingImage] = useState(false);

 const [passwordStrength, setPasswordStrength] = useState('');

 const handleValidation = (password) => {
  const strength = calculatePasswordStrength(password);
  setPasswordStrength(strength);
 };


 const handleChange = (e) => {
  const { name, value } = e.target
  setMe((prev) => ({
   ...prev,
   [name]: value
  }))
  setFormChanged(true)
 }


 const handlePasswordChange = (e) => {
  const { name, value } = e.target
  setPasswords((prev) => ({
   ...prev,
   [name]: value
  }))
  if (name === 'password') {
   handleValidation(value);
  }
 }

 const handleProfileUpdate = () => {
  const formData = new FormData();
  formData.append('firstname', me.firstname);
  formData.append('lastname', me.lastname);
  formData.append('location', me.country);
  formData.append('photo', me.profile_pic);


  if(user.role === 'homeseeker'){
    api.homeSeeker.profile.updateProfile(formData)
      .then((res) => {
        if(res.status === 200){
          setUser(res.data?.data)
          setFormChanged(false)
          toast.success('Profile updated successfully')
        }
      } )
      .catch((res) => console.log(res))
      .finally(()=>setLoadingImage(false));
  }else if(user.role === 'homeowner'){
    api.homeOwner.profile.updateProfile(formData)
      .then((res) => {
        if(res.status === 200){
          setUser(res.data?.data)
          setFormChanged(false)
          toast.success('Profile updated successfully')
        }
      })
      .catch((res) => console.log(res))
      .finally(()=>setLoadingImage(false));
  }

};

const handlePhotoChange = (event) => {
  const file = event.target.files[0];
  setMe((prev) => ({
    ...prev,
    profile_pic: file
   }))
  setLoadingImage(true);

  const formData = new FormData();
  formData.append('photo', file);
  formData.append('firstname', me.firstname);
  formData.append('lastname', me.lastname);
  formData.append('location', me.country);

  if(user.role === 'homeseeker'){
    api.homeSeeker.profile.updateProfile(formData)
      .then((res) => {
        if(res.status === 200){
          setUser(res.data?.data)
          setFormChanged(false)
          toast.success('Profile updated successfully')
        }
      })
      .catch((res) => console.log(res))
      .finally(()=>setLoadingImage(false));
  }else if(user.role === 'homeowner'){
    api.homeOwner.profile.updateProfile(formData)
      .then((res) => {
        if(res.status === 200){
          setUser(res.data?.data)
          setFormChanged(false)
          toast.success('Profile updated successfully')
        }
      })
      .catch((res) => console.log(res))
      .finally(()=>setLoadingImage(false));
  }
};



 return (
  <section className='flex flex-col gap-4'>
   <div>
    <h2 className='text-xl text-minsk-700 font-semibold'>Profile information</h2>
   </div>
   <div className='max-w-[250px] flex items-center justify-center'>
    <figure className='relative w-[150px] '>
     <div className="">
      {!loadingImage && <img src={`https://homelinker.itl.ng/public/upload/profile_pics/${user?.photo}` || 'https://cdn-icons-png.flaticon.com/256/149/149071.png'} alt="profile" className='h-[150px] w-auto rounded-full border-2 border-asparagus-600' />}
      {loadingImage && <div className={'animated-bg'}></div>}
     </div>
     <input type="file" accept={`image/png,image/jpeg`} onChange={handlePhotoChange} ref={profilePic} className='hidden' />
     <CiEdit className='absolute top-0 right-0 text-xl text-asparagus-800 bg-asparagus-300 p-1 cursor-pointer' onClick={() => profilePic.current.click()} />
    </figure>
   </div>
   <div>
    <div className='flex flex-col md:flex-row w-full gap-3'>
     <CustomInput value={me.firstname} placeholder={"Your First Name"} name={"firstname"} type={"text"} label={"First Name"} handleChange={handleChange} important />
     <CustomInput value={me.lastname} placeholder={"Your lastname"} name={"lastname"} type={"text"} label={"Last Name"} handleChange={handleChange} important />
    </div>
    <div className='flex flex-col md:flex-row w-full gap-3'>
     <CustomInput value={me.email} placeholder={"Your email"} name={"email"} type={"email"} label={"Email"} handleChange={handleChange} important disabled={true}/>
     <CustomInput value={me.phone} placeholder={"Your phone"} name={"phone"} type={"tel"} label={"Phone Number"} handleChange={handleChange} important />
    </div>
    <CustomInput value={me.address} placeholder={"Your address"} name={"address"} type={"text"} label={"Address"} handleChange={handleChange} important />

    {user.role !== 'homeseeker' && user.role !== 'admin' && <CustomInput value={me.agency_name} placeholder={"Your agency name"} name={"agency_name"} type={"text"} label={"Agency Name"} handleChange={handleChange} important />}
    <div className='flex w-full gap-3'>
     <article className='form_input_container'>
      <label htmlFor="location">Country <span className='text-red-800 text-xl font-extrabold'>*</span></label>
      <select name="country" id="country" value={me.country} onChange={handleChange} className='formInput'>
       <option value="">Select your location</option>
       {
        ['unted kingdom', 'nigeria', 'united states of america'].map((x, y) => {
         return (
          <option key={y + 1} value={x} className='uppercase'>{x}</option>
         )
        })
       }
      </select>
     </article>
     <CustomInput value={me.state} placeholder={"Your state"} name={"state"} type={"text"} label={"State"} handleChange={handleChange} important />
    </div>
    <button type="button" onClick={handleProfileUpdate} className={formChanged ?'submitBtn':'disabledBtn'} disabled={formChanged === false}>Update Profile</button>
   </div>

   <section className='mt-8'>
    <h2 className='text-xl text-minsk-700 font-semibold'>Change Password</h2>
    <CustomInput value={passwords.former} placeholder={""} name={"former"} type={"password"} label={"Former password"} handleChange={handlePasswordChange} important />

    <CustomInput value={passwords.password} placeholder={"Your password"} name={"password"} type={"password"} label={"New Password"} handleChange={handlePasswordChange} important info={<div className='flex flex-col'><small>Password should contain Capital letter(A), Small letter(a), Number(9), Special characters(@#$%) and {'>'} 8 characters</small>{passwordStrength && <p>Password Strength: <span className={passwordStrength === 'Weak' ? 'text-red-500' : passwordStrength === 'Moderate' ? 'text-green-300' : passwordStrength === 'Strong' ? 'text-green-800' : 'text-gray-300'}>{passwordStrength}</span></p>}</div>} />

    <CustomInput value={passwords.confirm_password} placeholder={""} name={"confirm_password"} type={"password"} label={"Confirm password"} handleChange={handlePasswordChange} important />
    
    <button type="button" className='submitBtn'>Update Password</button>
   </section>

  </section>
 )
}

export default MyProfile