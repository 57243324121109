import React from 'react'
import Niyi from '../../assets/home linker2.jpg'
import { useNavigate } from 'react-router-dom'
import { FiEye } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";

const OwnerCard = ({ openModal, activate, type, id, image, name, otherDetail, email }) => {
 const navigate = useNavigate()
 return (
  <article className='w-[250px] h-[350px] p-4 border-2 border-asparagus-100 flex flex-col gap-2 shadow-lg'>
   <figure className='h-[60%]'>
    <img src={image !== null ? `https://homelinker.itl.ng/public/upload/profile_pics/${image}` : Niyi} alt="profile" width={400} height={400} className='h-full object-cover' />
   </figure>
   <div className='h-[60%] flex flex-col gap-2'>
    <div className='flex flex-col gap-1'>
     <h2 className='text-2xl font-semibold'>{name}</h2>
     <small>{otherDetail}</small>
     <small>{email}</small>
    </div>
    <div className='flex gap-4 mt-auto'>
     <button type="button" className='border-2 px-2 border-minsk-500 rounded-md font-medium' onClick={() => navigate(`/dashboard/admin/view-${type}/${id}`)}><FiEye /></button>
     <button type="button" className='border-2 px-2 border-minsk-500 rounded-md font-medium' onClick={activate}>Activate</button>
     <button type="button" className='border-2 px-2 border-minsk-500 rounded-md font-medium' onClick={openModal}><MdDeleteForever /></button>
    </div>
   </div>
  </article>
 )
}

export default OwnerCard