import React, { useEffect, useState } from 'react'
import OwnerCard from '../../../Components/Cards/OwnerCard'
import Modal from '../../../Components/Cards/Modal';
import Danger from '../../../assets/danger-sign.png'
import api from '../../../services/api';

const ViewAgents = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [owners, setOwners] = useState([])

  const openModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    document.title = `Viewing Home owners`
    api.admin.getAllHomeOwners().then((res)=>{setOwners(res.data?.data)}).catch(res=>console.log(res))
  }, [])
  return (
    <section className='my-3'>
      <h2 className='text-center font-bold text-3xl text-asparagus-500'>All Home Owners</h2>
      <div className='displayData'>
        {
          owners.map((x, y) => {
            return (
              <OwnerCard name={x.firstname} image={x.photo} email={x.email} otherDetail={x.agency_name} key={y} activate={""} type={'owner'} id={x} openModal={openModal} />
            )
          })
        }
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='flex flex-col items-center gap-4'>
          <img src={Danger} alt="" className='w-[100px]' />
          <h2 className='text-2xl'>Are you sure you want to delete?</h2>
          <div className='flex  gap-4 items-center'>
            <button type="button" className='bg-asparagus-600 px-4 rounded-md py-1 text-white font-medium'>Yes</button>
            <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
          </div>
        </div>
      </Modal>
    </section>
  )
}

export default ViewAgents