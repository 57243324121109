import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/footerlogo.png'
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";

const Footer = () => {
 const currentYear = new Date().getFullYear();

 const footerData = [
  {
   name: 'first',
   title: 'Information',
   links: [
    { name: 'Contact Us', url: '/contact-us' },
    { name: 'Privacy Policy', url: 'https://docs.google.com/document/d/14om0K23j22k1EgF2aCug-4dDfVyKuMAP/edit?usp=drive_link&rtpof=true&sd=true', target: '_blank'},
    { name: 'Terms & Condition', url: 'https://docs.google.com/document/d/12aRO5LffE6Rlgt9gOIISpQgIP5CPGBF0/edit?usp=drive_link&rtpof=true&sd=true', target: '_blank'},
    { name: 'FAQs', url: '/faq' },
   ],
  },
  {
   name: 'second',
   title: 'Properties',
   links: [
    { name: 'Hotels', url: 'find-hotel' },
    { name: 'Shortlets', url: `find-apartment/Shortlet` },
    { name: 'Houses', url: `find-apartment/Houses` },
    { name: 'Flats', url: `find-apartment/Flat` },
    { name: 'Serviced Accommodation', url: `find-apartment/Serviced Accommodation` },
    { name: 'Office Complex', url: `find-apartment/Office Complex` },
    { name: 'Warehouses', url: `find-apartment/Warehouse` },
   ],
  },
  // {
  //  name: 'third',
  //  title: 'Useful Links',
  //  links: [
  //   { name: 'Blogs', url: 'blogs' },
  //   // { name: 'Country Representative', url: 'auth/register/ctyRep' },
  //  ],
  // },
 ]
 return (
  <footer className='bg-mosque-800 py-4 px-6 text-gray-400 mt-auto'>
   <div className='mb-[20px]'>
    <img src={Logo} alt="homelinker logo" className='w-[100px]' loading="lazy" width={'600'} height={'600'} />
   </div>
   {/* information */}
   <section className='flex flex-col md:flex-row flex-wrap px-2 gap-4 justify-evenly'>
   <div className='flex flex-col gap-6 w-full md:w-auto'>
      <h2 className='text-xl font-semibold text-left'>{'Contact Details'}</h2>
      <ul className='text-left'>
          <li  className='py-1 flex gap-2 items-center'>
          <FaPhone />
            <ul>
              <li>
                <a href="tel:+01217693022">01217693022</a>
              </li>
              <li>
                <a href="tel:+07377640972">07377640972</a>
              </li>
            </ul>
          </li>
          <li className="py-1 flex gap-2 items-center">
            <IoIosMail />
            <a href="mailto:robertrichesproperties@gmail.com">robertrichesproperties@gmail.com</a>
          </li>
          <li className="py-1 flex gap-2 items-center">
          <IoLocationOutline />
            <address>
              <address>11, Brindley Place,</address>
              <address>Brunswick Square,</address>
              <address>Birmingham B12LP</address>
            </address>
          </li>
      </ul>
    </div>
  {footerData.map((section) => (
    <div key={section.name} className='flex flex-col gap-6 w-full md:w-auto'>
      <h2 className='text-xl font-semibold text-left'>{section.title}</h2>
      <ul className='text-left'>
        {section.links.map((link) => (
          <li key={link.name} className='py-1'>
            <Link to={link.url} target={link?.target}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  ))}
</section>

    <div className='flex flex-col gap-3 justify-center items-center'>
     <h2 className='text-xl font-bold text-gray-100'>About HomeLinkers</h2>
     <div className='w-full lg:w-[70%] mx-auto'>
       <p>Homelinkers is a platform uniquely designed by <span className='font-semibold'>ROBERT-RICHES UK LTD</span> to serve the immediate and long term needs of every individual and family in need of  pocket friendly homes. Suitable and affordable corporate accommodations can also be sourced through this platform.</p>
       <p>At HomeLinkers, we think that every amazing property ought to be found. We therefore make it quick and simple for lodging providers all over the world to advertise their facilities, attract new clients, and expand their businesses by using our platform.</p>
     </div>
    </div>
   <section className='mt-5'>
    <p className='text-center'>&copy; {currentYear} HomeLinkers (ROBERT-RICHES UK LTD). All rights reserved.</p>
   </section>
  </footer>
 )
}

export default Footer