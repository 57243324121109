import React from 'react'
import Product from '../assets/apartment.png'
import { formatPrice } from './Reuseables'




const Transaction = ({ img, name, address, buyer, price, status, tag }) => {
 return (
  <tr className='tableRow'>
   <td className="border-b p-2">
    <img src={Product} alt="Product" className="w-[100px] h-[100px] object-contain" />
   </td>
   <td className="border-b p-2">
    <div className='flex flex-col gap-1 w-full md:w-[85%]'>
     <p className="font-semibold w-full md:w-[95%]">Product Name</p>
     <p className="text-gray-500">Location</p>
    </div>
   </td>
   <td className="border-b p-2">Unpaid</td>
   <td className="border-b p-2">${formatPrice(100000)}</td>
   <td className="border-b p-2">Rent</td>
   <td className="border-b p-2">John Doe</td>
  </tr>
 )
}

export default Transaction