import React, { useEffect, useState } from 'react'
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import { MdMyLocation } from "react-icons/md";
import { FaPerson } from "react-icons/fa6";
import Hotel from '../Components/Cards/Hotel';
import api from '../services/api';
import Loading from '../Components/Loading';
import ReactPaginate from 'react-paginate';



const FindHotelPage = () => {
  const navigate = useNavigate()
  const [hotels, setHotels] = useState([])
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true)
    document.title = 'View our finest hotels listed on HomeLinkers'
    api.homeSeeker.hotels.getAllHotels().then((res)=>{;setLoading(false); setHotels(res.data.hotels)}).catch((err)=>console.log(err))
  }, [])


  const [filter, setFilter] = useState({
    room_type:'',
    location: '',
    adults: '',
    children: '',
    room: ''
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => (
      {
        ...prev,
        [name]: value
      }
    ))
  }
  
  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(hotels.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = hotels.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <section>
      <button type='button' onClick={() => navigate(-1)} className='flex gap-2 items-center mb-2'> <IoMdArrowRoundBack /> <p>View other apartment type</p></button>
      <div>
        <h2 className='hotel_page_title'>Find the perfect Hotel on Homelinkers</h2>

        <div className='hotel_filter_container'>
          <article className='hotel_filter_item'>
            <MdMyLocation />
            <select name="location" id="location" value={filter.location} onChange={handleChange}>
              <option value="">Select your preferred location</option>
              {
                ['Nigeria', 'Ghana', 'United Kingdom'].map((country, y) => {
                  return (
                    <option value={country} key={y}>{country}</option>
                  )
                })
              }
            </select>
          </article>
          <article className='hotel_filter_item'>
            <FaPerson />
            <div className='flex gap-2 justify-center'>
              <article className=''>
                <select name="room_type" id="room_type" value={filter.room_type} onChange={handleChange} className='w-[150px] border-2 border-black'>
                  <option value="">room type</option>
                  {
                    ['single', 'double', 'twin', 'twindouble', 'triple', 'quadruple', 'family'].map((x, y) => {
                      return (
                        <option value={x} key={y}>{x}</option>
                      )
                    })
                  }
                </select>
              </article>
              <article className=''>
                <select name="children" id="children" value={filter.children} onChange={handleChange} className='w-[150px] border-2 border-black'>
                  <option value="">children</option>
                  {
                    ['1', '2', '3'].map((x, y) => {
                      return (
                        <option value={x} key={y}>{x} child(ren)</option>
                      )
                    })
                  }
                </select>
              </article>
              <article className=''>
                <select name="room" id="room" value={filter.room} onChange={handleChange} className='w-[150px] border-2 border-black'>
                  <option value="">room</option>
                  {
                    ['1', '2', '3'].map((x, y) => {
                      return (
                        <option value={x} key={y}>{x} room(s)</option>
                      )
                    })
                  }
                </select>
              </article>
            </div>
          </article>
        </div>
      </div>
      {/* all hotels */}
      {loading ? <Loading className={"w-[50px] my-[50px]"}/> :
      (<section>
        <div className="displayData">
          {hotels.length > 1 ?
            currentItems.map((x, y) => {
              return (
                <Hotel id={x.id} key={y} image={`https://homelinker.itl.ng/public/upload/hotels/images/${x.image_1}`} owner={false} seeker={true} type={'Hotel'} price={`${x.price}/hr`} name={x.property_name} location={x.location} chargeRate={'night'} state={x.state}/>
              )
            }) : <div>
            <p className="text-center text-red-500">No matching properties found for the selected filters.</p>
          </div>
          }
        </div>
        {hotels.length > itemsPerPage && <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"flex justify-center gap-2 my-12"}
            previousLinkClassName={
              "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
            }
            nextLinkClassName={
              "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-white"
            }
            breakClassName={"text-blue-500 cursor-pointer"}
            activeClassName={
              "bg-blue-500 text-white border rounded-full p-2"
            }
          />}
      </section>)}
    </section>
  )
}

export default FindHotelPage