import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './Context/UserProvider';
import { WishListProvider } from './Context/WishListProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
      <WishListProvider>
        <App />
      </WishListProvider>
    </UserProvider>
);
