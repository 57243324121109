// import { useLoaderData, useNavigation } from "react-router-dom"
// import Loading from "../Components/Loading"
import HomeBg from '../assets/home.jpg'
import { seek } from "../data"
import TypeCard from "../Components/Cards/TypeCard"
// import Search from "../Components/Search"
import OurValues from "../Components/OurValues"
import Testimonials from "../Components/Testimonials"
// import BestApartments from "../Components/BestApartments"
// import PropertiesByArea from "../Components/PropertiesByArea"
// import ListedHotels from "../Components/ListedHotels"
import { useEffect, useRef } from 'react'
import service from '../services'
// import CountryRep from '../Components/CountryRep'



export const Home = () => {
 const containerRef = useRef(undefined);
 useEffect(() => service.goToTop(containerRef), [])
 document.title = 'Homelinkers | Home'

 // const response = useLoaderData()
 // const navigation = useNavigation()

 // if (navigation.state === "loading") {
 //  return (
 //   <Loading />
 //  )
 // }
 return (
  <section ref={containerRef}>
   <div className='whatyouseekBig' style={{ backgroundImage: `url(${HomeBg})` }}>
    <div className="overlayLight"></div>
    <div className="home">
     <h1 className='home_text'>Find A Home</h1>
     <h1 className='home_text'>That Suites You</h1>
     {/* <div >
      <Search />
     </div> */}
    </div>
   </div>
   <section className='my-8'>
    <h1 className='find'>Featured Properties</h1>
    <div className='displayData'>
     {
      seek.map((x, y) => (
       <TypeCard key={y} name={x.name} to={x.name === 'Hotel' ? '/find-hotel' : `/find-apartment/${x.name}`} image={x.image} />
      ))
     }
    </div>
   </section>
   {/* <BestApartments /> */}
   {/* <PropertiesByArea /> */}
   {/* <ListedHotels /> */}
   <OurValues />
   {/* <CountryRep /> */}
   <Testimonials />
   {/* <img src={response?.url} alt="dog" loading="lazy" /> */}

  </section>
 )
}
