import React from "react";
import ValueCard from './Cards/ValueCard'
import { values } from '../data'

const OurValues = () => {
  return (
    <section className="ourValues">
      <div className="title">
        <p>HOMELINKERS REAL ESTATE AGENCY</p>
        <h1>Our Values</h1>
      </div>
      <div className='displayData'>
      {values.map((x,y)=>{
       return (
        <ValueCard key={y} icon={x.icon} title={x.title} summary={x.summary}/>
       )
      })}
     </div>
    </section>
  );
};

export default OurValues;
