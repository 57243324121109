import Shortlet from './assets/shortlet.jpg'
import Apartment from './assets/room.jpg'
import Hotel from './assets/hotel.jpg'
import Airbnb from './assets/airbnb.jpg'
import Office from './assets/office-complex.jpg'
import Warehouse from './assets/warehouse.jpg'
import Studio from './assets/studio-apartment.jpg'
import { MdOutlineCommute } from "react-icons/md";
import { LuBedDouble, LuBedSingle } from "react-icons/lu";

export const seek = [
 {
  name: 'Hotels',
  to: '/find-hotel',
  image: Hotel,
 },
 {
  name: 'Houses',
  to: '/apartments',
  image: Apartment,
 },
 {
  name: 'Shortlets',
  to: '/apartments',
  image: Shortlet,
 },
 {
  name: 'Serviced Accommodation',
  to: '/apartments',
  image: Airbnb,
 },
 {
  name: 'Flats',
  to: '/apartments',
  image: Studio,
 },
 {
  name: 'Office Complex',
  to: '/offices',
  image: Office,
 },
 {
  name: 'Warehouses',
  to: '/warehouses',
  image: Warehouse,
 }
]

export const type = [
 { name: 'Hotel' },
 { name: 'Shortlet' },
 { name: 'House' },
 { name: 'Serviced Accommodation' },
]

export const propertyTypeList = [
 {
  icon: '',
  name: 'House',
  summary: 'Furnished and self-catering accommodation, where guest rent the entire place',
 },
 {
  icon: '',
  name: 'Hotel and more',
  summary: 'Properties like hotels, B&Bs, guest houses, hostels, condo hotels, etc.',
 },
 {
  icon: '',
  name: 'Shortlet',
  summary: 'Furnished and self-catering accommodation, where guest rent the entire place',
 },
 {
  icon: '',
  name: 'Flat',
  summary: 'Furnished and self-catering accommodation, where guest rent the entire place',
 },
 {
  icon: '',
  name: 'Serviced Accommodation',
  summary: 'Furnished and self-catering accommodation, where guest rent the entire place',
 },
 {
  icon: '',
  name: 'Office Complex',
  summary: 'Furnished and self-catering office complex, where guest rent the entire place',
 },
 {
  icon: '',
  name: 'Warehouse',
  summary: 'Large space where guest can rent or own the entire space',
 },
]

export const values = [
 {
  icon: <MdOutlineCommute size={28} />,
  title: 'Integrity',
  summary: 'Integrity based on our ethical beliefs and standards. We are poised to handle all dealings and deliver all assignment with all sense of professionalism..'
 },
 {
  icon: <MdOutlineCommute size={28} />,
  title: 'Service',
  summary: 'Our service delivery, is geared towards providing a first class customer care service experience to all our clients through our dedicated, friendly and efficient team who deploy cutting edge marketing strategies on the platform of the latest technologies to attain this. We are simply your "GO TO GUYS" for all your property needs.'
 },
]
// export const values = [
//  {
//   icon: <MdOutlineCommute size={28} />,
//   title: 'Ensuring clear communication',
//   summary: 'We keep you in the loop every step of the way. You can rely on a transparent, comprehensible flow of information.'
//  },
//  {
//   icon: <MdOutlineCommute size={28} />,
//   title: 'Making the most of technology',
//   summary: 'Time is precious. We guarantee a high degree of efficiency and a premium level of service by using the right tools.'
//  },
//  {
//   icon: <MdOutlineCommute size={28} />,
//   title: 'Providing excellent customer service',
//   summary: 'We are passionate about what we do and we take pride in achieving the personalised results that you have in mind.'
//  },
// ]

export const ratings = [1, 2, 3, 4, 5]
export const categoryOptions = {
 general: ['air_conditioner', 'heating', 'free_wifi', 'electric_vehicle_charging_station'],
 cookingcleaning: ['kitchen', 'kitchenette', 'washing_machine', 'oven', 'fridge'],
 entertainment: ['flat_screen_tv', 'swimming_pool', 'hot_tub', 'mini_bar'],
 outsideview: ['balcony', 'garden_view', 'terrace', 'ocean_view'],
};

export const hotelService = ['Restaurant', 'Room service', 'Bar', 'Terrace', 'Sauna', 'Air conditioning', 'Free Wifi', 'Spa', 'Non-smoking rooms']

export const mockData = [
 { id: 1, tag: 'rent', location: 'Los angeles', price: 1200 },
 { id: 2, tag: 'sale', location: 'Lagos', price: 250000 },
 { id: 3, tag: 'lease', location: 'London', price: 800 },
 { id: 4, tag: 'rent', location: 'Tanzania', price: 1500 },
 { id: 5, tag: 'sale', location: 'Mexico', price: 180000 },
 { id: 6, tag: 'lease', location: 'Rwanda', price: 1000 },
 // Add more items as needed
];

export const bedSizes = [
 { id: 1, icon: <LuBedSingle size={28} />, name: 'Single', wideness: '90-130' },
 { id: 2, icon: <LuBedDouble size={28} />, name: 'Double', wideness: '131-150' },
 { id: 3, icon: <LuBedDouble size={28} />, name: 'Twin', wideness: '131-150' },
 { id: 4, icon: <LuBedDouble size={28} />, name: 'TwinDouble', wideness: '131-150' },
 { id: 5, icon: <LuBedDouble size={28} />, name: 'Triple', wideness: '131-150' },
 { id: 6, icon: <LuBedDouble size={28} />, name: 'Quadruple', wideness: '131-150' },
 { id: 7, icon: <LuBedDouble size={28} />, name: 'Family', wideness: '131-150' },
]

export const toiletItems = [
 { id: "1", name: "Toilet paper" },
 { id: "2", name: "Shower" },
 { id: "3", name: "Water closet" },
 { id: "4", name: "Hair dryer" },
 { id: "5", name: "Bath Tub" },
 { id: "6", name: "Slippers" },
 { id: "7", name: "Spa bath" },
]

export const houseTypes = [
  { name: 'Mansion', value: 'mansion' },
  { name: 'Detached', value: 'detached' },
  { name: 'Semi-Detached', value: 'semi-detached' },
  { name: 'Bungalow', value: 'bungalow' },
  { name: 'Terraces', value: 'terraces' },
  { name: 'Flats', value: 'flats' },
  { name: 'Studio Apartments', value: 'studio-apartments' }
];

export const currencies = [
  {
    code: "NGN",
    symbol: "₦",
    name: "Nigerian Naira"
  },
  {
    code: "USD",
    symbol: "$",
    name: "US Dollar"
  },
  {
    code: "GBP",
    symbol: "£",
    name: "British Pound Sterling"
  }
];


