import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../assets/home linker.png"
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { useUser } from '../Context/UserProvider';
import Pic from '../assets/profile_pic.jpg'

const DashboardNavbar = ({ onClick }) => {
 const [scrolled, setScrolled] = useState(false);
 const { user } = useUser();


 useEffect(() => {
  const handleScroll = () => {
    const isScrolled = window.scrollY > 40;
    setScrolled(isScrolled);
  };
  handleScroll();
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
 return (
  <nav className={`bg-white dark:bg-gray-900 shadow-lg relative ${scrolled && 'sticky top-0 z-[1300] shadow-2xl'}`}>
   <div className={`flex flex-wrap items-center justify-between mx-auto p-4`}>
    <Link to='/' className="flex items-center space-x-3 rtl:space-x-reverse">
     <img src={Logo} className="h-12 w-16 lg:w-20" alt="Homelinker Logo" />
     <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white hidden md:block">HomeLinkers</span>
    </Link>
    <div className='flex gap-4 items-center'>
     <img src={`https://homelinker.itl.ng/public/upload/profile_pics/${user?.photo}` || Pic} alt={user?.name} className="h-10 w-10 object-contain rounded-full border-double border-2 border-minsk-800 cursor-pointer" />
     <div className="flex justify-end py-3 cursor-pointer ml-auto lg:hidden">
      <HiOutlineMenuAlt3 size={26} onClick={onClick} />
     </div>
    </div>
   </div>
  </nav>
 )
}

export default DashboardNavbar