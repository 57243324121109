import React, { useEffect, useRef } from 'react'
import Image from '../assets/home.jpg'
import service from '../services';


const AboutUs = () => {
  const containerRef = useRef(undefined);
  useEffect(() => service.goToTop(containerRef), [])
  useEffect(() => {
    document.title = 'About Homelinkers'
  }, [])

  return (
    <section ref={containerRef}>
      <div className='whatyouseekBig' style={{ backgroundImage: `url(${Image})` }}>
        <div className="home">
          <h1 className='home_text !text-white'>About us</h1>
        </div>
      </div>
      <div>
        <h1 className='font-bold text-center underline underline-offset-[4px] my-3 text-xl md:text-3xl lg:text-6xl'><span className="text-asparagus-600">What we do at</span> HomeLinkers</h1>
        <section>
          <div className='aboutUs'>
            <h2>Bringing value to our accommodation partners</h2>
            <article className='article'>
              <p>Our goal is to bring closer to each vendor or buyer, lease or leasor, or just the ordinary or random tourist or guest the process of securing, acquiring, or booking their desired property vis-a-vis accommodation, tailored to each client's taste, desire, and affordability while maintaining the minimum acceptable standard of quality and professionalism in our delivery.</p>
            </article>
          </div>
          <div className='aboutUs'>
            <h2>What HomeLinkers offers</h2>
            <div>
              <article className='article'>
                <h3>Incredible choice</h3>
                <p>Whether you want to stay in a chic city apartment, a luxury beach resort or a cosy B&B in the countryside, HomeLinkers provides you with amazing diversity and breadth of choice- all in one place.</p>
              </article>
              <article className='article'>
                <h3>Low rates</h3>
                <p>Homelinkers guarantees to offer you the best available rates with our promise to price match, you can rest assured that you're always getting a great deal.</p>
              </article>
              <article className='article'>
                <h3>Instant confirmation</h3>
                <p>At Homelinkers, every reservation is instantly confirmed. Once you've found your perfect stay, a few clicks are all it takes.</p>
              </article>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

export default AboutUs