import React from 'react'

const AnyModal = ({ content, handleClosePopup }) => {
 return (
  <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80">
   <div>{content}</div>
   <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 absolute top-0 right-4" onClick={handleClosePopup}>
    Close
   </button>
  </div>
 )
}

export default AnyModal