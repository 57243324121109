import React, { useEffect } from 'react'
import Subscriber from '../../../Components/Subscriber'

const ViewSubscribers = () => {
  useEffect(() => {
    document.title = `Viewing Listed Subscribers`
  }, [])
 const subs = [1,2,3,4,5,6,7,8,9,0,9,8,7,6,5,4,3,2,2,1,2,3,4,5,6,7,8,8,9,0]
  return (
    <section>
     <div className='flex flex-col gap-3 items-center my-8 text-center font-semibold'>
      <h1 className=' text-2xl lg:text-5xl'>All Subscribers</h1>
      <p className='text-xl'>We have {subs.length} subscribers</p>
     </div>
     <div className='displayData'>
      {
       subs.map((x,y)=>{
        return(
         <Subscriber key={y} email={'officiallyomoniyi@gmail.com'}/>
        )
       })
      }
     </div>
    </section>
  )
}

export default ViewSubscribers