import axios from "axios";
import service from "../index";
// import routes from "../routes";
// import { auth } from "./auth";
import { toast } from "react-toastify";
import routes from "../routes";


// Request Interceptor
axios.interceptors.request.use(
  async (config) => {
    const token = service.getTokensLocalStorage();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;

    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // first error
    if (error.response.status === 401) {
      console.error(error.response.data?.message)

      // //  window.location.replace("/register");
      // const token = service.getTokensLocalStorage('tokens');


      // auth.refreshToken(token).then((res) => { service.setTokensL{ocalStorage({ access: res.data.access_token }) }).catch((res) => console.log("refreshToken", res))

    } else {
      console.log("error", error);
    }
    // second error, error 422, email already used
    if (error.response.status === 422) {
      toast.error(error.response.data?.error)
    } else {
      toast.error(error.response.data?.message)
    }
    return error;
  }
);

const api = {
  contactUs : async (postBody) =>{
    return await axios.post(routes["contact-us"](), postBody)
  },
  admin: {
    getAllHomeSeekers: async () => {
      return await axios.get(routes.admin.get["all-seekers"]())
    },
    getAllHomeOwners: async () => {
      return await axios.get(routes.admin.get["all-homeowners"]())
    }
  },
  homeOwner: {
    profile: {
      updateProfile: async (postBody) => {
        return await axios.post(routes.homeowner["update-profile"](), postBody)
      }
    },
    apartments: {
      createApartment: async (postBody) => {
        return await axios.post(routes.homeowner.apartments["create-apartment"](), postBody)
      },
      updateApartment: async (id, postBody) => {
        return await axios.post(routes.homeowner.apartments["update-apartment"](id), postBody)
      },
      getAllApprovedApartments: async () => {
        return await axios.get(routes.homeowner.apartments["get-approvedApartments"]())
      },
      getAllUnApprovedApartments: async () => {
        return await axios.get(routes.homeowner.apartments["get-unapprovedApartments"]())
      },
      getAllApartments: async () => {
        return await axios.get(routes.homeowner.apartments["get-allApartments"]())
      },
      deleteApartment: async (id) => {
        return await axios.delete(routes.homeowner.apartments["delete-Apartment"](id))
      },
      getById: async (id) => {
        return await axios.get(routes.homeowner.apartments["get-byId"](id))
      }
    },
    shortlets: {
      createShortlet: async (postBody) => {
        return await axios.post(routes.homeowner.shortlets["create-shortlet"](), postBody)
      },
      updateShortlet: async (id, postBody) => {
        return await axios.post(routes.homeowner.shortlets["update-shortlet"](id), postBody)
      },
      getAllApprovedShortlets: async () => {
        return await axios.get(routes.homeowner.shortlets["get-approvedshortlets"]())
      },
      getAllUnApprovedShortlets: async () => {
        return await axios.get(routes.homeowner.shortlets["get-unapprovedshortlets"]())
      },
      getAllShortlets: async () => {
        return await axios.get(routes.homeowner.shortlets["get-allshortlets"]())
      },
      deleteShortlet: async (id) => {
        return await axios.delete(routes.homeowner.shortlets["delete-shortlet"](id))
      },
      getById: async (id) =>{
        return await axios.get(routes.homeowner.shortlets["get-byId"](id))
      }
    },
    hotels: {
      createHotel: async (postBody) => {
        return await axios.post(routes.homeowner.hotels["create-hotel"](), postBody)
      },
      updateHotel: async (id, postBody) => {
        return await axios.post(routes.homeowner.hotels["update-hotel"](id), postBody)
      },
      getAllApprovedhotels: async () => {
        return await axios.get(routes.homeowner.hotels["get-approvedhotels"]())
      },
      getAllUnApprovedhotels: async () => {
        return await axios.get(routes.homeowner.hotels["get-unapprovedhotels"]())
      },
      getAllhotels: async () => {
        return await axios.get(routes.homeowner.hotels["get-allhotel"]())
      },
      deleteHotel: async (id) => {
        return await axios.delete(routes.homeowner.hotels["delete-hotel"](id))
      },
    },
    airbnb: {
      createAirbnb: async (postBody) => {
        return await axios.post(routes.homeowner.airbnb["create-airbnb"](), postBody)
      },
      updateAirbnb: async (id, postBody) => {
        return await axios.post(routes.homeowner.airbnb["update-airbnb"](id), postBody)
      },
      getAllApprovedAirbnb: async () => {
        return await axios.get(routes.homeowner.airbnb["get-approvedairbnbs"]())
      },
      getAllUnApprovedAirbnb: async () => {
        return await axios.get(routes.homeowner.airbnb["get-unapprovedairbnbs"]())
      },
      getAllAirbnb: async () => {
        return await axios.get(routes.homeowner.airbnb["get-allairbnb"]())
      },
      deleteAirbnb: async (id) => {
        return await axios.delete(routes.homeowner.airbnb["delete-airbnb"](id))
      },
      getById: async (id) =>{
        return await axios.get(routes.homeowner.airbnb["get-byId"](id))
      }
    },
    studioApartment: {
      createStudioApartment: async (postBody) => {
        return await axios.post(routes.homeowner["studio-apartment"]["create-studioApartment"](), postBody)
      },
      updateStudioApartment: async (id, postBody) => {
        return await axios.post(routes.homeowner["studio-apartment"]["update-studioApartment"](id), postBody)
      },
      getAllApprovedStudioApartment: async () => {
        return await axios.get(routes.homeowner["studio-apartment"]["get-approvedstudioApartments"]())
      },
      getAllUnApprovedStudioApartment: async () => {
        return await axios.get(routes.homeowner["studio-apartment"]["get-unapprovedstudioApartments"]())
      },
      getAllStudioApartment: async () => {
        return await axios.get(routes.homeowner["studio-apartment"]["get-allstudioApartment"]())
      },
      deleteStudioApartment: async (id) => {
        return await axios.delete(routes.homeowner["studio-apartment"]["delete-studioApartment"](id))
      },
      getById: async (id) =>{
        return await axios.get(routes.homeowner["studio-apartment"]["get-byId"](id))
      }
    },
    warehouse: {
      createWarehouse: async (postBody) => {
        return await axios.post(routes.homeowner.warehouse["create-warehouse"](), postBody)
      },
      updateWarehouse: async (id, postBody) => {
        return await axios.post(routes.homeowner.warehouse["update-warehouse"](id), postBody)
      },
      getAllApprovedWarehouse: async () => {
        return await axios.get(routes.homeowner.warehouse["get-approvedwarehouse"]())
      },
      getAllUnApprovedWarehouse: async () => {
        return await axios.get(routes.homeowner.warehouse["get-unapprovedwarehouse"]())
      },
      getAllWarehouse: async () => {
        return await axios.get(routes.homeowner.warehouse["get-warehouse"]())
      },
      deleteWarehouse: async (id) => {
        return await axios.delete(routes.homeowner.warehouse["delete-warehouse"](id))
      },
    },
    officeComplex: {
      createOfficeComplex: async (postBody) => {
        return await axios.post(routes.homeowner.officeComplex["create-office-complex"](), postBody)
      },
      updateOfficeComplex: async (id, postBody) => {
        return await axios.post(routes.homeowner.officeComplex["update-office-complex"](id), postBody)
      },
      getAllApprovedOfficeComplex: async () => {
        return await axios.get(routes.homeowner.officeComplex["get-approvedoffice-complex"]())
      },
      getAllUnApprovedOfficeComplex: async () => {
        return await axios.get(routes.homeowner.officeComplex["get-unapprovedoffice-complex"]())
      },
      getAllOfficeComplex: async () => {
        return await axios.get(routes.homeowner.officeComplex["get-office-complex"]())
      },
      deleteOfficeComplex: async (id) => {
        return await axios.delete(routes.homeowner.officeComplex["delete-office-complex"](id))
      },
    },
  },
  rep: {
    profile: {
      updateProfile: async (postBody) => {
        return await axios.post(routes.rep["update-profile"](), postBody)
      }
    },
    apartments: {
      getAllApartments: async () => {
        return await axios.get(routes.homeowner.apartments["get-allApartments"]())
      },
      getById: async (id) => {
        return await axios.get(routes.homeowner.apartments["get-byId"](id))
      }
    },
    shortlets: {
      getAllShortlets: async () => {
        return await axios.get(routes.homeowner.shortlets["get-allshortlets"]())
      },
    },
    hotels: {
      getAllhotels: async () => {
        return await axios.get(routes.homeowner.hotels["get-allhotel"]())
      },
      deleteHotel: async (id) => {
        return await axios.delete(routes.homeowner.hotels["delete-hotel"](id))
      },
    },
    airbnb: {
      getAllAirbnb: async () => {
        return await axios.get(routes.homeowner.airbnb["get-allairbnb"]())
      },
    },
    studioApartment: {
      getAllStudioApartment: async () => {
        return await axios.get(routes.homeowner["studio-apartment"]["get-allstudioApartment"]())
      },
    },
  },

  homeSeeker: {
    apartments: {
      getAllApartments: async () => {
        return await axios.get(routes.homeseeker.get.apartments(),)
      },
      getSingleApartment: async (id) => {
        return await axios.get(routes.homeseeker.get.single.apartment(id))
      }
    },
    hotels: {
      getAllHotels: async () => {
        return await axios.get(routes.homeseeker.get.hotels(),)
      },
      getSingleHotel: async (id) => {
        return await axios.get(routes.homeseeker.get.single.hotel(id))
      }
    },
    shortlets: {
      getAllShortlets: async () => {
        return await axios.get(routes.homeseeker.get.shortlets())
      },
      getSingleShortlet: async (id) => {
        return await axios.get(routes.homeseeker.get.single.shortlet(id))
      }
    },
    airbnb: {
      getAllAirbnb: async () => {
        return await axios.get(routes.homeseeker.get.airbnb())
      },
      getSingleAirbnb: async (id) => {
        return await axios.get(routes.homeseeker.get.single.airbnb(id))
      }
    },
    studioApartment: {
      getAllStudioApartment: async () => {
        return await axios.get(routes.homeseeker.get.studioApartment())
      },
      getSingleStudioApartment: async (id) => {
        return await axios.get(routes.homeseeker.get.single["studio-apartment"](id))
      }
    },
    warehouse: {
      getAllWarehouse: async () => {
        return await axios.get(routes.homeseeker.get.warehouse())
      },
      getSingleWarehouse: async (id) => {
        return await axios.get(routes.homeseeker.get.single.warehouse(id))
      }
    },
    officeComplex: {
      getAllOfficeComplex: async () => {
        return await axios.get(routes.homeseeker.get["office-complex"]())
      },
      getSingleOfficeComplex: async (id) => {
        return await axios.get(routes.homeseeker.get.single["office-complex"](id))
      }
    },
    wish: {
      addToWishlist: async (postBody) => {
        return await axios.post(routes.homeseeker.wish["add-to-wishlist"](), postBody)
      },
      deleteWish: async (id) => {
        return await axios.delete(routes.homeseeker.wish["delete-wishlist"](id))
      },
      getWishlist: async () => {
        return await axios.get(routes.homeseeker.wish["get-wishes"]())
      }
    },
    profile: {
      updateProfile: async (postBody) => {
        return await axios.post(routes.homeseeker["update-profile"](), postBody)
      }
    }
  }
};

export default api;
