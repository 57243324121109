import React from 'react'

const ValueCard = ({title,icon,summary}) => {
  return (
    <article className='valueCard'>
      <div className='icon'>{icon}</div>
      <div className='flex flex-col gap-4'>
        <h2>{title}</h2>
        <p>{summary}</p>
      </div>
    </article>
  )
}

export default ValueCard