import React, { useState, useEffect } from 'react'
import PropertyCard from '../../../Components/Cards/PropertyCard'
import api from '../../../services/api'
import Loading from '../../../Components/Loading'
import Modal from '../../../Components/Cards/Modal'
import Danger from '../../../assets/danger-sign.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate'


const AllWarehouse = () => {
  document.title = 'View all listed Warehouse'

  const [selectedTag, setSelectedTag] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const [filteredProperties, setFilteredProperties] = useState([]);
  const [activeButton, setActiveButton] = useState('All');
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate()
  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const pageCount = Math.ceil(filteredProperties.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProperties.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const applyFilters = () => {
    const newFilteredProperties = filteredProperties.filter((property) => {
      const isTagMatch = !selectedTag || property.tag === selectedTag;
      const isLocationMatch = !selectedLocation || property.location.includes(selectedLocation);
      const isPriceRangeMatch = (!minPrice || property.price >= minPrice) && (!maxPrice || property.price <= maxPrice);

      return isTagMatch && isLocationMatch && isPriceRangeMatch;
    });

    setFilteredProperties(newFilteredProperties);
  };
  useEffect(() => {
    setLoading(true)
    if (activeButton === 'All') {
      api.homeOwner.warehouse.getAllWarehouse().then((res) => {
        setTimeout(() => {
          setLoading(false)
          setFilteredProperties(res.data['All warehouses'])
        }, 2000)
      }).catch((err) => console.error(err))
    } else if (activeButton === 'Approved') {
      api.homeOwner.warehouse.getAllApprovedWarehouse().then((res) => {
        setLoading(false)
        setFilteredProperties(res.data.data)
      }).catch((err) => console.error(err))
    } else {
      api.homeOwner.warehouse.getAllUnApprovedWarehouse().then((res) => {
        setLoading(false)
        setFilteredProperties(res.data.data)
      }).catch((err) => console.error(err))
    }
  }, [activeButton]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  if (loading) {
    return <Loading className={'w-[50px]'}/>
  }

  const openModal = (id) => {
    setDeleteId(id)
    setIsModalOpen(true);
  };

  const deleteItem = () => {
    api.homeOwner.warehouse.deleteWarehouse(deleteId).then((res) => {
      console.log(res)
      if (res.status === 200) {
        setLoading(true)
        setIsModalOpen(false);
        const updatedProperties = filteredProperties.filter(item => item.id !== deleteId);
        setFilteredProperties(updatedProperties);
        setTimeout(() => {
          setLoading(false)
          toast.success(res.message)
        }, 500)
      }
    }).catch((err) => console.log(err))
  }

  const edit = (id) => {
    navigate(`/dashboard/homeowner/add-property?id=${id}&type=Warehouse`)
  }

  return (
    <section className='flex flex-col gap-4 my-3'>
      {/* filter section */}
      <div>
        <h2 className="text-center font-bold text-3xl text-asparagus-500">All listed Warehouse</h2>
      </div>
      <div className='flex gap-4 justify-start md:justify-center items-end overflow-x-scroll myoverflow'>
        {/* <h1 className="text-4xl font-bold">FILTER HERE</h1> */}
        <div className="flex flex-col gap-1">
          <label className="mr-2">Tag:</label>
          <select
            onChange={(e) => setSelectedTag(e.target.value)}
            value={selectedTag}
            className="p-2 border rounded"
          >
            <option value="">All</option>
            <option value="rent">Rent</option>
            <option value="sale">Sale</option>
            <option value="lease">Lease</option>
          </select>
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-2">Location:</label>
          <input
            type="text"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            className="p-2 border rounded"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="mr-2">Price:</label>
          <div className="flex">
            <input
              type="number"
              placeholder="Min"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              className="p-2 border rounded mr-2"
            />
            <input
              type="number"
              placeholder="Max"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
        </div>
        <button
          onClick={applyFilters}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Apply Filters
        </button>
      </div>
      <div className='flex gap-4 justify-center items-center my-4'>
        <button type="button" className={`rounded-md px-4 py-2 text-white ${activeButton === 'All' ? 'bg-asparagus-500' : 'bg-slate-400'}`} onClick={() => handleButtonClick('All')}>All</button>
        <button type="button" className={`rounded-md px-4 py-2 text-white ${activeButton === 'Approved' ? 'bg-asparagus-500' : 'bg-slate-400'}`} onClick={() => handleButtonClick('Approved')}>Approved</button>
        <button type="button" className={`rounded-md px-4 py-2 text-white ${activeButton === 'Unapproved' ? 'bg-asparagus-500' : 'bg-slate-400'}`} onClick={() => handleButtonClick('Unapproved')}>Unapproved</button>
      </div>
      <div className='displayData'>
        {currentItems.length > 0 &&
          currentItems.map((x) => {
            return (
              <PropertyCard id={x.id} tag={x.tag} currency={x.currency} handleEditByOwner={() => edit(x.id)} sqrFt={x.warehouse_size} handleDeleteByOwner={() => openModal(x.id)} type={x.property_type} owner={true} seeker={false} state={x.state} key={x.id} location={x.location} price={x.price} bed={x.no_of_bedroom} bath={x.no_of_bathroom} garage={x.garage} agentName={x.created_by} date={x.created_at} propertyName={x.property_name} address={x.street_name} image={`https://homelinker.itl.ng/public/upload/Warehouses/images/${x.image_1}`} />
            )
          })
        }
        {
          filteredProperties.length < 1 && (
            <div>
              <p>Nothing to show here</p>
            </div>
          )
        }
      </div>
        {
        filteredProperties.length > 0 && 
        <div className="w-[100px]">
          <select className='form_input_container border-2' name="itemsperpage" id="" value={itemsPerPage} onChange={(e)=>setItemsPerPage(e.target.value)}>
            <option value={6}>6 items</option>
            <option value={12}>12 items</option>
            <option value={18}>18 items</option>
          </select>
        </div>
      }
      {filteredProperties.length > itemsPerPage && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"flex justify-center gap-2 my-12"}
            previousLinkClassName={
              "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
            }
            nextLinkClassName={
              "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-white"
            }
            breakClassName={"text-blue-500 cursor-pointer"}
            activeClassName={"bg-blue-500 text-white border rounded-full p-2"}
          />
        )}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-4'>
            <img src={Danger} alt="" className='w-[100px]' />
            <h2 className='text-2xl'>Are you sure you want to delete?</h2>
            <div className='flex  gap-4 items-center'>
              <button type="button" className='bg-asparagus-600 px-4 rounded-md py-1 text-white font-medium' onClick={deleteItem}>Yes</button>
              <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
            </div>
          </div>
        </Modal>
    </section>
  )
}

export default AllWarehouse