import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css';
import NoImage from  '../assets/noImage.jpg'

const ViewPicVid = ({ images, videos }) => {
 return (
  <div className="max-w-3xl p-4 bg-white rounded-lg overflow-hidden">
   <Splide>
    {/* Display Images */}
    {images.map((image, index) => (
     <SplideSlide>
      <img key={index} src={image} alt={`product ${index + 1}`} className='w-full' onError={(e) => {
        e.target.onerror = null;
        e.target.src = NoImage;
      }}/>
     </SplideSlide>
    ))}

    {/* Display Videos */}
    {videos.map((video, index) => (
     <SplideSlide >
      <video key={index} controls className="w-full" src={video} type="video/mp4" onError={(e) => {
        e.target.onerror = null;
        e.target.src = NoImage;
      }}/>
     </SplideSlide>
    ))}

   </Splide>
  </div>
 )
}

export default ViewPicVid