import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useEffect, useRef } from "react";
import service from "../../services";

const RootLayout = () => {
 const containerRef = useRef(undefined);
 useEffect(() => service.goToTop(containerRef), [])
 return (
  <div ref={containerRef} className='max-w-[1728px] border-2 border-gray-50 mx-auto flex flex-col min-h-screen'>
   <Navbar />
   <main className="my-[20px] px-[10px]">
    <Outlet />
   </main>
   <Footer />
  </div>
 )
}

export default RootLayout
