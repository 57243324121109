import React, { useState, useRef, } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb';
import CustomInput from '../../../../Components/CustomInput';
import GoogleMapComponent from '../../../../Components/GoogleMap';
import { categoryOptions, bedSizes, toiletItems, currencies } from '../../../../data';
import { RxSwitch } from "react-icons/rx";
import Upload from '../../../../assets/upload.svg'
import { formatPropertyName } from '../../../../Components/Reuseables';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import { useUser } from '../../../../Context/UserProvider';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../Components/Loading';


const Hotels = () => {
  const imageInputRefs = {
    image_1: useRef(null),
    image_2: useRef(null),
    image_3: useRef(null),
    image_4: useRef(null),
  };
  const videoInputRef = {
    video_1: useRef(null),
    video_2: useRef(null),
  };
  const [step, setStep] = useState(1);
  const [creating, setCreating] = useState(false)

  const { user } = useUser();

  const navigate = useNavigate()

  const [apartment, setApartment] = useState({
    propertyName: '',
    country: '',
    address: '',
    state: '',
    map: '',
    bedSize: {
      single: 0, double: 0, twin: 0, twindouble: 0, triple: 0, quadruple: 0, family: 0
    },
    roomType: '',
    parking: '',
    roomSize: '',
    guest: '',
    bathroomItems: [],
    houseRules: {
      smoking: 0,
      pets: 0,
      parties: 0,
    },
    image_1: '',
    image_2: '',
    image_3: '',
    image_4: '',
    video_1: '',
    video_2: '',
    air_conditioner: 0,
    heating: 0,
    free_wifi: 0,
    electric_vehicle_charging_station: 0,
    kitchen: 0,
    kitchenette: 0,
    washing_machine: 0,
    oven: 0,
    fridge: 0,
    flat_screen_tv: 0,
    swimming_pool: 0,
    hot_tub: 0,
    mini_bar: 0,
    balcony: 0,
    garden_view: 0,
    terrace: 0,
    ocean_view: 0,
    price: '',
    tag: 'Hotel',
    overview:'',
    currency:''
  })
  const updateBedSize = (id, operation, event) => {
    if (event) {
      event.preventDefault(); // Prevent form submission
    }

    setApartment((prevApartment) => {
      const updatedBedSize = { ...prevApartment.bedSize };
      // Find the bed size by id
      const bedToUpdate = bedSizes.find((bed) => bed.id === id);
      if (bedToUpdate) {
        // Update the count of the specific bed size category based on the operation
        updatedBedSize[bedToUpdate.name.toLowerCase()] += operation === 'increase' ? 1 : -1;
        // Ensure the count does not go below 0
        if (updatedBedSize[bedToUpdate.name.toLowerCase()] < 0) {
          updatedBedSize[bedToUpdate.name.toLowerCase()] = 0;
        }
      }
      return {
        ...prevApartment,
        bedSize: updatedBedSize,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApartment((prevApartment) => ({
      ...prevApartment,
      [name]: value,
    }));
  };
  // map click
  const handlePinDrop = (coordinates) => {
    setApartment((prev) => ({
      ...prev,
      map: coordinates
    }))
  }
  // houserules
  const handleHouseRuleChange = (rule) => {
    setApartment((prevApartment) => ({
      ...prevApartment,
      houseRules: {
        ...prevApartment.houseRules,
        [rule]: prevApartment.houseRules[rule] === 1 ? 0 : 1,
      },
    }));
  };
  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    setStep(step - 1);
  };
  const handleCheckboxChange = (option) => {
    setApartment((prevApartment) => ({
      ...prevApartment,
      [option]: prevApartment[option] === 1 ? 0 : 1, // Toggle the checkbox value
    }));
  };
  const handleBathroomItemChange = (item) => {
    setApartment((prevApartment) => {
      const updatedBathroomItems = prevApartment.bathroomItems.includes(item)
        ? prevApartment.bathroomItems.filter((existingItem) => existingItem !== item)
        : [...prevApartment.bathroomItems, item];

      return {
        ...prevApartment,
        bathroomItems: updatedBathroomItems,
      };
    });
  };

  // handle upload 
  const handleFileChange = (event, fileType, fileNumber) => {
    const selectedFile = event.target.files[0];
    // Check file size based on fileType
    const maxSize = fileType === 'photo' ? 12 * 1024 * 1024 : 40 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      alert(`File size exceeds the allowed limit (${maxSize / (1024 * 1024)} MB). Please choose a smaller file.`);
      return;
    }
    setApartment((prevDetails) => ({
      ...prevDetails,
      [`${fileType}_${fileNumber}`]: selectedFile,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    setCreating(true)


    formData.append('property_name', apartment.propertyName)
    formData.append('street_name', apartment.address)
    formData.append('location', apartment.country)
    formData.append('state', apartment.state)
    formData.append('no_of_bedroom', apartment.bedroom)
    formData.append('garage', apartment.garage)
    formData.append('air_conditioner', apartment.air_conditioner)
    formData.append('heating', apartment.heating)
    formData.append('free_wifi', apartment.free_wifi)
    formData.append('electric_vehicle_charging_station', apartment.electric_vehicle_charging_station)
    formData.append('kitchen', apartment.kitchen)
    formData.append('kitchenette', apartment.kitchenette)
    formData.append('washing_machine', apartment.washing_machine)
    formData.append('oven', apartment.oven)
    formData.append('fridge', apartment.fridge)
    formData.append('flat_screen_tv', apartment.flat_screen_tv)
    formData.append('swimming_pool', apartment.swimming_pool)
    formData.append('hot_tub', apartment.hot_tub)
    formData.append('mini_bar', apartment.mini_bar)
    formData.append('balcony', apartment.balcony)
    formData.append('garden_view', apartment.garden_view)
    formData.append('terrace', apartment.terrace)
    formData.append('ocean_view', apartment.ocean_view)
    formData.append('smoking', apartment.houseRules.smoking)
    formData.append('parties', apartment.houseRules.parties)
    formData.append('pets', apartment.houseRules.pets)
    formData.append('tag', apartment.tag)
    formData.append('price', apartment.price)
     // Check if the files are already URLs or need to be appended as files
  if (apartment.image_1 instanceof File) {
    formData.append('image_1', apartment.image_1);
  }
  if (apartment.image_2 instanceof File) {
    formData.append('image_2', apartment.image_2);
  }
  if (apartment.image_3 instanceof File) {
    formData.append('image_3', apartment.image_3);
  }
  if (apartment.image_4 instanceof File) {
    formData.append('image_4', apartment.image_4);
  }
  if (apartment.video_1 instanceof File) {
    formData.append('video_1', apartment.video_1);
  }
  if (apartment.video_2 instanceof File) {
    formData.append('video_2', apartment.video_2);
  }
    formData.append('overview', apartment.overview)
    formData.append('currency', apartment.currency)

    api.homeOwner.hotels.createHotel(formData).then((res) => {
      if(res.status === 200){
        toast.success(res.data.message)
        navigate(`/dashboard/${user.role}/all-hotels`)
        setApartment(apartment)
      }
    })
    .catch((res) => {
      setCreating(false)
      console.log(res)
    });
  };
  // const handleDeleteMedia = (mediaType, index) => {
  //   setApartment((prevApartment) => {
  //     const updatedMedia = [...prevApartment[mediaType]];
  //     updatedMedia.splice(index, 1);

  //     return {
  //       ...prevApartment,
  //       [mediaType]: updatedMedia,
  //     };
  //   });
  // };



  // Handle stages rendering
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <section>
            <h2>Step 2: Basic Details</h2>
            <div>
              <form>
                <CustomInput value={apartment.propertyName} placeholder={"Room name"} name={"propertyName"} type={'text'} label={"Room Name"} handleChange={handleChange} />
                <article className='form_input_container'>
                  <label htmlFor="location">Location <span className='important'>*</span></label>
                  <select name="country" id="country" value={apartment.country} onChange={handleChange} className='formInput'>
                    <option value="">Select your location</option>
                    {
                      ['Nigeria', 'United Kingdom', 'United States'].map((country) => {
                        return (
                          <option key={country}>{country}</option>
                        )
                      })
                    }
                  </select>
                </article>
                <CustomInput value={apartment.address} placeholder={""} name={"address"} type={'text'} label={"Street name and house number"} handleChange={handleChange} />
                <CustomInput value={apartment.state} placeholder={""} name={"state"} type={'text'} label={"State"} handleChange={handleChange} />
                <div className='form_input_container'>
                  <label htmlFor="overview">Overview</label>
                  <textarea name="overview" className='formInput' id="overview" cols="30" rows="10" value={apartment.overview} onChange={handleChange}/>
                </div>
                <GoogleMapComponent onPinDrop={handlePinDrop} />
              </form>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </section>
        );
      case 2:
        return (
          <section>
            <h2>Step 2: Main Details</h2>
            <div>
              <form>
                {/* type of room */}
                <article className='form_input_container'>
                  <label htmlFor="roomType">What type of room is this? <span className='important'>*</span></label>
                  <select name="roomType" id="" value={apartment.roomType} onChange={handleChange} className='formInput'>
                    <option value="">Select room type</option>
                    {
                      ['single', 'double', 'twin', 'twindouble', 'triple', 'quadruple', 'family'].map((room) => {
                        return (
                          <option key={room} value={room}>{room}</option>
                        )
                      })
                    }
                  </select>
                </article>
                {/* size of bed */}
                <article className='form_input_container'>
                  <label htmlFor="location">What type of bed is present? <span className='important'>*</span></label>
                  <div className='displayData'>
                    {
                      bedSizes.map((bed) => {
                        return (
                          <article key={bed.id} className='flex gap-4 justify-between border-2 border-gray-400 p-3 mb-3 text-xl'>
                            <div className='flex gap-2'>
                              {bed.icon}
                              <div>
                                <p>{bed.name} bed</p>
                                <small>{bed.wideness} cm wide</small>
                              </div>

                            </div>
                            <div className='flex gap-2 items-center'>
                              <button type='button' onClick={() => updateBedSize(bed.id, 'decrease')}>-</button>
                              <p>{apartment.bedSize && apartment.bedSize[bed.name.toLowerCase()]}</p>
                              <button type='button' onClick={() => updateBedSize(bed.id, 'increase')}>+</button>
                            </div>
                          </article>
                        )
                      })
                    }
                  </div>
                </article>

                <div className='flex flex-col gap-2 my-6'>
                  <h1 className='headerTitle'>Amenities</h1>
                  <div className='flex flex-wrap gap-4 justify-between items-center'>
                    {Object.keys(categoryOptions).map((category) => (
                      <article key={category} className='flex flex-col gap-3 w-auto'>
                        <h3 className='subheaderTitle'>
                          {category === 'cookingcleaning' ? 'cooking & cleaning' :
                            category === 'outsideview' ? 'outside view' : category}
                        </h3>
                        {categoryOptions[category].map((option) => (
                          <label key={option} className='flex items-center gap-1 text-xl'>
                            <input
                              type="checkbox"
                              checked={apartment[option]} // Check the boolean value directly
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <p>{formatPropertyName(option)}</p>
                          </label>
                        ))}
                      </article>
                    ))}
                  </div>
                </div>
                <div className='flex flex-col gap-2 my-6'>
                  <h1 className='headerTitle'>Room Rules</h1>
                  <div>
                    {
                      ['smoking', 'pets', 'parties'].map((x, y) => {
                        return (
                          <article key={y} className='flex justify-between w-[300px] mb-4 text-xl'>
                            <p>{x}</p>
                            <RxSwitch values={apartment.houseRules[x]} onClick={() => handleHouseRuleChange(x)} className={`rotate-180 text-3xl text-gray-400 ${apartment.houseRules[x] === 1 && 'rotate-0 text-green-700'}`} />
                          </article>
                        )
                      })
                    }
                  </div>
                </div>
              </form>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </section>
        );
      case 3:
        return (
          <div>
            <h2>Step 3: Other Details</h2>
            <div className=''>
              <CustomInput value={apartment.guest} placeholder={"Number of guests the room can take"} name={"guest"} type={'number'} label={"How many guest can the room take?"} handleChange={handleChange} />
              <article className='form_input_container'>
                <label htmlFor="location">Which bathroom items are available in this room? </label>
                <div className='flex flex-col gap-2 mt-6'>
                  {
                    toiletItems.map((item) => {
                      return (
                        <label htmlFor={'bathroomItems'} key={item.name} className='flex items-center gap-1 text-xl'>
                          <input
                            type="checkbox"
                            value={item.name}
                            checked={apartment.bathroomItems.includes(item.name)}
                            onChange={() => handleBathroomItemChange(item.name)}
                          />
                          <p>{item.name}</p>
                        </label>

                      )
                    })
                  }
                </div>
              </article>

            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Step 3: Final Details</h2>
            <div className='flex flex-col gap-4'>
              {/* images */}
              <div className='flex gap-3 flex-wrap items-center justify-center'>
                {
                  [1, 2, 3, 4].map((fileNumber) => {
                    return (
                      <div key={fileNumber} onClick={() => imageInputRefs[`image_${fileNumber}`].current.click()} className='upload'>
                        <img src={apartment[`image_${fileNumber}`] ? URL.createObjectURL(apartment[`image_${fileNumber}`]) : Upload} alt='upload' className='w-[150px] h-[80%] object-cover' />
                        <input type="file" accept=".jpg, .jpeg, .png, .gif" onChange={(e) => handleFileChange(e, 'image', fileNumber)}
                          ref={imageInputRefs[`image_${fileNumber}`]} style={{ display: 'none' }} />
                        <p className='mt-auto'>Upload your images here, max file is (12mb)</p>
                      </div>
                    )
                  })
                }
              </div>
              {/* videos */}
              <div className='flex gap-4 justify-center'>
                {
                  [1, 2].map((fileNumber) => {
                    return (
                      <div key={fileNumber} onClick={() => videoInputRef[`video_${fileNumber}`].current.click()} className='upload'>
                        {
                          apartment[`video_${fileNumber}`] ?
                            (
                              <video controls width="auto" height="auto">
                                <source src={URL.createObjectURL(apartment[`video_${fileNumber}`])} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )
                            :
                            <img src={Upload} alt='upload' className='w-[150px]' />
                        }
                        <input type="file" accept="video/*" onChange={(e) => handleFileChange(e, 'video', fileNumber)} ref={videoInputRef[`video_${fileNumber}`]} style={{ display: 'none' }} />
                        <p>Upload your Videos here, max file is (40mb)</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <h2>Step 3: Final Details</h2>
            <div className='flex flex-col gap-4'>
              <h2>How much do you charge per night</h2>
              <div>
              <select name="currency" id="currency" className='formInput w-full md:w-[30%]' onChange={handleChange} value={apartment.currency}>
                  <option value="">Select currency</option>
                  {
                    currencies.map((currency)=>{
                      return(
                        <option key={currency.code} value={currency.symbol}>{currency.name}</option>
                      )
                    })
                  }
                </select>
                <CustomInput value={apartment.price} label={'How much is your client expected to pay'} placeholder={'eg 2000000, or 20000.55 or 1500.00'} type={'text'} name="price" handleChange={handleChange} />
              </div>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleSubmit} className={!creating ? ` submitBtn` : 'disabledBtn'} disabled={creating === true}>{creating ? <Loading className={'w-[35px]'}/> : 'Submit'}</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <BreadCrumb items={['Basic Details', 'Room Details', 'Other Details', 'Last Details']} currentStep={step} onStepClick={setStep} />
      {renderStepContent()}
    </div>
  )
}

export default Hotels