
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../Components/BreadCrumb';
import { propertyTypeList } from '../../../data';
import Home from '../../../assets/apartment.png'
import { FaCheckCircle } from "react-icons/fa";
import Apartments from './PropertyTypes/Apartments';
import Hotels from './PropertyTypes/Hotels';
import { useSearchParams } from 'react-router-dom';
import Warehouse from './PropertyTypes/Warehouse';
import OfficeComplex from './PropertyTypes/OfficeComplex';


const AddProperty = () => {
  document.title = 'Add Property'

  const [step, setStep] = useState(1);
  const [propertyType, setPropertyType] = useState('');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const type = searchParams.get('type');


  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePropertyTypeSelect = (selectedType) => {
    setPropertyType(selectedType);
  };

  // EDIT MODE
  useEffect(()=>{
    if(id){
      setPropertyType(type)
      handleNext()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id, type])

  console.log(propertyType)




  const renderStepContent = () => {

    // const StarRating = ({ rating }) => {
    //   const renderStars = () => {
    //     const stars = [];
    //     for (let i = 1; i <= rating; i++) {
    //       stars.push(<span key={i}>⭐</span>);
    //     }
    //     return stars;
    //   };

    //   return <div>{renderStars()}</div>;
    // };
    switch (step) {
      case 1:
        return (
          <div>
            <h2 className='text-2xl pb-4 text-center'>Select Property Type</h2>
            <div className='displayData'>
              {
                propertyTypeList.map((x, y) => {
                  return (
                    <div key={y} onClick={() => handlePropertyTypeSelect(x.name)} className='w-[300px] lg:w-[350px] h-[270px] border-2 border-asparagus-600 p-3 relative flex flex-col gap-3 items-center'>
                      {propertyType === x.name && <FaCheckCircle className='absolute right-2 text-green-700 text-2xl' />}
                      <img src={Home} alt='' className='w-[70px] h-[70px]' />
                      <h3 className='text-xl font-bold'>{x.name}</h3>
                      <p>{x.summary}</p>
                      <button type='button' className='submitBtn' onClick={handleNext}>List Property</button>
                    </div>
                  )
                })
              }
            </div>

            <div className='w-[60%] mx-auto mt-8'><button onClick={handleNext} className='bg-asparagus-700 py-3 w-full text-white text-xl'>Next</button></div>
          </div>
        );
      case 2:
        return (
          <>
            {propertyType === 'Hotel and more' ? <Hotels /> : propertyType === 'Office Complex' ? <OfficeComplex /> : propertyType === 'Warehouse' ? <Warehouse /> : <Apartments propertyType={propertyType} />}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <BreadCrumb items={['Select Property Type', 'Others']} currentStep={step} onStepClick={setStep} />
      {renderStepContent()}
    </div>
  )
}

export default AddProperty