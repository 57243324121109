import React from 'react'
import Seeker from "../../assets/homeseeker.svg"
import Owner from "../../assets/homeowner.svg"
import { Link } from 'react-router-dom'



const OwnerOrSeeker = () => {
 return (
  <div className='h-full flex flex-col gap-5 justify-around my-auto'>
   <div className='text-center my-[20px] text-2xl'>
    <h2>Which are you?</h2>
   </div>
   <section className='flex justify-evenly gap-6 items-center my-auto'>
    <article className='ownerOrSeekerArticle group'>
     <h2 className='text-lg'>Home Owner</h2>
     <img src={Owner} alt="home owner" className='w-[250px]' loading="lazy" width={'600'} height={'600'} />
     <Link to='/auth/register/home-owner' className='ownerOrSeeker'>Home Owner</Link>
    </article>
    <article className='ownerOrSeekerArticle group'>
     <h2 className='text-lg'>Home Seeker</h2>
     <img src={Seeker} alt="home owner" className='w-[250px]' loading="lazy" width={'600'} height={'600'} />
     <Link to='/auth/register/home-seeker' className='ownerOrSeeker'>Home Seeker</Link>
    </article>

   </section>
  </div>
 )
}

export default OwnerOrSeeker