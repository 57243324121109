import React, { useState, useEffect } from 'react'
import Hotel from '../../../Components/Cards/Hotel';
import api from '../../../services/api';
import Loading from '../../../Components/Loading';
import Modal from '../../../Components/Cards/Modal';
import Danger from '../../../assets/danger-sign.png'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



const AllHotels = () => {
  document.title = 'View all listed Hotels'

 const [selectedLocation, setSelectedLocation] = useState('');
 const [minPrice, setMinPrice] = useState('');
 const [maxPrice, setMaxPrice] = useState('');
 const [deleteId, setDeleteId] = useState(null);

 const [activeButton, setActiveButton] = useState('All');
 const [loading, setLoading] = useState(false)
const [isModalOpen, setIsModalOpen] = useState(false);

const navigate = useNavigate()



 const [filteredProperties, setFilteredProperties] = useState([]);

 const applyFilters = () => {
  const newFilteredProperties = filteredProperties.filter((property) => {
   const isLocationMatch = !selectedLocation || property.location.includes(selectedLocation);
   const isPriceRangeMatch = (!minPrice || property.price >= minPrice) && (!maxPrice || property.price <= maxPrice);

   return isLocationMatch && isPriceRangeMatch;
  });

  setFilteredProperties(newFilteredProperties);
 };

 useEffect(() => {
  setLoading(true)
  if(activeButton === 'All'){
    api.homeOwner.hotels.getAllhotels().then((res)=>{
      setTimeout(()=>{
        setLoading(false)
        setFilteredProperties(res.data['All shortlets'])
      },2000)
    }).catch((err)=>console.error(err))
  } else if(activeButton === 'Approved'){
    api.homeOwner.hotels.getAllApprovedhotels().then((res)=>{
        setLoading(false)
      setFilteredProperties(res.data.data)
    }).catch((err)=>console.error(err))
  }else{
    api.homeOwner.hotels.getAllUnApprovedhotels().then((res)=>{
        setLoading(false)
      setFilteredProperties(res.data.data)
    }).catch((err)=>console.error(err))
  }
 }, [activeButton]);

 const handleButtonClick = (buttonName) => {
  setActiveButton(buttonName);
};

if(loading){
  return <Loading className={'w-[50px]'}/>
}

  const openModal = (id) => {
    setDeleteId(id)
    setIsModalOpen(true);
  };

  const deleteItem = () =>{
    api.homeOwner.hotels.deleteHotel(deleteId).then((res)=>{
      console.log(res)
      if(res.status === 200){
        setLoading(true)
        setIsModalOpen(false);
        const updatedProperties = filteredProperties.filter(item => item.id !== deleteId);
          setFilteredProperties(updatedProperties); 
        setTimeout(()=>{
          setLoading(false)
          toast.success(res.message)
        },500)
      }
    }).catch((err)=>console.log(err))
  }

  const edit = (id) =>{
    navigate(`/dashboard/homeowner/add-property?id=${id}&type=Hotel and more`)
  }

 return (
  <section className='flex flex-col gap-4 my-3'>
   {/* filter section */}
   <div>
    <h2 className="text-center font-bold text-3xl text-asparagus-500">All listed Hotels</h2>
   </div>
   <div className='flex gap-4 justify-start md:justify-center items-end overflow-x-scroll myoverflow'>
    <div className="flex flex-col gap-1">
     <label className="mr-2">Location:</label>
     <input
      type="text"
      value={selectedLocation}
      onChange={(e) => setSelectedLocation(e.target.value)}
      className="p-2 border rounded"
     />
    </div>
    <div className="flex flex-col gap-1">
     <label className="mr-2">Price:</label>
     <div className="flex">
      <input
       type="number"
       placeholder="Min"
       value={minPrice}
       onChange={(e) => setMinPrice(e.target.value)}
       className="p-2 border rounded mr-2"
      />
      <input
       type="number"
       placeholder="Max"
       value={maxPrice}
       onChange={(e) => setMaxPrice(e.target.value)}
       className="p-2 border rounded"
      />
     </div>
    </div>
    <button
     onClick={applyFilters}
     className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
    >
     Apply Filters
    </button>
   </div>
   <div className='flex gap-4 justify-center items-center my-4'>
    <button type="button" className={`rounded-md px-4 py-2 text-white ${activeButton === 'All'? 'bg-asparagus-500': 'bg-slate-400'}`} onClick={() => handleButtonClick('All')}>All</button>
    <button type="button" className={`rounded-md px-4 py-2 text-white ${activeButton === 'Approved'? 'bg-asparagus-500': 'bg-slate-400'}`} onClick={() => handleButtonClick('Approved')}>Approved</button>
    <button type="button" className={`rounded-md px-4 py-2 text-white ${activeButton === 'Unapproved'? 'bg-asparagus-500': 'bg-slate-400'}`} onClick={() => handleButtonClick('Unapproved')}>Unapproved</button>
   </div>
   <div className='displayData'>
    {filteredProperties.length > 0 &&
     filteredProperties.map((x, y) => {
      return (
        <Hotel owner={true} image={`https://homelinker.itl.ng/public/upload/hotels/images/${x.image_1}`} seeker={false} handleEditByOwner={()=> edit(x.id)} handleDeleteByOwner={()=>openModal(x.id)} key={y} type={'Hotel'} price={x.price} name={x.property_name} state={x.state} location={x.location} chargeRate={'night'} />
      )
     })
    }
    {
      filteredProperties.length < 1 && (
        <div>
          <p>Nothing to show here</p>
        </div>
      )
    }
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='flex flex-col items-center gap-4'>
          <img src={Danger} alt="" className='w-[100px]' />
          <h2 className='text-2xl'>Are you sure you want to delete?</h2>
          <div className='flex  gap-4 items-center'>
            <button type="button" className='bg-asparagus-600 px-4 rounded-md py-1 text-white font-medium' onClick={deleteItem}>Yes</button>
            <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
          </div>
        </div>
      </Modal>
   </div>
  </section>
 )
}

export default AllHotels