import { useState, useEffect } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Logo from "../assets/home linker.png"
import { IoMenu } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import Dropdown from './Dropdown';
import { MdAccountBalance } from "react-icons/md";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { useUser } from '../Context/UserProvider';
import service from '../services';
import Pic from '../assets/profile_pic.jpg'


const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const { user, logout } = useUser();
  const navigate = useNavigate()

  const toggleMobileMenu = () => {
    setIsMobileOpen((prevCollapsed) => !prevCollapsed);
  };

  const handleLogout = () => {
    logout();
    navigate('/')
    service.reloadPage();
  }

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <nav className={`bg-white shadow-lg relative ${scrolled && 'sticky top-0 z-[1000] w-full lg:w-[95%] shadow-2xl lg:rounded-full mx-auto'}`}>
      <div className={`bg-mosque-700 p-2 text-white flex justify-end ${scrolled && 'hidden'}`}>
        <Link to="contact-us" className='border-2 py-1 px-3 rounded-xl hover:bg-white  hover:text-mosque-800 hover:font-bold hover:border-mosque-400'>Contact</Link>
      </div>
      <div className={`flex flex-wrap items-center justify-between mx-auto p-4`}>
        <Link to='/' className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={Logo} className="h-12 w-16 lg:w-20" alt="Homelinker Logo" loading="lazy" width={'600'} height={'600'} />
          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white hidden md:block">HomeLinkers</span>
        </Link>
        <button data-collapse-toggle="navbar-default" aria-expanded={!isMobileOpen} type="button" onClick={toggleMobileMenu} className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default">
          {!isMobileOpen ? (
            <IoMenu className="text-[50px] transition-opacity duration-300 opacity-100 hover:opacity-80" />
          ) : (
            <RxCross2 className="text-[50px] transition-opacity duration-300 opacity-100 hover:opacity-80" />
          )}
        </button>
        <div class="hidden w-full md:block md:w-auto relative" id="navbar-default">
          <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:items-center md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            <li>
              <NavLink to={'/'} className='nav-links'>Home</NavLink>
            </li>
            <li>
              <NavLink to={'/about-us'} className='nav-links'>About Us</NavLink>
            </li>
            <li>
              {user && Object.keys(user).length > 0 ?
                (
                  <div onClick={() => setIsDropdown(!isDropdown)}>
                    <img src={`https://homelinker.itl.ng/public/upload/profile_pics/${user?.photo}` || Pic} alt={user?.name || 'profile'} className="h-10 w-10 object-contain rounded-full border-double border-2 border-minsk-800 cursor-pointer" />
                  </div>
                )
                :
                (<NavLink to={'auth/login'} className='nav-links'>Login/Register</NavLink>)}
            </li>
          </ul>
          {
            isDropdown && <Dropdown />
          }
        </div>
      </div>
      {isMobileOpen && <div className="md:hidden w-full" id="navbar-default">
        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
          <li>
            <NavLink to={'/'} className='nav-links'>Home</NavLink>
          </li>
          <li>
            <NavLink to={'/about-us'} className='nav-links'>About Us</NavLink>
          </li>
          <li>
            {user && Object.keys(user).length > 0 ?
              (
                <div className='flex flex-col gap-1'>
                  <Link to={`dashboard/${user?.role}`} className='flex gap-2 items-center text-xl p-3'>
                    <MdAccountBalance />
                    <p>Dashboard</p>
                  </Link>
                  <div className='flex gap-2 items-center text-xl p-3 cursor-pointer' onClick={handleLogout}>
                    <LiaSignOutAltSolid />
                    <p>Sign out</p>
                  </div>
                </div>
              )
              :
              (<NavLink to={'auth/login'} className='nav-links'>Login/Register</NavLink>)}
          </li>
        </ul>
      </div>}

    </nav>
  )
}

export default Navbar