import React, { useRef, useState, useEffect } from 'react'
import CustomInput from '../Components/CustomInput'
import service from '../services'
import api from '../services/api'
import Loading from '../Components/Loading'
import { toast } from 'react-toastify'
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { IoLocation } from "react-icons/io5";

const ContactUs = () => {
  const containerRef = useRef(undefined);
  useEffect(() => service.goToTop(containerRef), [])
  document.title = 'Contact Us'
  const [contact, setContact] = useState({
    name: "",
    email: '',
    phone: '',
    subject: '',
    message: '',
    attachments: null
  })
  const[sending, setSending] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setContact((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleAttachments = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Convert the file to a data URL
      // const dataUrl = await readFileAsDataURL(file);
      setContact((prev) => ({
        ...prev,
        attachments: file
      }));

    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    for (const key in contact) {
      if (contact[key] === '') {
        alert(`Please fill in all fields`);
        return;
      }
    }
    setSending(true)
    const formData = new FormData()
    formData.append('fullname', contact.name)
    formData.append('phone_number', contact.phone)
    formData.append('subject', contact.subject)
    formData.append('message', contact.message)
    formData.append('email', contact.email)
    if (contact.attachments) {
      formData.append('file', contact.attachments)
    }
    api.contactUs(formData).then(res=>{
      if(res.status === 200){
        toast.success(res.data.message)
        setContact(contact)
        window.location.replace('/')
      }
    }).catch(res => console.log(res))
  }
  return (
    <div className='w-full md:w-[70%] mx-auto' ref={containerRef}>
      <div className='text-center text-2xl '>
        <h1>Contact us</h1>
        <p className='text-lg'>Get in touch with our team to discuss business</p>
      </div>
      <div className='my-2'>
      <ul className='text-left lg:flex lg:justify-between'>
          <li  className='py-1 flex lg:flex-col gap-2 items-center'>
          <FaPhone size={26} />
            <ul>
              <li>
                <a href="tel:+01217693022">01217693022</a>
              </li>
              <li>
                <a href="tel:+07377640972">07377640972</a>
              </li>
            </ul>
          </li>
          <li className="py-1 flex lg:flex-col gap-2 items-center">
            <IoIosMail size={26} />
            <a href="mailto:robertrichesproperties@gmail.com">robertrichesproperties@gmail.com</a>
          </li>
          <li className="py-1 flex lg:flex-col gap-2 items-center">
          <IoLocation  size={26}/>
            <address>
              <address>11, Brindley Place,</address>
              <address>Brunswick Square,</address>
              <address>Birmingham B12LP</address>
            </address>
          </li>
      </ul>
    </div>
    <h2 className='text-center text-[50px] text-asparagus-600 font-bold'>OR</h2>
      <form>
        <CustomInput value={contact.name} placeholder={'Full Name'} name={'name'} type={'text'} handleChange={handleChange} />
        <article className='flex flex-col md:flex-row md:gap-3'>
          <CustomInput value={contact.email} placeholder={'Email'} name={'email'} type={'email'} handleChange={handleChange} />
          <CustomInput value={contact.phone} placeholder={'Phone Number'} name={'phone'} type={'tel'} handleChange={handleChange} />
        </article>
        <CustomInput value={contact.subject} placeholder={'Subject'} name={'subject'} type={'text'} handleChange={handleChange} />
        <textarea name="message" id="message" cols="30" rows="10" placeholder='Message' className='formInput' onChange={handleChange}/>
        <article className='my-3'>
          <CustomInput placeholder={'Your subject'} name={'attachments'} type={'file'} handleChange={handleAttachments} />
          {
            contact.attachments && <img src={contact.attachments} alt='' className='w-[300px] h-[100px] object-contain' />
          }
        </article>
        <button type="button" className={sending ? 'disabledBtn':'submitBtn'} disabled={sending} onClick={handleSubmit}>{sending ? <Loading className={'w-[30px]'}/> :'Send Message'}</button>
      </form>
    </div>
  )
}

export default ContactUs