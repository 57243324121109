import React, { useState, useRef, useEffect } from 'react'
import BreadCrumb from '../../../../Components/BreadCrumb';
import CustomInput from '../../../../Components/CustomInput';
import GoogleMapComponent from '../../../../Components/GoogleMap';
import { categoryOptions, currencies, houseTypes } from '../../../../data';
import { RxSwitch } from "react-icons/rx";
import Upload from '../../../../assets/upload.svg'
import api from "../../../../services/api"
import { formatPropertyName } from '../../../../Components/Reuseables';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../Context/UserProvider';
import Loading from '../../../../Components/Loading'


const Apartments = ({ propertyType }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const imageInputRefs = {
    image_1: useRef(null),
    image_2: useRef(null),
    image_3: useRef(null),
    image_4: useRef(null),
  };
  const videoInputRef = {
    video_1: useRef(null),
    video_2: useRef(null),
  };
  const { user } = useUser();

  const navigate = useNavigate()

  const [step, setStep] = useState(1);
  const [creating, setCreating] = useState(false)
  const [apartment, setApartment] = useState({
    property_name: '',
    location: '',
    street_name: '',
    state: '',
    no_of_bedroom: '',
    no_of_bathroom: '',
    garage: '0',
    type: propertyType,
    tag: '',
    price: '',
    size: '0',
    smoking: 0,
    pets: 0,
    parties: 0,
    image_1: '',
    image_2: '',
    image_3: '',
    image_4: '',
    video_1: '',
    video_2: '',
    air_conditioner: 0,
    heating: 0,
    free_wifi: 0,
    electric_vehicle_charging_station: 0,
    kitchen: 0,
    kitchenette: 0,
    washing_machine: 0,
    oven: 0,
    fridge: 0,
    flat_screen_tv: 0,
    swimming_pool: 0,
    hot_tub: 0,
    mini_bar: 0,
    balcony: 0,
    garden_view: 0,
    terrace: 0,
    ocean_view: 0,
    house_type:'',
    currency:'',
    overview:''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApartment((prevApartment) => ({
      ...prevApartment,
      [name]: value,
    }));
  };

  // map click
  const handlePinDrop = (coordinates) => {
    setApartment((prev) => ({
      ...prev,
      map: coordinates
    }))
  }
  // houserules
  const handleHouseRuleChange = (rule) => {
    setApartment(prevApartment => ({
      ...prevApartment,
      [rule]: prevApartment[rule] === 1 ? 0 : 1,
    }));
  };
  const handleNext = () => {
    let isValid = false;

    switch (step) {
      case 1:
        // Validate fields for Step 2
        isValid = validateStep1Fields();
        break;
      case 2:
        // Validate fields for Step 3
        isValid = validateStep2Fields();
        break;
      case 4:
        // Validate fields for Step 4
        isValid = validateStep4Fields();
        break;

      default:
        isValid = true;
    }

    if (isValid) {

      setStep(step + 1);
    } else {
      // Display an error message or take appropriate action if validation fails
      toast.error(`Please fill in all required fields in Step ${step}`);
    }
  };

  // Validation functions for each step
  const validateStep1Fields = () => {
    if (!apartment.property_name || !apartment.location || !apartment.street_name || !apartment.state) {
      return false;
    }

    return true;
  };

  const validateStep2Fields = () => {
    if(propertyType === 'Apartment'){
      if (!apartment.tag || !apartment.no_of_bedroom || !apartment.no_of_bathroom) {
        return false;
      }
    }else {
      if (!apartment.no_of_bedroom || !apartment.no_of_bathroom) {
        return false;
      }
    }

    return true;
  };

  
  const validateStep4Fields = () => {
    if (!apartment.price) {
      return false;
    }

    return true;
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleCheckboxChange = (option) => {
    setApartment((prevApartment) => ({
      ...prevApartment,
      [option]: prevApartment[option] === 1 ? 0 : 1, 
    }));
  };

  const handleFileChange = (event, fileType, fileNumber) => {
    const selectedFile = event.target.files[0];
    // Check file size based on fileType
    const maxSize = fileType === 'photo' ? 12 * 1024 * 1024 : 40 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      alert(`File size exceeds the allowed limit (${maxSize / (1024 * 1024)} MB). Please choose a smaller file.`);
      return;
    }
    setApartment((prevDetails) => ({
      ...prevDetails,
      [`${fileType}_${fileNumber}`]: selectedFile,
    }));
  };

  useEffect(()=>{
    if(id){
      if(propertyType === 'Apartment'){
        api.homeOwner.apartments.getById(id).then(res=>{setApartment(res.data?.data)}).catch(err=>console.log(err))
      } else if(propertyType === 'Shortlet'){
        api.homeOwner.shortlets.getById(id).then(res=>{setApartment(res.data?.data)}).catch(err=>console.log(err))
      } else if(propertyType === 'Airbnb'){
        api.homeOwner.airbnb.getById(id).then(res=>{setApartment(res.data?.data)}).catch(err=>console.log(err))
      } else if (propertyType === 'Studio Apartment'){
        api.homeOwner.studioApartment.getById(id).then(res=>{setApartment(res.data?.data)}).catch(err=>console.log(err))
      }
    }
  },[id, propertyType])


  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()

    setCreating(true)

    formData.append('property_name', apartment.property_name)
    formData.append('street_name', apartment.street_name)
    formData.append('location', apartment.location)
    formData.append('state', apartment.state)
    formData.append('no_of_bedroom', apartment.no_of_bedroom)
    formData.append('no_of_bathroom', apartment.no_of_bathroom)
    formData.append('size', apartment.size)
    formData.append('garage', apartment.garage)
    formData.append('air_conditioner', apartment.air_conditioner)
    formData.append('heating', apartment.heating)
    formData.append('free_wifi', apartment.free_wifi)
    formData.append('electric_vehicle_charging_station', apartment.electric_vehicle_charging_station)
    formData.append('kitchen', apartment.kitchen)
    formData.append('kitchenette', apartment.kitchenette)
    formData.append('washing_machine', apartment.washing_machine)
    formData.append('oven', apartment.oven)
    formData.append('fridge', apartment.fridge)
    formData.append('flat_screen_tv', apartment.flat_screen_tv)
    formData.append('swimming_pool', apartment.swimming_pool)
    formData.append('hot_tub', apartment.hot_tub)
    formData.append('mini_bar', apartment.mini_bar)
    formData.append('balcony', apartment.balcony)
    formData.append('garden_view', apartment.garden_view)
    formData.append('terrace', apartment.terrace)
    formData.append('ocean_view', apartment.ocean_view)
    formData.append('smoking', apartment.smoking)
    formData.append('parties', apartment.parties)
    formData.append('pets', apartment.pets)
    formData.append('tag', apartment.tag)
    formData.append('price', apartment.price)
    // Check if the files are already URLs or need to be appended as files
  if (apartment.image_1 instanceof File) {
    formData.append('image_1', apartment.image_1);
  }
  if (apartment.image_2 instanceof File) {
    formData.append('image_2', apartment.image_2);
  }
  if (apartment.image_3 instanceof File) {
    formData.append('image_3', apartment.image_3);
  }
  if (apartment.image_4 instanceof File) {
    formData.append('image_4', apartment.image_4);
  }
  if (apartment.video_1 instanceof File) {
    formData.append('video_1', apartment.video_1);
  }
  if (apartment.video_2 instanceof File) {
    formData.append('video_2', apartment.video_2);
  }
    formData.append('house_type', apartment.house_type)
    formData.append('overview', apartment.overview)
    formData.append('currency', apartment.currency)
    
    if(id){
      // update existing property
      switch (propertyType) {
        case 'Apartment':
          api.homeOwner.apartments.updateApartment(id,formData).then((res) => {
            if(res.status === 200){
              toast.success(res.data.message)
              navigate(`/dashboard/${user.role}/all-properties`)
              setApartment(apartment)
            }
          }).catch((res) => {
            console.log(res)
          }).finally(()=>setCreating(false))
          break;
  
        case 'Shortlet':
          api.homeOwner.shortlets.updateShortlet(id,formData)
            .then((res) => {
              if(res.status === 200){
                toast.success(res.data.message)
                navigate(`/dashboard/${user.role}/all-shortlets`)
                setApartment(apartment)
              }
            })
            .catch((res) => {
              console.log(res)
            }).finally(()=>setCreating(false))
          break;
  
        case 'Studio Apartment':
          api.homeOwner.studioApartment.updateStudioApartment(id,formData).then((res) => {
            if(res.status === 200){
              toast.success(res.data.message)
              navigate(`/dashboard/${user.role}/all-studioApartments`)
              setApartment(apartment)
            }
          }).catch((res) => {
            if(res.status === 404 || res.status === 500){
              setCreating(false)
              console.log(res)
            }
          }).finally(()=>setCreating(false))
          break;
  
        case 'Airbnb':
          api.homeOwner.airbnb.updateAirbnb(id,formData).then((res) => {
            if(res.status === 200){
              toast.success(res.data.message)
              navigate(`/dashboard/${user.role}/all-servicedAccomodation`)
              setApartment(apartment)
            }
          }).catch((res) => {
            if(res.status === 404 || res.status === 500){
              console.log(res)
            }
          }).finally(()=>setCreating(false))
          break;
  
        default:
          // Default logic if propertyType doesn't match any case
          break;
      }
    } else{
      switch (propertyType) {
        case 'House':
          api.homeOwner.apartments.createApartment(formData).then((res) => {
            if(res.status === 200){
              toast.success(res.data.message)
              navigate(`/dashboard/${user.role}/all-properties`)
              setApartment(apartment)
            }
          }).catch((res) => {
            if(res.status === 404 || res.status === 500){
              setCreating(false)
              toast.error(res.response.data.message)
            }
          }).finally(()=>setCreating(false))
          break;
  
        case 'Shortlet':
          api.homeOwner.shortlets.createShortlet(formData)
            .then((res) => {
              if(res.status === 200){
                toast.success(res.data.message)
                navigate(`/dashboard/${user.role}/all-shortlets`)
                setApartment(apartment)
              }
            })
            .catch((res) => {
              toast.error(res.response.data.message)
            }).finally(()=>setCreating(false))
          break;
  
        case 'Flat':
          api.homeOwner.studioApartment.createStudioApartment(formData).then((res) => {
            if(res.status === 200){
              toast.success(res.data.message)
              navigate(`/dashboard/${user.role}/all-studioApartments`)
              setApartment(apartment)
            }
          }).catch((res) => {
            if(res.status === 404 || res.status === 500){
              setCreating(false)
              toast.error(res.response.data.message)
            }
          }).finally(()=>setCreating(false))
          break;
  
        case 'Serviced Accommodation':
          api.homeOwner.airbnb.createAirbnb(formData).then((res) => {
            if(res.status === 200){
              toast.success(res.data.message)
              navigate(`/dashboard/${user.role}/all-servicedAccomodation`)
              setApartment(apartment)
            }
          }).catch((res) => {
            if(res.status === 404 || res.status === 500){
              toast.error(res.response.data.message)
            }
          }).finally(()=>setCreating(false))
          break;
  
        default:
          // Default logic if propertyType doesn't match any case
          break;
      }
    }

    
  };


  // Handle stages rendering
  const renderStepContent = () => {

    switch (step) {
      case 1:
        return (
          <section>
            <h2>Step 1: Basic Details</h2>
            <div>
              <form>
                <CustomInput value={apartment.property_name} placeholder={"Property name"} name={"property_name"} type={'text'} label={"Property Name"} handleChange={handleChange} />
                <article className='form_input_container'>
                  <label htmlFor="location">Location <span className='important'>*</span></label>
                  <select name="location" id="" value={apartment.location} onChange={handleChange} className='formInput'>
                    <option value="">Select your location</option>
                    {
                      ['Nigeria', 'United Kingdom', 'United States'].map((country) => {
                        return (
                          <option key={country}>{country}</option>
                        )
                      })
                    }
                  </select>
                </article>
                <CustomInput value={apartment.street_name} placeholder={""} name={"street_name"} type={'text'} label={"Street name and house number"} handleChange={handleChange} />
                <CustomInput value={apartment.state} placeholder={""} name={"state"} type={'text'} label={"State"} handleChange={handleChange} />
                <div className='form_input_container'>
                  <label htmlFor="overview">Overview</label>
                  <textarea name="overview" className='formInput' id="overview" cols="30" rows="10" value={apartment.overview} onChange={handleChange}/>
                </div>
                <GoogleMapComponent onPinDrop={handlePinDrop} />
              </form>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </section>
        );
      case 2:
        return (
          <section>
            <h2>Step 2: Main Details</h2>
            <div>
              <form>
                {<article className='form_input_container'>
                  <label htmlFor="house_type">House Type <span className='important'>*</span></label>
                  <select name="house_type" id="" value={apartment.house_type} onChange={handleChange} className='formInput'>
                    <option value="">Select house type</option>
                    {
                      houseTypes.map((tag) => {
                        return (
                          <option className='capitalize' value={tag.value} key={tag}>{tag.name}</option>
                        )
                      })
                    }
                  </select>
                </article>}
                {<article className='form_input_container'>
                  <label htmlFor="country">Tag <span className='important'>*</span></label>
                  <select name="tag" id="" value={apartment.tag} onChange={handleChange} className='formInput'>
                    <option value="">Select your tag</option>
                    {
                      ['rent', 'sales', 'lease'].map((tag) => {
                        return (
                          <option className='capitalize' key={tag}>{tag}</option>
                        )
                      })
                    }
                  </select>
                </article>}
                <CustomInput value={apartment.no_of_bedroom} placeholder={"Number of bedrooms"} name={"no_of_bedroom"} type={'number'} label={"Bedroom"} handleChange={handleChange} />
                <CustomInput value={apartment.no_of_bathroom} placeholder={"Number of bathroom"} name={"no_of_bathroom"} type={'number'} label={"Bathroom"} handleChange={handleChange} />
                <CustomInput value={apartment.garage} placeholder={"Number of cars garage can contain"} name={"garage"} type={'number'} label={"Garage"} handleChange={handleChange} />
                <CustomInput value={apartment.size} placeholder={"Size of building in feet"} name={"size"} type={'number'} label={"Size"} handleChange={handleChange} />
                <div className='flex flex-col gap-2 my-6'>
                  <h1 className='headerTitle'>Amenities</h1>
                  <div className='flex flex-wrap gap-4 justify-between items-center'>
                    {Object.keys(categoryOptions).map((category) => (
                      <article key={category} className='flex flex-col gap-3 w-auto'>
                        <h3 className='subheaderTitle'>
                          {category === 'cookingcleaning' ? 'cooking & cleaning' :
                            category === 'outsideview' ? 'outside view' : category}
                        </h3>
                        {categoryOptions[category].map((option) => (
                          <label key={option} className='flex items-center gap-1 text-xl'>
                            <input
                              type="checkbox"
                              checked={apartment[option]} // Check the boolean value directly
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <p>{formatPropertyName(option)}</p>
                          </label>
                        ))}
                      </article>
                    ))}
                  </div>

                </div>
                <div className='flex flex-col gap-2 my-6'>
                  <h1 className='headerTitle'>House Rules</h1>
                  <div>
                    {
                      ['smoking', 'pets', 'parties'].map((x, y) => {
                        return (
                          <article key={y} className='flex justify-between w-[300px] mb-4 text-xl'>
                            <p>{x}</p>
                            <RxSwitch values={apartment[x]} onClick={() => handleHouseRuleChange(x)} className={`rotate-180 text-3xl text-gray-400 ${apartment[x] === 1 && 'rotate-0 text-green-700'}`} />
                          </article>
                        )
                      })
                    }
                  </div>
                </div>
              </form>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </section>
        );
      case 3:
        return (
          <div>
            <h2>Step 3: Pictorial and Video Details</h2>
            <div className='flex flex-col gap-4'>
              {/* images */}
              {
                id ? (
                  <div className='flex gap-3 flex-wrap items-center justify-center'>
                {
                  [1, 2, 3, 4].map((fileNumber) => {
                    let type
                    if(propertyType === 'Apartment'){
                      type='apartments'
                    }else if(propertyType === 'Shortlet'){
                      type='shortlets'
                    } else if(propertyType === 'Studio Apartment'){
                      type='studioapartments'
                    } else{
                      type='airbnbs'
                    }
                    return (
                      <div key={fileNumber} onClick={() => imageInputRefs[`image_${fileNumber}`].current.click()} className='upload'>
                        <img 
                        src={
                          apartment[`image_${fileNumber}`] instanceof File
                            ? URL.createObjectURL(apartment[`image_${fileNumber}`])
                            : apartment[`image_${fileNumber}`]
                              ? `https://homelinker.itl.ng/public/upload/${type}/images/` +
                              apartment[`image_${fileNumber}`]
                              : "https://default-remote-image-url.com" // Provide a default URL here
                        }                        
                        alt='upload' 
                        className='w-[150px] h-[80%] object-cover' />
                        <input type="file" accept=".jpg, .jpeg, .png, .gif" onChange={(e) => handleFileChange(e, 'image', fileNumber)}
                          ref={imageInputRefs[`image_${fileNumber}`]} style={{ display: 'none' }} />
                        <p className='mt-auto'>Upload your images here, max file is (12mb)</p>
                      </div>
                    )
                  })
                }
              </div>
                ) : (
                  <div className='flex gap-3 flex-wrap items-center justify-center'>
                {
                  [1, 2, 3, 4].map((fileNumber) => {
                    return (
                      <div key={fileNumber} onClick={() => imageInputRefs[`image_${fileNumber}`].current.click()} className='upload'>
                        <img src={apartment[`image_${fileNumber}`] ? URL.createObjectURL(apartment[`image_${fileNumber}`]) : Upload} alt='upload' className='w-[150px] h-[80%] object-cover' />
                        <input type="file" accept=".jpg, .jpeg, .png, .gif" onChange={(e) => handleFileChange(e, 'image', fileNumber)}
                          ref={imageInputRefs[`image_${fileNumber}`]} style={{ display: 'none' }} />
                        <p className='mt-auto'>Upload your images here, max file is (12mb)</p>
                      </div>
                    )
                  })
                }
              </div>
                )
              }
            
              {/* videos */}
              {
                id ? (
                  <div className='flex gap-4 justify-center'>
                {
                  [1, 2].map((fileNumber) => {
                    let type
                    if(propertyType === 'Apartment'){
                      type='apartments'
                    }else if(propertyType === 'Shortlet'){
                      type='shortlets'
                    } else if(propertyType === 'Studio Apartment'){
                      type='studioapartments'
                    } else{
                      type='airbnbs'
                    }
                    return (
                      <div key={fileNumber} onClick={() => videoInputRef[`video_${fileNumber}`].current.click()} className='upload'>
                        {
                          apartment[`video_${fileNumber}`] ?
                            (
                              <video controls width="auto" height="auto">
                                <source src={
                          apartment[`image_${fileNumber}`] instanceof File
                            ? URL.createObjectURL(apartment[`image_${fileNumber}`])
                            : apartment[`image_${fileNumber}`]
                              ? `https://homelinker.itl.ng/public/upload/${type}/videos/` +
                              apartment[`video_${fileNumber}`]
                              : "https://default-remote-image-url.com" // Provide a default URL here
                        } type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )
                            :
                            <img src={Upload} alt='upload' className='w-[150px]' />
                        }
                        <input type="file" accept="video/*" onChange={(e) => handleFileChange(e, 'video', fileNumber)} ref={videoInputRef[`video_${fileNumber}`]} style={{ display: 'none' }} />
                        <p>Upload your Videos here, max file is (40mb)</p>
                      </div>
                    )
                  })
                }
              </div>
                ) : (
                  <div className='flex gap-4 justify-center'>
                {
                  [1, 2].map((fileNumber) => {
                    return (
                      <div key={fileNumber} onClick={() => videoInputRef[`video_${fileNumber}`].current.click()} className='upload'>
                        {
                          apartment[`video_${fileNumber}`] ?
                            (
                              <video controls width="auto" height="auto">
                                <source src={URL.createObjectURL(apartment[`video_${fileNumber}`])} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )
                            :
                            <img src={Upload} alt='upload' className='w-[150px]' />
                        }
                        <input type="file" accept="video/*" onChange={(e) => handleFileChange(e, 'video', fileNumber)} ref={videoInputRef[`video_${fileNumber}`]} style={{ display: 'none' }} />
                        <p>Upload your Videos here, max file is (40mb)</p>
                      </div>
                    )
                  })
                }
              </div>
                )
              }
              
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleNext} className='submitBtn '>Next</button>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Step 3: Final Details</h2>
            <div className='flex flex-col gap-4'>
              <h2>How much do you charge {apartment.tag === 'rent' ? 'per month' : apartment.tag === 'sale' ? 'per property' : apartment.tag === 'lease' && 'per leasing'}</h2>
              <div>
                <select name="currency" id="currency" className='formInput w-full md:w-[30%]' value={apartment.currency} onChange={handleChange}>
                  <option value="">Select currency</option>
                  {
                    currencies.map((currency)=>{
                      return(
                        <option key={currency.code} value={currency.symbol}>{currency.name}</option>
                      )
                    })
                  }
                </select>
                <CustomInput value={apartment.price} label={'How much is your client expected to pay (just the value, no comma)'} placeholder={'eg 2000000, or 20000.55 or 1500.00'} type={'text'} name="price" handleChange={handleChange} />
              </div>
            </div>
            <div className='flex gap-6 my-8 w-[50%] justify-start'>
              <button onClick={handleBack} className='submitBtn !bg-mosque-200'>Back</button>
              <button onClick={handleSubmit} className={!creating ? ` submitBtn` : 'disabledBtn'} disabled={creating === true}>{creating ? <Loading className={'w-[35px]'}/> : 'Submit'}</button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <BreadCrumb items={['Basic Details', 'Main Details', 'Last Details']} currentStep={step} onStepClick={setStep} />
      {renderStepContent()}
    </div>
  )
}

export default Apartments