import React, { useEffect } from 'react'
import { MdDeleteForever } from "react-icons/md";
import { formatPrice } from '../Reuseables';
import { useNavigate } from 'react-router-dom';



const WishlistItem = ({ price, location, property_name,  date,  id, type, removeWish }) => {
 const navigate = useNavigate()

 useEffect(() => {
  document.title = 'My wishes'
 }, [])

 const handleRemoveWish = (e, id) =>{
  e.stopPropagation();
  removeWish(id)
 }


 return (
  <article className='relative flex flex-col gap-3 bg-asparagus-500 w-[300px] h-[200px]' id={id} onClick={() => navigate(`/property/${id}/${type}`)}>
   {/* figure */}
   <figure className='propCard_figure'>
    <div className='propCard_absolute left-3'>
     <p className="propCard_tag capitalize">{type}</p>
    </div>
    <div className='propCard_absolute propCard_right'>
     <MdDeleteForever onClick={(e)=>handleRemoveWish(e,id)}/>
    </div>
    <p className="propCard_price"> ₦{formatPrice(price)}</p>
   </figure>
   {/* other info */}
   <div className='propCard_info'>
    <small className='hidden'>{type}</small>
    <small className='text-lg text-gray-400'>{location}</small>
    <address className='text-xl font-bold'>
     {property_name}
    </address>
    <div className='flex justify-between items-center my-3'>
     <p className='italic text-gray-400'>4 months ago</p>
    </div>
   </div>
  </article>
 )
}

export default WishlistItem