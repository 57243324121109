/* eslint-disable no-undef */
const baseUrl = "https://homelinker.itl.ng";

const routes = {
  register: (userType) => `${baseUrl}/${userType}/register`,
  login: (userType) => `${baseUrl}/${userType}/login`,
  'refresh-token': (userType) => `${baseUrl}/${userType}/refresh`,
  'activate-password': (userType) => `${baseUrl}/${userType}/activate-password`,
  'reset-password': (userType) => `${baseUrl}/${userType}/reset-password`,
  'contact-us': () => `${baseUrl}/homeseeker/contact-us`,
  admin: {
    url: () => `${baseUrl}/admin/api`,
    get: {
      'all-seekers': () => `${routes.admin.url()}/all-homeseeker`,
      'all-homeowners': () => `${routes.admin.url()}/all-homeowner`
    }
  },
  homeowner: {
    url: () => `${baseUrl}/homeowner/api`,
    'update-profile': () => `${routes.homeowner.url()}/update-profile`,
    apartments: {
      'create-apartment': () => `${routes.homeowner.url()}/create-apartment`,
      'update-apartment': (id) => `${routes.homeowner.url()}/update-apartment/${id}`,
      'get-approvedApartments': () => `${routes.homeowner.url()}/get-approved-apartment`,
      'get-unapprovedApartments': () => `${routes.homeowner.url()}/get-unapproved-apartment`,
      'get-allApartments': () => `${routes.homeowner.url()}/all-apartment`,
      'delete-Apartment': (id) => `${routes.homeowner.url()}/delete-apartment/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-apartment/${id}`
    },
    shortlets: {
      'create-shortlet': () => `${routes.homeowner.url()}/create-shortlet`,
      'update-shortlet': (id) => `${routes.homeowner.url()}/update-shortlet/${id}`,
      'get-approvedshortlets': () => `${routes.homeowner.url()}/get-approved-shortlet`,
      'get-unapprovedshortlets': () => `${routes.homeowner.url()}/get-unapproved-shortlet`,
      'get-allshortlets': () => `${routes.homeowner.url()}/all-shortlet`,
      'delete-shortlet': (id) => `${routes.homeowner.url()}/delete-shortlet/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-shortlet/${id}`
    },
    hotels: {
      'create-hotel': () => `${routes.homeowner.url()}/create-hotel`,
      'update-hotel': (id) => `${routes.homeowner.url()}/update-hotel/${id}`,
      'get-approvedhotels': () => `${routes.homeowner.url()}/get-approved-hotel`,
      'get-unapprovedhotels': () => `${routes.homeowner.url()}/get-unapproved-hotel`,
      'get-allhotel': () => `${routes.homeowner.url()}/all-hotel`,
      'delete-hotel': (id) => `${routes.homeowner.url()}/delete-hotel/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-hotel/${id}`
    },
    airbnb: {
      'create-airbnb': () => `${routes.homeowner.url()}/create-airbnb`,
      'update-airbnb': (id) => `${routes.homeowner.url()}/update-airbnb/${id}`,
      'get-approvedairbnbs': () => `${routes.homeowner.url()}/get-approved-airbnb`,
      'get-unapprovedairbnbs': () => `${routes.homeowner.url()}/get-unapproved-airbnb`,
      'get-allairbnb': () => `${routes.homeowner.url()}/all-airbnb`,
      'delete-airbnb': (id) => `${routes.homeowner.url()}/delete-airbnb/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-airbnb/${id}`
    },
    "studio-apartment": {
      'create-studioApartment': () => `${routes.homeowner.url()}/create-studio-Studioapartment`,
      'update-studioApartment': (id) => `${routes.homeowner.url()}/update-studio-apartment/${id}`,
      'get-approvedstudioApartments': () => `${routes.homeowner.url()}/get-approved-Studioapartment`,
      'get-unapprovedstudioApartments': () => `${routes.homeowner.url()}/get-unapproved-Studioapartment`,
      'get-allstudioApartment': () => `${routes.homeowner.url()}/all-studio-apartment`,
      'delete-studioApartment': (id) => `${routes.homeowner.url()}/delete-studio-apartment/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-studio-apartment/${id}`
    },
    warehouse:{
      'create-warehouse' : () => `${routes.homeowner.url()}/create-warehouse`,
      'update-warehouse': (id) => `${routes.homeowner.url()}/update-warehouse/${id}`,
      'get-approvedwarehouse': () => `${routes.homeowner.url()}/get-approved-warehouse`,
      'get-unapprovedwarehouse': () => `${routes.homeowner.url()}/get-unapproved-warehouse`,
      'get-warehouse': () => `${routes.homeowner.url()}/all-warehouse`,
      'delete-warehouse': (id) => `${routes.homeowner.url()}/delete-warehouse/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-warehouse/${id}`
    },
    officeComplex:{
      'create-office-complex' : () => `${routes.homeowner.url()}/create-office-complex`,
      'update-office-complex': (id) => `${routes.homeowner.url()}/update-office-complex/${id}`,
      'get-approvedoffice-complex': () => `${routes.homeowner.url()}/get-approved-office-complex`,
      'get-unapprovedoffice-complex': () => `${routes.homeowner.url()}/get-unapproved-office-complex`,
      'get-office-complex': () => `${routes.homeowner.url()}/all-office-complex`,
      'delete-office-complex': (id) => `${routes.homeowner.url()}/delete-office-complex/${id}`,
      'get-byId': (id) => `${routes.homeowner.url()}/get-office-complex/${id}`
    }
  },
  rep: {
    url: () => `${baseUrl}/rep/api`,
    'update-profile': () => `${routes.rep.url()}/update-profile`,
    apartments: {
      'get-allApartments': () => `${routes.rep.url()}/all-apartment`,
      'get-byId': (id) => `${routes.rep.url()}/get-apartment/${id}`
    },
    shortlets: {
      'get-allshortlets': () => `${routes.rep.url()}/all-shortlet`,
      'get-byId': (id) => `${routes.rep.url()}/get-shortlet/${id}`
    },
    hotels: {
      'get-allhotel': () => `${routes.rep.url()}/all-hotel`,
      'get-byId': (id) => `${routes.rep.url()}/get-hotel/${id}`
    },
    airbnb: {
      'get-allairbnb': () => `${routes.rep.url()}/all-airbnb`,
      'get-byId': (id) => `${routes.rep.url()}/get-airbnb/${id}`
    },
    "studio-apartment": {
      'get-allstudioApartment': () => `${routes.rep.url()}/all-studio-apartment`,
      'get-byId': (id) => `${routes.rep.url()}/get-studio-apartment/${id}`
    },
  },
  homeseeker: {
    url: () => `${baseUrl}/homeseeker`,
    'update-profile': () => `${routes.homeseeker.url()}/api/update-profile`,
    wish: {
      'add-to-wishlist': () => `${routes.homeseeker.url()}/api/add-wishlist`,
      'delete-wishlist': (id) => `${routes.homeseeker.url()}/api/delete-wishlist/${id}`,
      'get-wishes': () => `${routes.homeseeker.url()}/api/get-wishlist`
    },
    get: {
      'apartments': () => `${routes.homeseeker.url()}/all/apartments`,
      'hotels': () => `${routes.homeseeker.url()}/all/hotels`,
      'shortlets': () => `${routes.homeseeker.url()}/all/shortlets`,
      'airbnb': () => `${routes.homeseeker.url()}/all/airbnbs`,
      'studioApartment': () => `${routes.homeseeker.url()}/all/studio-apartments`,
      'warehouse': () => `${routes.homeseeker.url()}/all/warehouse`,
      'office-complex' : () => `${routes.homeseeker.url()}/all/office-complex`,
      single: {
        'apartment': (id) => `${routes.homeseeker.url()}/get-apartment/${id}`,
        'hotel': (id) => `${routes.homeseeker.url()}/get-hotel/${id}`,
        'shortlet': (id) => `${routes.homeseeker.url()}/get-shortlet/${id}`,
        'airbnb': (id) => `${routes.homeseeker.url()}/get-airbnb/${id}`,
        'studio-apartment': (id) => `${routes.homeseeker.url()}/get-studio-apartment/${id}`,
        'warehouse': (id) => `${routes.homeseeker.url()}/get-warehouse/${id}`,
        'office-complex': (id) => `${routes.homeseeker.url()}/get-office-complex/${id}`,
      }
    }
  }
};

export default routes;
