export const formatPropertyName = (propertyName) => {
  return propertyName.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const formatPrice = (amount) => {
  const parsedAmount = parseFloat(amount);

  if (isNaN(parsedAmount)) {
    return "Invalid Amount";
  }

  return parsedAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const calculatePasswordStrength = (password) => {
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (hasLowercase && hasUppercase && hasNumber && hasSpecialChar && password.length >= 8) {
    return 'Strong';
  } else if ((hasLowercase || hasUppercase || hasNumber || hasSpecialChar) && password.length >= 6) {
    return 'Moderate';
  } else {
    return 'Weak';
  }
};

export const readFileAsDataURL = (file) => {
 return new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
   resolve(reader.result);
  };

  reader.onerror = (error) => {
   reject(error);
  };

  reader.readAsDataURL(file);
 });
};

export function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  const diff = now - date;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
      return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months > 0) {
      return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days > 0) {
      return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
      return 'Just now';
  }
}
