import React, { useEffect, useState } from 'react'
// import Agent from '../../assets/niyicodes.jpg'
import { MdOutlineKingBed } from "react-icons/md";
import { GiHomeGarage } from "react-icons/gi";
import { MdOutlineBathtub } from "react-icons/md";
import { FaRegSquareFull,FaHeart } from "react-icons/fa6";
import { CiHeart } from "react-icons/ci";
import { formatPrice, timeAgo } from '../Reuseables';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDelete } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import api from '../../services/api';
import { useUser } from '../../Context/UserProvider';
import { useWishlist } from '../../Context/WishListProvider';
import NoImage from '../../assets/noImage.jpg'

const PropertyCard = ({currency, tag, price, location, state, agentName, date, sqrFt, bed, garage, bath, id, type, propertyName, address, owner, seeker, handleDeleteByOwner, handleEditByOwner, image }) => {
  const navigate = useNavigate()
  const { user } = useUser();
  const {wishlist} = useWishlist()
  const isUserLoggedIn = () => user && Object.keys(user).length > 0

  const[liked, setLiked] = useState(false)

  useEffect(()=>{
    const isLiked = wishlist.some(item => item.property_id.toString() === id.toString());
    setLiked(isLiked);
  },[id, wishlist])


  const handleAddWish = (e) => {
    e.stopPropagation();
    const formData = new FormData()
    formData.append('property_id', id)
    formData.append('property_type', type)
    if(isUserLoggedIn()){
      api.homeSeeker.wish.addToWishlist(formData).then((res)=>{
        if(res.status === 200){
          toast.success('Wish added')
          setLiked(true)
        }
      }).catch((err) => console.log(err))
    }else{
      navigate('/auth/login')
    }
  }

  // const handleRemoveWish = (e, id) =>{
  //   e.stopPropagation();
  //   const index = wishlist.findIndex(item => item.property_id === id.toString());
  //   if (index !== -1) {
  //     const wishToRemove = wishlist[index];
  //     api.homeSeeker.wish.deleteWish(wishToRemove.id)
  //         .then((res) => {
  //             console.log(res);
  //             if(res.status === 200){
  //               const wishlistFromLocalStorage = JSON.parse(sessionStorage.getItem('wishes')) || [];
  //               const updatedWishlist = wishlistFromLocalStorage.filter(item => item.id !== id);
  //               setWishlist(updatedWishlist)
  //               toast.success('Wish removed');
  //               wishlist.splice(index, 1);
  //               setLiked(false)
  //             }
  //         })
  //         .catch((err) => console.log(err));
  // } else {
  //     console.log('Wish not found in the wish list');
  // }
  //  }

  const handleDelete = (e, id) => {
    e.stopPropagation();
    handleDeleteByOwner(id)
  }
  const handleEdit = (e, id) => {
    e.stopPropagation();
    handleEditByOwner(id)
  }
  const propCardTag = type === 'apartment' ? `For ${tag}` : type === 'shortlet' ? 'Shortlet' : type === 'Airbnb' ? 'Airbnb' : type === 'Hotel' ? 'Hotels' : type === 'warehouse' ? `For ${tag}` : type === 'office_complex' ? `For ${tag}` : 'Studio Apartment'
  return (
    <article className='propCard' id={id} onClick={() => navigate(`/property/${id}/${type}`)}>
      {/* figure */}
      <figure className='propCard_figure'>
        <img src={image} alt="house" width={600} height={600} className='w-full h-[100%]' onError={(e) => {
        e.target.onerror = null;
        e.target.src = NoImage;
      }}/>
        <div className='propCard_absolute left-3'>
          <p className="propCard_tag">{propCardTag}</p>
        </div>
        <article className='propCard_absolute propCard_right '>
          <div className=' cursor-pointer'>
            {seeker && liked ? <FaHeart className="cursor-pointer fill-red-600" />:<CiHeart onClick={handleAddWish} />}
          </div>
          <div className='cursor-pointer'>
            {owner && <MdOutlineDelete onClick={(e) => handleDelete(e, id)} />}
          </div>
          <div className='cursor-pointer'>
            {owner && <FaPen onClick={(e) => handleEdit(e, id)} />}
          </div>
        </article>
        <p className="propCard_price">{currency || `₦`}{formatPrice(price)}</p>
      </figure>
      {/* other info */}
      <div className='propCard_info'>
        <small className='hidden'>{type}</small>
        <small className='text-lg text-gray-400 capitalize'>{state}, {location}</small>
        <address className='text-xl font-bold'>{propertyName}</address>
        <address className='text-xl font-bold'>
          {address}
        </address>
        <div className='flex justify-between items-center my-3'>
          <div className='flex items-end gap-2'>
            {/* <img src={Agent} alt="agent" width={600} height={600} className='w-[35px] h-[35px] rounded-full object-contain' /> */}
            <p className='text-blue-600 font-medium'>{agentName}</p>
          </div>
          <p className='italic text-gray-400'>{timeAgo(date)}</p>
        </div>
        {/* Features */}
        <div className="propCard_features">

          <div className="feature">
            <FaRegSquareFull size={24} />
            <p>{sqrFt} Sq Ft</p>
          </div>

          {type !== 'warehouse' && type!=='office_complex' &&<div className='flex gap-4 items-center ml-auto'>
            <div className="feature">
              <MdOutlineKingBed size={24} />
              <p>{bed}</p>
            </div>
            <div className="feature">
              <MdOutlineBathtub size={24} />
              <p>{bath}</p>
            </div>
            <div className="feature">
              <GiHomeGarage size={24} />
              <p>{garage}</p>
            </div>
          </div>}
        </div>
      </div>
    </article>
  )
}

export default PropertyCard