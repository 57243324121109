import { createContext, useContext, useState, useEffect } from 'react';

const WishListContext = createContext({});

const WishListProvider = ({ children }) => {
  const storedWishes= JSON.parse(sessionStorage.getItem('wishes')) || [];
  const [wishlist, setWishlist] = useState(storedWishes);

  
  useEffect(() => {
    sessionStorage.setItem('wishes', JSON.stringify(wishlist));
  }, [wishlist]);

  return (
    <WishListContext.Provider value={{ wishlist, setWishlist}}>
      {children}
    </WishListContext.Provider>
  );
};

// Custom hook to use the user context
const useWishlist = () => useContext(WishListContext);

export { WishListProvider, useWishlist };
