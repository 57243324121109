import React from 'react'
import { useNavigate } from 'react-router-dom'


const TypeCard = ({ image, to, name }) => {
 const navigate = useNavigate()
 return (
  <article className='typeCard' onClick={() => navigate(to)}>
   <img src={image} alt={name} width={600} height={600} className='h-[80%] object-cover' loading='lazy' />
   <h1 className='my-2 text-xl lg:text-2xl'>{name}</h1>
  </article>
 )
}

export default TypeCard