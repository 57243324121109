import React, { useState, useEffect } from 'react'
import CustomInput from '../../../Components/CustomInput'
import { auth } from '../../../services/api/auth'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"
import { calculatePasswordStrength } from '../../../Components/Reuseables';
import Loading from '../../../Components/Loading'


const Seeker = () => {
  useEffect(() => {
    document.title = 'Register as a Home Seeker';
  }, []);

  const [seekerDetails, setSeekerDetails] = useState({
    location: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confirm_password: '',
    phone: ''
  })

  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');


  const validateForm = () => {
    const {
      location,
      email,
      firstname,
      lastname,
      password,
      confirm_password,
      phone
    } = seekerDetails;

    const isPasswordMatch = password === confirm_password;
    const isRequiredFieldsFilled =
      location !== '' &&
      email !== '' &&
      firstname !== '' &&
      lastname !== '' &&
      password !== '' &&
      confirm_password !== '' &&
      phone !== ''

    const isCheckboxChecked = agreeToTerms;


    return isRequiredFieldsFilled && isPasswordMatch && isCheckboxChecked;
  };
  const navigate = useNavigate()

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData()
    formData.append('firstname', seekerDetails.firstname)
    formData.append('lastname', seekerDetails.lastname)
    formData.append('location', seekerDetails.location)
    formData.append('email', seekerDetails.email)
    formData.append('password', seekerDetails.password)
    formData.append('password_confirmation', seekerDetails.confirm_password)

    if (validateForm()) {
      auth.registerSeeker(formData).then((res) => {
        if (res.status === 200) {
          toast.success(res.data?.message)
          setTimeout(() => {
            navigate('/auth/login')
          }, 2000)
        }
      }).catch((res) => console.log(res))
    } else {
      // Handle validation error
      console.error('Form validation failed.');
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setSeekerDetails((prev) => ({
      ...prev,
      [name]: value
    }))
    if (name === 'password') {
      handleValidation(value);
    }
  }

  const handleValidation = (password) => {
    const strength = calculatePasswordStrength(password);
    setPasswordStrength(strength);
  };


  return (
    <form>
      <CustomInput value={seekerDetails.firstname} placeholder={"Your First Name"} name={"firstname"} type={"text"} label={"First Name"} handleChange={handleChange} important />

      <CustomInput value={seekerDetails.lastname} placeholder={"Your lastname"} name={"lastname"} type={"text"} label={"Last Name"} handleChange={handleChange} important />

      <CustomInput value={seekerDetails.email} placeholder={"Your email"} name={"email"} type={"email"} label={"Email"} handleChange={handleChange} important />

      <CustomInput value={seekerDetails.phone} placeholder={"International format, eg +234, +44"} name={"phone"} type={"tel"} label={"Whatsapp number"} handleChange={handleChange} important />

      <article className='form_input_container'>
        <label htmlFor="location">Location <span className='text-red-800 text-xl font-extrabold'>*</span></label>
        <select name="location" id="location" value={seekerDetails.location} onChange={handleChange} className='formInput'>
          <option value="">Select your location</option>
          {
            ['Nigeria', 'United Kingdom', 'United States'].map((x, y) => {
              return (
                <option key={y + 1} value={x}>{x}</option>
              )
            })
          }
        </select>
      </article>

      <CustomInput value={seekerDetails.password} placeholder={"Your password"} name={"password"} type={"password"} label={"Password"} handleChange={handleChange} important info={<div className='flex flex-col'><small>Password should contain Capital letter(A), Small letter(a), Number(9), Special characters(@#$%) and {'>'} 8 characters</small>{passwordStrength && <p>Password Strength: <span className={passwordStrength === 'Weak' ? 'text-red-500' : passwordStrength === 'Moderate' ? 'text-green-300' : passwordStrength === 'Strong' ? 'text-green-800' : 'text-gray-300'}>{passwordStrength}</span></p>}</div>} />

      <CustomInput value={seekerDetails.confirm_password} placeholder={""} name={"confirm_password"} type={"password"} label={"Confirm password"} handleChange={handleChange} important />

      <article className='flex gap-1 mb-[10px] items-center'>
        <input type="checkbox" name="policy" id="policy" checked={agreeToTerms}
          onChange={() => setAgreeToTerms(!agreeToTerms)} />
        <p>I agree to HomeLinkers policy and terms of use</p>
      </article>

      <button className={(validateForm() || loading === true) ? 'submitBtn' : 'disabledBtn'} type='button' disabled={(!validateForm() || loading === true)} onClick={handleSubmit}>{loading ? <Loading className={'w-[40px]'} /> : 'Sign up'}</button>
    </form>
  )
}

export default Seeker